import React from 'react'

/* components */
import { CircularButton, CircularButtonProps } from '../StyledElements/CircularButton'
import { ArrowLeftIcon, ArrowRightIcon } from 'components/atoms/icons/index'

/* type declarations */
export type HideTableButtonProps = CircularButtonProps & {
  handleClick: () => void
}

export function HideTableButton({ handleClick, isHidden, ...rest }: HideTableButtonProps) {
  return (
    <CircularButton isHidden={isHidden} onClick={handleClick} {...rest}>
      {isHidden ? <ArrowRightIcon /> : <ArrowLeftIcon />}
    </CircularButton>
  )
}

export default HideTableButton
