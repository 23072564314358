import styled from 'styled-components'
import { StyledComponentProps } from 'utils/types'

export type HorizontalLineProps = StyledComponentProps

export const HorizontalLine = styled.hr<HorizontalLineProps>`
  margin: 1em 0;
`

export default HorizontalLine
