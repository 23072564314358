import styled from 'styled-components'
import { Text, TextProps } from 'components/atoms'

export type StyledTextProps = TextProps

export const StyledText = styled(Text)<StyledTextProps>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.717rem;
  line-height: 1;
  padding-top: 0;
`

export default StyledText
