/* local ducks */
import { types as piiTypes } from 'state/piiMapping'

/* foreign ducks */
import schemaActions from 'state/schemas/actions'

// initial state
export const initialState: piiTypes.State = {
  dataAttributes: undefined,
  dataClasses: undefined,
  maskingStrategies: undefined,
  openYamlValidationModal: false,
  schemaChanges: [],
  schemas: {},
  schemaValidations: undefined,
  selectedEntity: undefined,
  yamlValidationErrors: undefined,
  yamlFileName: undefined
}

export const initialSchemaState: piiTypes.SchemaMeta = {
  done: false,
  endTime: undefined,
  error: undefined,
  numTablesDone: 0,
  numTablesTotal: Infinity,
  percentDone: 0,
  startTime: undefined,
  status: schemaActions.retrieveSchemaStart.type
}

export default initialState
