import React from 'react'
import ReactSelect, { Props } from 'react-select'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledSelectProps = Props & StyledComponentProps
export type SelectProps = StyledSelectProps

export const StyledSelect = styled(ReactSelect)<StyledSelectProps>`
  & .react-select__indicator-separator {
    display: none;
  }
`

export function Select({ ...rest }: StyledSelectProps) {
  return <StyledSelect classNamePrefix="react-select" {...rest} />
}

export default Select
