import React, { PropsWithoutRef } from 'react'

/* components */
import { RenderFieldList } from './RenderFieldList'
import { StyledCompoundForm, StyledCompoundFormProps } from './StyledCompoundForm'
import SubmitButtons, { SubmitButtonsProps } from '../SubmitButtons'

/* utils */
import { FieldSection, FieldSections } from 'utils/types'

/* type declarations */
export type CompoundFormProps = StyledCompoundFormProps &
  SubmitButtonsProps & {
    containerProps?: StyledCompoundFormProps & PropsWithoutRef<JSX.IntrinsicElements['form']>
    handleFormSubmit?: (event: Event) => void
    sections: FieldSections
    styleSecondaryAsLink?: boolean
  }

/**
 * CompoundForm presentational component
 */
export default function CompoundForm({
  handleFormSubmit,
  handlePrimarySubmit,
  handleSecondarySubmit,
  primaryButtonText,
  primaryProps,
  secondaryButtonText,
  secondaryButtonType,
  secondaryProps,
  sections,
  ...rest
}: CompoundFormProps) {
  const buttonProps = {
    handlePrimarySubmit,
    handleSecondarySubmit,
    primaryButtonText,
    primaryProps,
    secondaryButtonText,
    secondaryButtonType,
    secondaryProps,
    sections
  }

  return (
    <StyledCompoundForm data-testid="general_form" onSubmit={onSubmit} {...rest}>
      {sections
        .filter(({ fields }: FieldSection) => fields.length > 0)
        .map(({ fields, handleChange, id }: FieldSection) => {
          return <RenderFieldList fields={fields} key={id} onChange={handleChange} />
        })}
      <br />
      <SubmitButtons {...buttonProps} />
    </StyledCompoundForm>
  )

  function onSubmit(event: Event) {
    event.preventDefault()

    if (handleFormSubmit) {
      handleFormSubmit(event)
    }
  }
}
