import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import { fetchFinishSchema, fetchSaveSchema } from './asyncActions'
import actions from './actions'
import initialState from './initialState'
import schemaUtils from './utils'

/* foreign ducks */
import { actions as connActions, types as connTypes } from 'state/connections'
import { types as currTypes } from 'state/currentConnection'
import { types as piiTypes } from 'state/piiMapping'
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { composer } from 'utils/functions'

const reducer = createReducer(initialState, {
  [fetchFinishSchema.fulfilled.type]: (
    state: schemaTypes.State,
    action: PayloadAction<piiTypes.SchemaComplete>
  ) => {
    const { connectionId, entities } = action.payload

    // Update the pure API-supplied values with any new properties that may be useful for the UI
    const entitiesForUI: schemaTypes.EntitiesForUI = composer(entities).resolve(updateMappingStatus)

    state[connectionId] = {
      entities: entitiesForUI
    }
  },

  [fetchSaveSchema.fulfilled.type]: (
    state: schemaTypes.State,
    action: PayloadAction<piiTypes.SchemaComplete>
  ) => {
    const { connectionId, entities } = action.payload

    // Update the pure API-supplied values with any new properties that may be useful for the UI
    const entitiesForUI: schemaTypes.EntitiesForUI = composer(entities).resolve(updateMappingStatus)

    state[connectionId] = {
      entities: entitiesForUI
    }
  },

  [actions.retrieveSchemaComplete.type]: (
    state: schemaTypes.State,
    action: PayloadAction<piiTypes.SchemaComplete>
  ) => {
    const { connectionId, entities } = action.payload

    // Update the pure API-supplied values with any new properties that may be useful for the UI
    const entitiesForUI: schemaTypes.EntitiesForUI = composer(entities).resolve(updateMappingStatus)

    state[connectionId] = {
      entities: entitiesForUI
    }
  },

  [actions.updateEntityProperty.type]: (
    state: schemaTypes.State,
    action: PayloadAction<schemaTypes.PropertyChange>
  ) => {
    const { connectionId, entityName, ...newPropertyVersion } = action.payload

    const entitiesToUpdate = state[connectionId]?.entities?.find((entity) => entity.name === entityName)

    // exit early
    if (!entitiesToUpdate) {
      return
    }

    const propertiesToUpdate = entitiesToUpdate?.properties

    if (propertiesToUpdate) {
      const index: number = propertiesToUpdate.findIndex(({ name }) => name === newPropertyVersion.name)

      if (index >= 0) {
        propertiesToUpdate[index] = {
          ...newPropertyVersion,
          hasNoMapping: schemaUtils.determineIfUnmapping(newPropertyVersion)
        }
      }

      entitiesToUpdate.hasNoMappings = schemaUtils.checkIfAllUnmapped(propertiesToUpdate)
    } else {
      entitiesToUpdate.hasNoMappings = true
    }
  },

  [actions.updateSchemaTable.type]: (
    state: schemaTypes.State,
    action: PayloadAction<schemaTypes.SchemaTableChange>
  ) => {
    const { connectionId, entityName, newEntityName, newCategory } = action.payload

    const entity = state[connectionId]?.entities?.find(({ name }) => name && name === entityName)

    if (entity && newEntityName) {
      entity.label = newEntityName
    }

    if (entity && newCategory) {
      entity.category = newCategory
    }
  },

  [connActions.fetchDeleteConnection.fulfilled.type]: (
    state: currTypes.State,
    action: PayloadAction<{ connectionId: connTypes.ConnectionId }>
  ) => {
    const { connectionId } = action.payload

    if (connectionId && state[connectionId]) {
      delete state[connectionId]
    }
  }
})

export default reducer

/* ------------------------------ */
/* helper functions */

function updateMappingStatus(entities: schemaTypes.FinalEntities): schemaTypes.EntitiesForUI {
  return entities?.map(({ properties, ...rest }) => {
    const newProperties = properties?.map((entityProperty) => ({
      ...entityProperty,
      hasNoMapping: schemaUtils.determineIfUnmapping(entityProperty)
    }))

    const hasNoMappings: schemaTypes.HasNoMappings =
      properties === undefined ? true : schemaUtils.checkIfAllUnmapped(newProperties || [])

    return {
      hasNoMappings,
      properties: newProperties,
      ...rest
    }
  })
}
