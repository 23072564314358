import React from 'react'
import { ClickAwayListener, Drawer, DrawerProps } from '@material-ui/core'

/* components */
import StyledBody from './StyledBody'
import StyledContainer, { StyledContainerProps } from './StyledContainer'

export type HelpSliderProps = DrawerProps & {
  containerProps?: StyledContainerProps
  handleClose: () => void
}

export function HelpSlider({
  anchor = 'right',
  children,
  containerProps,
  handleClose,
  ...rest
}: HelpSliderProps) {
  return (
    <Drawer anchor={anchor} {...rest}>
      <ClickAwayListener onClickAway={handleClose}>
        <StyledContainer onKeyDown={handleClose} {...containerProps}>
          <StyledBody>{children}</StyledBody>
        </StyledContainer>
      </ClickAwayListener>
    </Drawer>
  )
}

export default HelpSlider
