import styled from 'styled-components'
import { Button, ButtonProps } from 'components/atoms'

export type SendLogsButtonProps = ButtonProps

export const SendLogsButton = styled(Button)<SendLogsButtonProps>`
  && {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    margin-top: 2em;
    width: 300px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryHover};
    }
  }
`

export default SendLogsButton
