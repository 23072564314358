import React from 'react'
import styled from 'styled-components'
import { InputAdornment } from '@material-ui/core'

/* components */
import { CheckboxField, TextField, TextFieldProps } from 'components/atoms'
import { InfoIcon } from 'components/atoms/icons/index'

/* utils */
import { EventChange, StyledComponentProps } from 'utils/types'

/* type declarations */
export type RenderFieldListProps = StyledComponentProps & {
  fields: Array<TextFieldProps>
  onChange: (event: EventChange) => void
}

/**
 * RenderFieldList presentational component
 */
export function RenderFieldList({ fields, ...rest }: RenderFieldListProps) {
  return (
    <>
      {fields.map((field: any) => {
        const InputProps = {
          endAdornment: field.description && (
            <StyledInputAdornment position="end">
              <InfoIcon style={{ cursor: 'default' }} title={field.description} />
            </StyledInputAdornment>
          )
        }

        switch (field.type) {
          case 'checkbox':
            return <CheckboxField key={field.id} {...field} {...rest} />

          default:
            return (
              <TextField
                InputProps={InputProps}
                key={`${field.id}-${field.label || 'field'}`}
                {...field}
                {...rest}
              />
            )
        }
      })}
    </>
  )
}

export const StyledInputAdornment = styled(InputAdornment)`
  position: relative;
  left: 2.8em;
  font-size: 1.31rem;
  color: ${({ theme }) => theme.colors.grayDarker1};
`

export default RenderFieldList
