import styled from 'styled-components'

/* components */
import { Header, HeaderProps } from 'components/organisms'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type ModifiedHeaderProps = StyledComponentProps & HeaderProps

export const ModifiedHeader = styled(Header)<ModifiedHeaderProps>`
  flex-direction: row;
  align-items: center;
  padding: 0;
`

export default ModifiedHeader
