import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

/* local ducks */
import requests from './_requests'
import { types as currTypes } from 'state/currentConnection'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'
import { actions as appActions } from 'state/app'

/* utils */
import { MESSAGES } from 'utils/constants'
import { saveToLocalStorage } from 'utils/app'
import { CURRENT_CONNECTION_STORAGE_LABEL } from 'utils/constants'
import { ApiError, HttpResponse } from 'utils/types'

export const updateConnectionProxy = createAction(
  'state/update/current-connection',
  (currentConnection: currTypes.ConnectionProxy) => {
    // This config is everything we send as payload minus the database connection password, we don't
    // want that in local storage
    let configToStore: currTypes.ConnectionProxy = {...currentConnection}
    configToStore.configurations = currentConnection?.configurations?.filter(el => {
      return el.id !== 'password'
    })
    saveToLocalStorage({ key: CURRENT_CONNECTION_STORAGE_LABEL, value: configToStore })

    return {
      payload: currentConnection
    }
  }
)

export const fetchPostConnection = createAsyncThunk(
  'fetch/current-connection/create-new-connection',
  async (params: currTypes.ConnectionProxyParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI

    try {
      const res: HttpResponse<currTypes.ConnectionProxyResponse> = await requests.postConnection(params, {
        signal
      })

      switch (res.status) {
        case 200: {
          const json: currTypes.ConnectionProxyResponse = (await res.json()) as currTypes.ConnectionProxyResponse
          dispatch(updateConnectionProxy({ ...json }))

          return json
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = (await res.json()) as ApiError

          dispatch(
            alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchPutConnection = createAsyncThunk(
  'fetch/current-connection/update-current-connection',
  async (params: currTypes.ConnectionProxyParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI

    try {
      const res: HttpResponse<currTypes.ConnectionProxyResponse> = await requests.putConnection(params, {
        signal
      })

      switch (res.status) {
        case 200: {
          const json: currTypes.ConnectionProxyResponse = (await res.json()) as currTypes.ConnectionProxyResponse
          dispatch(updateConnectionProxy({ ...json }))
          return json
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = (await res.json()) as ApiError

          dispatch(
            alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

const actions = {
  fetchPostConnection,
  fetchPutConnection,
  updateConnectionProxy
}

export default actions
