import { all, call, spawn } from 'redux-saga/effects'

export type SagasArray = ((...rest: any) => Generator<{}, void, unknown>)[]
export type SagasConfig = {
  errCallback?: (err: Error) => void
}

export default function createRootSaga(sagas: SagasArray, args: any, { errCallback }: SagasConfig) {
  return function* rootSaga() {
    yield all(
      sagas.map((saga) =>
        spawn(function* () {
          while (true) {
            try {
              yield call(saga, args)
              break
            } catch (err) {
              if (errCallback) {
                errCallback(err)
              }
            }
          }
        })
      )
    )
  }
}
