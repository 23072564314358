import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type StyledCloseButtonProps = ButtonProps

/**
 * StyledCloseButton styled-component
 */
export const StyledCloseButton = styled(Button)<StyledCloseButtonProps>`
  && {
    position: absolute;
    right: 0;

    color: inherit;
    font-size: 1.2rem;

    min-height: 0;
    min-width: 0;

    margin: 0;
    padding: 0.75em;

    width: fit-content;
  }
`

export default StyledCloseButton
