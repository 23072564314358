import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type CenterAlignCellProps = StyledComponentProps & StyledComponentProps & {}

/**
 * CenterAlignCell
 */
export const CenterAlignCell = styled.td<CenterAlignCellProps>`
  text-align: center;

  && {
    & > * {
      margin: 0 0.25em;
    }
  }
`

export default CenterAlignCell
