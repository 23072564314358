import React, { ChangeEvent, useState } from 'react'

/* components */
import * as Styled from '../StyledElements'

/* state */
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { DEFAULT_DROPDOWN_VALUE, RELATIONSHIP_EXISTS } from 'utils/constants'
import { ReactSelectMissingEventType, ReactSelectOption, StyledComponentProps } from 'utils/types'
import { RELATIONSHIP_DATA_ATTRIBUTES } from 'utils/data/_relationships'

/* type declarations */
export type PointsToProps = StyledComponentProps & {
  columnName: schemaTypes.PropertyColumn
  currentSelection?: schemaTypes.PointsToValue
  dataAttribute?: schemaTypes.RelationshipMap
  entityNames: schemaTypes.EntitiesWithOnlyName
  handleChange: (event: ChangeEvent<HTMLSelectElement> | ReactSelectMissingEventType) => void
}

export type SelectionWithItems = {
  currentSelection?: schemaTypes.PointsToValue
  list: schemaTypes.EntitiesWithOnlyName
}

export default function PointsTo({
  columnName,
  currentSelection,
  dataAttribute,
  entityNames,
  handleChange,
  ...rest
}: PointsToProps) {
  const [open, setOpen] = useState(false)

  const relationshipExistsForDataAttribute = RELATIONSHIP_EXISTS.includes(dataAttribute as RELATIONSHIP_DATA_ATTRIBUTES)
  const options = convertToDisplay(entityNames)
  const currentValue = getCurrentValue(currentSelection)

  const simpleKeyForResettingState = `${!!currentValue?.value}`
  const showList = options?.length > 0 && relationshipExistsForDataAttribute

  return (
    <Styled.TableCell onClick={() => void setOpen(!open)} {...rest}>
      {showList && (
        <Styled.Select
          key={simpleKeyForResettingState}
          name={columnName}
          onChange={onChange}
          options={options}
          placeholder={showList ? 'Select Table' : undefined}
          value={currentValue}
        />
      )}
    </Styled.TableCell>
  )

  function convertToDisplay(entities: schemaTypes.EntitiesWithOnlyName): Array<ReactSelectOption> {
    if (entities.length > 0) {
      return [
        DEFAULT_DROPDOWN_VALUE,
        ...entities?.map(({ name }) => ({
          label: name,
          value: name
        }))
      ]
    }

    return []
  }

  function getCurrentValue(currentSelection?: schemaTypes.PointsToValue): ReactSelectOption | undefined {
    if (currentSelection) {
      return {
        label: currentSelection,
        value: currentSelection
      }
    }

    return undefined
  }

  function onChange({ value }: ReactSelectOption) {
    const event: ReactSelectMissingEventType = {
      currentTarget: {
        name: columnName,
        value
      }
    }

    handleChange(event)
  }
}
