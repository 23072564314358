/* local ducks */
import { types as connTypes } from 'state/connections'

/* utils */
import { configureInit } from 'utils/app'
import { endpoints } from 'utils/constants'
import { RequestOptions, Empty, HttpResponse } from 'utils/types'


export function deleteConnection(
  { connectionId }: connTypes.DeleteConnectionParams,
  { signal }: RequestOptions
): Promise<HttpResponse<connTypes.Connection>> {
  const input: RequestInfo = `${endpoints.connection}?connectionId=${connectionId}`
  const init: RequestInit = configureInit({
    method: 'DELETE',
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

export function getConnection(
    { connectionId }: connTypes.GetConnectionParams,
    { signal }: RequestOptions
): Promise<HttpResponse<connTypes.Connection>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}`
  const init: RequestInit = configureInit({
    method: 'GET',
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

export function getConnections(_: Empty, { signal }: RequestOptions): Promise<HttpResponse<connTypes.Connections>> {
  const input: RequestInfo = endpoints.connection
  const init: RequestInit = configureInit({
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

export function getConnectionStatus( { connectionId }: connTypes.GetConnectionStatusParams, { signal }: RequestOptions
): Promise<HttpResponse<connTypes.ConnectionStatus>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/test`
  const init: RequestInit = configureInit({
    method: 'GET',
    signal
  })

  return fetch(input, init)
}

const requests = { deleteConnection, getConnection, getConnections, getConnectionStatus }

export default requests
