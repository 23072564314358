import { TableRow, TableRowProps } from '@material-ui/core'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableRowProps = StyledComponentProps &
  TableRowProps & {
    showError?: boolean
  }

const FilterPropsWrapper = ({ showError, ...rest }: StyledTableRowProps) => <TableRow {...rest} />

/**
 * TableRow styled-component
 */
export const StyledTableRow = styled(FilterPropsWrapper)<StyledTableRowProps>`
  && {
    display: flex;

    background-color: ${({ showError, theme }) => (showError ? theme.colors.redLighter : theme.colors.white)};
    color: ${({ theme }) => theme.colors.grayDarker1};

    height: auto;
  }
`

export default StyledTableRow
