import { types as piiTypes } from 'state/piiMapping'
import mergeMessages from './mergeMessages'

export default function mergeColumnErrorMessages(obj: piiTypes.EntityErrorsMap | undefined): string {
  if (!obj) {
    return ''
  }

  const keys = Object.keys(obj)

  const individualMessages = keys.map((key) => `(${key}) ${mergeMessages(obj[key])}`)

  return individualMessages.join('; ')
}
