import React from 'react'

/* components */
import { Button, ButtonProps, Progress, Text } from 'components/atoms'
import { DownloadFileIcon } from 'components/atoms/icons/index'
import RowItem from './RowItem'

/* type declarations */
export type DownloadYamlProps = ButtonProps & {
  disabled: boolean
  handleSubmit: () => void
  isLoading?: boolean
}

export default function DownloadYaml({ disabled, handleSubmit, isLoading, ...rest }: DownloadYamlProps) {
  const buttonContents = (
    <>
      <DownloadFileIcon
        style={{
          color: '#666666',
          fontSize: '1.4rem',
          marginRight: 6
        }}
      />
      <Text message="Download Structure YAML" />
    </>
  )

  return (
    <RowItem {...rest}>
      <Button disabled={disabled} onClick={handleSubmit} style={{ minWidth: 235 }}>
        {isLoading ? <Progress style={{ minWidth: 235 }} /> : buttonContents}
      </Button>
    </RowItem>
  )
}
