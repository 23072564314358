import styled from 'styled-components'
import { TableBody, TableBodyProps } from '@material-ui/core'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableBodyProps = StyledComponentProps & TableBodyProps

/**
 * StyledTable styled-component
 */
export const StyledTableBody = styled(TableBody)<StyledTableBodyProps>`
  border-radius: 0 0 2% 2%;
`

export default StyledTableBody
