import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'
import { Table, TableProps } from '@material-ui/core'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableProps = StyledComponentProps & LayoutProps & TableProps

/**
 * StyledTable styled-component
 */
export const StyledTable = styled(Table)<StyledTableProps>`
  overflow-x: hidden;
  overflow-y: auto;

  max-height: 672px;

  ${layout}

  && {
    display: inline-block;
    width: initial;

    & .mapping-heading-row {
      height: 58px;
    }
  }
`

export default StyledTable
