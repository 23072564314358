import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTilesProps = StyledComponentProps

/**
 * StyledTiles styled-component
 */
export const StyledTiles = styled.ul`
  display: flex;
  flex-wrap: wrap;

  padding: 0 0 3em;
  max-width: 1110px;

  & > *:not(:last-child) {
    margin-right: 1.97em;
  }
`

export default StyledTiles
