/* local ducks */
import { types as userTypes } from 'state/user'

/* utils */
import { configureInit } from 'utils/app'
import { endpoints } from 'utils/constants'
import { Empty, HttpResponse, RequestOptions } from 'utils/types'

export function getUser(_: Empty, { signal }: RequestOptions): Promise<HttpResponse<userTypes.UserResponse>> {
  const input: RequestInfo = endpoints.userResource
  const init: RequestInit = configureInit({
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

export function getVersion(
  _: Empty,
  { signal }: RequestOptions
): Promise<HttpResponse<userTypes.UserResponse>> {
  const input: RequestInfo = endpoints.version
  const init: RequestInit = configureInit({
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

export function postSendDiagnostics(
  bodyParams: userTypes.SendDiagnosticsFetchBody,
  { signal }: RequestOptions
): Promise<HttpResponse<userTypes.SendDiagnosticsResponse>> {
  const rootUrl = endpoints.sendDiagnostics
  const queryParams = `?hours=${bodyParams?.hours}`

  const input: RequestInfo = `${rootUrl}${queryParams}`
  const init: RequestInit = configureInit({
    method: 'POST',
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

const requests = { getUser, getVersion, postSendDiagnostics }

export default requests
