/* local ducks */
import { types as connTypes } from 'state/connections'

/* utils */
import { connectionPropertyNames } from 'utils/constants'

// initial state
export const initialState: connTypes.State = {
  list: undefined,
  tableSortByColumn: connectionPropertyNames.name,
  tableSortReverse: false
}

export default initialState
