import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledFlatLayoutProps = BoxProps

/**
 * StyledFlatLayout styled-component
 */
export const StyledFlatLayout = styled(Box)<StyledFlatLayoutProps>`
  position: relative;
  display: grid;
  grid-template-rows: auto 4em minmax(4em, auto) 1.5em auto 2.5em;
  grid-template-columns: 1fr 500px 1fr;
  grid-template-areas:
    'nav nav nav'
    '. . .'
    '. main-content .'
    '. . .'
    '. content .'
    '. . .';

  width: 100%;

  & > * {
    justify-self: center;
  }
`

export default StyledFlatLayout
