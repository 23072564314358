import React from 'react'
import styled from 'styled-components'

/* components */
import { VerticalDotsIcon } from 'components/atoms/icons/index'
import * as Styled from '../StyledElements'
import { StyledTableCellProps } from '../StyledElements/TableCell'

/* utils */
import { EventClick } from 'utils/types'

/* type declarations */
export type MappingTableHeadingProps = StyledTableCellProps & {
  handleClick?: (event: EventClick) => void
  handleSecondaryClick?: (event: EventClick) => void
  label: string
}

export const Wrapper = styled.div``

export default function MappingTableHeading({
  handleClick,
  handleSecondaryClick,
  label,
  ...rest
}: MappingTableHeadingProps) {
  return (
    <Styled.TableHeading {...rest}>
      <Styled.CellButton onClick={onClick}>
        <Wrapper>{label}</Wrapper>
        <Wrapper>
          <VerticalDotsIcon
            onClick={handleSecondaryClick}
            style={{ cursor: 'pointer', fontSize: '1.4em', marginTop: 5 }}
          />
        </Wrapper>
      </Styled.CellButton>
    </Styled.TableHeading>
  )

  function onClick(event: EventClick) {
    if (handleClick) {
      handleClick(event)
    }
  }
}
