const colors = {
  black: '#111111',
  boxShadow: '0px 1px 2px 0px #9e9e9e',
  boxShadowHeavy: '0px 6px 20px 5px rgba(0,0,0,0.5)',
  gray: '#9e9e9e',
  grayDarker1: '#666666',
  grayLight1: '#d8d8d8',
  grayLight2: '#EDEDED',
  grayLight3: '#F3F3F3',
  primary: '#0861ce',
  primaryDarker1: '#0c42af',
  primaryHover: '#0c42af',
  primaryLighter1: '#0372e0',
  primaryLighter2: '#0085f4',
  primaryLighter3: '#0093ff',
  primaryLighter4: '#30a3ff',
  primaryLighter5: '#5ab3ff',
  primaryLighter6: '#8cc9ff',
  primaryLighter7: '#b9ddff',
  primaryLighter8: '#e2f2ff',
  red: '#f44336',
  redLight: '#fae1e1',
  redLighter: '#fcf5f7',
  statusError: '#fae1e1',
  statusInfo: '#e2f2ff',
  statusSuccess: '#e1faec',
  statusWarning: '#faeee1',
  white: '#fffffe',
  whiteHover: '#E1EEFC'
}

export default colors
