import styled from 'styled-components'

/* components */
import { ExternalLink, ExternalLinkProps } from 'components/atoms'

/**
 * StyledLink styled-component
 */
export const StyledExternalLink = styled(ExternalLink)<ExternalLinkProps>`
  color: inherit;
  text-decoration: none;

  &:onhover {
    text-decoration: underline;
  }
`

export default StyledExternalLink
