/* foreign ducks */
import { RootState } from 'state/store'

/* utils */
import { stateNames } from 'utils/constants'

const { auth } = stateNames

export const selectEmail = (state: RootState) => state[auth]?.email
export const selectFieldErrors = (state: RootState) => state[auth]?.fieldErrors
export const selectMfaRefId = (state: RootState) => state[auth]?.mfaRefId

const selectors = {
  selectEmail,
  selectFieldErrors,
  selectMfaRefId
}

export default selectors
