import React from 'react'

/* components */
import { Button, ButtonProps, Progress, Text } from 'components/atoms'
import { PreviewIcon } from 'components/atoms/icons/index'
import RowItem from './RowItem'

/* utils */
import { EventClick } from 'utils/types'

/* type declarations */
export type QueryPreviewProps = ButtonProps & {
  disabled: boolean
  handleSubmit: (event: EventClick) => void
  isLoading?: boolean
}

export default function QueryPreview({ disabled, handleSubmit, isLoading, ...rest }: QueryPreviewProps) {
  const buttonContents = (
    <>
      <PreviewIcon
        style={{
          color: '#666666',
          fontSize: '1.4rem',
          marginRight: 6
        }}
      />
      <Text message="Preview Query Execution" />
    </>
  )

  return (
    <RowItem {...rest}>
      <Button disabled={disabled} onClick={handleSubmit} style={{ minWidth: 235 }}>
        {isLoading ? <Progress style={{ minWidth: 235 }} /> : buttonContents}
      </Button>
    </RowItem>
  )
}
