import React, { ChangeEvent, useState } from 'react'

/* components */
import { Box } from 'components/atoms'
import { StyledText } from '../StyledText'
import { RenameTableButton } from './RenameTableButton'
import { TableNameButton } from './TableNameButton'
import { TableNameInput } from './TableNameInput'

/* state */
import { types as currTypes } from 'state/currentConnection'
import { types as schemaTypes } from 'state/schemas'

/* type declarations */
export type TableNameHandlerProps = {
  connectionId: currTypes.ConnectionProxyId
  changeValue: (props: schemaTypes.SchemaTableChange) => void
  entityName: string
  entityLabel: string
  modifiedTitle: string
}

export function TableNameHandler({
  connectionId,
  changeValue,
  entityName,
  entityLabel,
  modifiedTitle
}: TableNameHandlerProps) {
  /* state hooks */
  const [isChangingName, setIsChangingName] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(entityLabel || entityName || '')

  /* constants */
  const displayValue = `${entityLabel || entityName || 'None'}`
  const entityNameInParentheses = entityLabel ? ` (${entityName})` : ''
  const tableNameButtonProps = {
    fontSize: '1.5rem',
    textTransform: 'initial'
  }

  /* render */
  return (
    <Box display="flex" alignItems="center">
      <StyledText>{modifiedTitle}</StyledText>
      {isChangingName ? (
        <TableNameInput onChange={handleChange} value={inputValue} />
      ) : (
        <TableNameButton
          message={displayValue}
          secondaryMessage={entityNameInParentheses}
          style={tableNameButtonProps}
          onClick={activateChangeName}
        />
      )}
      {isChangingName && <RenameTableButton onClick={renameTable}>Rename Table</RenameTableButton>}
    </Box>
  )

  /* callbacks */
  function activateChangeName() {
    setIsChangingName(true)
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget
    setInputValue(value)
  }

  function renameTable() {
    changeValue({ connectionId, entityName, newEntityName: inputValue })
    setIsChangingName(false)
  }
}

export default TableNameHandler
