/**
 * A utility function for transforming data in a declarative fashion. The intention here is to provide a tool
 *      similar to Array.map(), but for non-arrays.
 *
 * NOTE: Unlike Array.map(), the .resolve property MUST be invoked at the end of the function chain. Otherwise,
 *      an error will be thrown. It's purpose here is to let the function "know" that the composition chain
 *      is at an end.
 *
 * References:
 *  https://egghead.io/courses/professor-frisby-introduces-composable-functional-javascript
 *  https://gist.github.com/wordyallen/a4079c2672d8152452e51eb8e0f02d93 (See: "Box.js")
 */
const composer = (x: any) => ({
  map: (f: Function) => composer(f(x)),
  resolve: (f: Function) => f(x),
  inspect: () => `composer(${x})`
})

export default composer
