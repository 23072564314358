import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

/* local ducks */
import requests from './_requests'
import { types as connTypes } from 'state/connections'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'
import { actions as appActions } from 'state/app'

/* utils */
import { MESSAGES } from 'utils/constants'
import { ApiError, HttpResponse, SortByProperty } from 'utils/types'

export const fetchDeleteConnection = createAsyncThunk(
  'fetch/connections/delete-from-connections',
  async ({ connectionId }: connTypes.DeleteConnectionParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI

    try {
      const res: HttpResponse<connTypes.Connection> = await requests.deleteConnection(
        { connectionId },
        { signal }
      )

      switch (res.status) {
        case 200: {
          return { connectionId }
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = (await res.json()) as ApiError

          dispatch(
            alertActions.createAlert({
              title: `${title} (Status ${res.status})`,
              message,
              severity: 'error'
            })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchStatusByConnectionId = createAsyncThunk(
  'fetch/connection/get-connection-status',
  async ({ connectionId }: connTypes.GetConnectionStatusParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI
    try {
      const res: HttpResponse<connTypes.ConnectionStatus> = await requests.getConnectionStatus(
        { connectionId },
        { signal }
      )
      switch (res.status) {
        case 200: {
          return (await res.json()) as connTypes.ConnectionStatus
        }
        default: {
          const { message }: ApiError = (await res.json()) as ApiError
          dispatch(alertActions.createAlert({ title: `Status ${res.status}`, message, severity: 'error' }))

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const { message }: ApiError = await err
      dispatch(alertActions.createAlert({ title: `Fetch Error`, message, severity: 'error' }))

      return rejectWithValue({ message })
    }
  }
)

export const fetchGetConnections = createAsyncThunk(
  'fetch/connections/get-all-connections',
  async ({ signal }: { signal?: AbortSignal }, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI

    try {
      const res: HttpResponse<connTypes.Connections> = await requests.getConnections(null, { signal })

      switch (res.status) {
        case 200: {
          return (await res.json()) as connTypes.Connections
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = (await res.json()) as ApiError

          dispatch(
            alertActions.createAlert({
              title: `${title} (Status ${res.status})`,
              message,
              severity: 'error'
            })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      if (!message.includes('abort')) {
        dispatch(
          alertActions.createAlert({
            title,
            message,
            severity: 'error'
          })
        )
      }

      return rejectWithValue({ message })
    }
  }
)

export const sortColumn = createAction<SortByProperty>('connections/sort')

const actions = {
  fetchDeleteConnection,
  fetchGetConnections,
  fetchStatusByConnectionId,
  sortColumn
}

export default actions
