import React from 'react'
import { Dialog as OriginalDialog } from '@material-ui/core'

/* components */
import { StyledDialogContent, StyledDialogContentProps } from './StyledDialogContent'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type DialogProps = StyledDialogContentProps & {
  open: boolean
  message?: string
  reverseColors?: boolean
  styleProps?: StyledComponentProps
}

/**
 * Dialog presentational component
 */
export default function Dialog({ children, className, message, style, styleProps, ...rest }: DialogProps) {
  return (
    <OriginalDialog data-testid="dialog" {...rest}>
      <StyledDialogContent
        className={className}
        // reverseColors={reverseColors}
        {...styleProps}
        style={style}>
        {message || children}
      </StyledDialogContent>
    </OriginalDialog>
  )
}
