import styled from 'styled-components'

/* components */
import { Stack, StackProps } from '../Stack'

/* type declarations */
export type PaperProps = StackProps

/**
 * Paper styled-component
 */
export const Paper = styled(Stack)<PaperProps>`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.25em;
  box-shadow: ${(props) => props.theme.colors.boxShadow};
`

export default Paper
