import styled from 'styled-components'

/* components */
import { Link, LinkProps } from 'components/atoms'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledLinkProps = StyledComponentProps & LinkProps

/**
 * StyledLink styled-component
 */
export const StyledLink = styled(Link)<StyledLinkProps>`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export default StyledLink
