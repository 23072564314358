import React from 'react'
import { AccordionSummary } from '@material-ui/core'

/* components */
import { HorizontalLine } from 'components/atoms'
import { ChevronDown } from 'components/atoms/icons/index'
import { StyledAccordion, StyledAccordionProps } from './StyledAccordion'

/* type declarations */
export type CollapsibleBlockProps = StyledAccordionProps & {
  heading: string
  open: boolean
}

/**
 * Text presentational component
 */
export default function CollapsibleBlock({ children, heading, open = true, ...rest }: CollapsibleBlockProps) {
  return (
    <>
      <StyledAccordion expanded={open} {...rest}>
        <AccordionSummary expandIcon={<ChevronDown />} style={{ padding: 0 }}>
          <strong>{heading}</strong>
        </AccordionSummary>
        {children}
      </StyledAccordion>
      <HorizontalLine />
    </>
  )
}
