import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

/* components */
import { Box, BoxProps, Progress, Row, Text, TextField } from 'components/atoms'
import { Heading } from 'components/molecules'

import { LeftColumn } from './LeftColumn'
import { RightColumn } from './RightColumn'
import { Section } from './Section'
import { SectionsContainer } from './SectionsContainer'
import { SendLogsButton } from './SendLogsButton'
import { StyledSecondaryButton } from './StyledSecondaryButton'

/* state */
import { useDispatch } from 'state/store'
import { actions as alertActions } from 'state/alerts'
import { actions as userActions, selectors as userSelectors } from 'state/user'

/* utils */
import colors from 'App/theme/colors'
import { EventClick, EventChange } from 'utils/types'

/* type declarations */
export type AccountScreenProps = BoxProps & {
  secondaryActions: (event: EventClick) => void
}

export enum DiagnosticLogStatus {
  noStatus = '',
  loading = 'loading',
  success = 'success',
  failure = 'failure'
}

export enum HoursToSend {
  default = 24,
  min = 1
}

export default function AccountScreen({ secondaryActions, ...rest }: AccountScreenProps) {
  /* library hooks */
  const dispatch = useDispatch()

  /* state hooks */
  const [hoursToSend, setHoursToSend] = useState(HoursToSend.default)
  const [logStatus, setLogStatus] = useState(DiagnosticLogStatus.noStatus)

  /* selector hooks */
  const { email, name, organizationName, version } = useSelector(userSelectors.selectUserDetails) || {}

  /* effect hooks */
  useEffect(() => {
    dispatch(userActions.fetchGetVersion())
  }, [dispatch])

  /* render */
  return (
    <Box {...rest}>
      <SectionsContainer>
        <Section>
          <Heading text="Account" />
          <Row>
            <LeftColumn>Organization</LeftColumn>
            <RightColumn>
              <strong>{organizationName}</strong>
            </RightColumn>
          </Row>
          <Row>
            <LeftColumn>User</LeftColumn>
            <RightColumn>{name}</RightColumn>
          </Row>
          <Row>
            <LeftColumn>Email</LeftColumn>
            <RightColumn>{email}</RightColumn>
          </Row>
        </Section>
        <Section>
          <Heading text="Diagnostics" />
          <Row>
            <LeftColumn>Status</LeftColumn>
            <RightColumn>
              <strong>{version}</strong>
            </RightColumn>
          </Row>
          <Row>
            <LeftColumn>Hours of Logs to Send</LeftColumn>
            <RightColumn>
              <TextField onChange={handleHoursToSend} pattern="[0-9]*" type="text" value={hoursToSend} />
            </RightColumn>
          </Row>
          <SendLogsButton onClick={sendLogs}>
            {logStatus === DiagnosticLogStatus.loading ? <Progress /> : <>Send Diagnostic Logs to Ethyca</>}
          </SendLogsButton>
          <Text minHeight="2em">{renderLogStatusText()}</Text>
        </Section>
      </SectionsContainer>
      <Box display="flex" justifyContent="center" paddingTop="3em">
        <StyledSecondaryButton onClick={secondaryActions}>Back</StyledSecondaryButton>
      </Box>
    </Box>
  )

  /* render callbacks */
  function renderLogStatusText() {
    if (logStatus === DiagnosticLogStatus.success) {
      return <em style={{ color: colors.primary }}>Logs have been sent successfully!</em>
    }

    if (logStatus === DiagnosticLogStatus.failure) {
      return <em style={{ color: colors.red }}>Request failed. Please try again!</em>
    }

    return DiagnosticLogStatus.noStatus
  }

  /* callbacks */
  function handleHoursToSend(event: EventChange) {
    const { value } = event.target

    if (!value?.length) {
      setHoursToSend(0)
    } else {
      const convertedValue = parseInt(value, 10)

      setHoursToSend(convertedValue)
    }
  }

  async function sendLogs() {
    if (hoursToSend < HoursToSend.min) {
      dispatch(
        alertActions.createAlert({
          title: `Diagnostic Logs - Out of Bounds`,
          message: `The number of hours to send must be above ${HoursToSend.min} hours.`,
          severity: 'warning'
        })
      )
      return
    }

    setLogStatus(DiagnosticLogStatus.loading)

    try {
      const action = await dispatch(userActions.fetchSendDiagnostics({ hours: hoursToSend }))
      unwrapResult(action)
      setLogStatus(DiagnosticLogStatus.success)
    } catch (_) {
      setLogStatus(DiagnosticLogStatus.failure)
    }
  }
}
