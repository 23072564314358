import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* assets */
import img from 'assets/img/rocket-success.png'

/* components */
import { FlatLayout, FlatLayoutProps } from 'components/templates'
import { Image } from 'components/atoms'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'
import { actions as connActions } from 'state/connections'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'

/* helpers */
import { routes } from 'utils/constants'

/* type declarations */
export type ConnectionSuccessProps = FlatLayoutProps & RouteComponentProps<any>

/**
 * ConnectionSuccess redux- & router-aware component
 */
export default function ConnectionSuccess({ location }: ConnectionSuccessProps) {
  const { pathname } = location

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* selector hooks */
  const currentConnection: currTypes.ConnectionProxy =
    useSelector(currSelectors.selectCurrentConnection) || {}

  const { id: connectionId } = currentConnection

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ currentPath: pathname, isAuthPath: true })), [
    dispatch,
    pathname
  ])

  // If no current connection loads, simply auto-redirect to the connections page
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!connectionId) {
        history.push(routes.connections)
      }
    }, 3000)

    return clearTimeout(timeout)
  }, [connectionId, history])

  useEffect(() => {
    dispatch(connActions.fetchGetConnections({}))
  }, [dispatch])

  const layoutProps: FlatLayoutProps = {
    // Hide the buttons on the FlatLayout if there's no connection selected
    buttonProps: connectionId
      ? {
          primaryButtonText: `Start PII Mapping`,
          handlePrimarySubmit: handleSubmit,
          secondaryButtonText: `Continue to Control Panel`,
          handleSecondarySubmit: handleSecondary,
          primaryProps: { style: { width: 270 } },
          secondaryProps: { style: { width: 270 } },
          style: {
            justifyContent: 'space-around'
          }
        }
      : undefined,
    subtitle: `Atlas is now retrieving your schema. You can leave this page and continue to your control panel or hang around here until it's ready. This process usually takes up to five minutes.`,
    subtitleProps: {
      style: {
        paddingBottom: '1.5em',
        width: 684
      }
    },
    title: `Database Connection Successful!`
  }

  /* render */
  // @ts-ignore
  return (
    <FlatLayout {...layoutProps}>
      <Image alt="Rocket firing upward" src={img} style={{ height: 390 }} />
    </FlatLayout>
  )

  /* callbacks */
  function handleSubmit() {
    if (connectionId) {
      return void history.push(`${routes.piiMapping}/${connectionId}`)
    }
    return void history.push(routes.connections)
  }

  function handleSecondary() {
    history.push(routes.connections)
  }
}
