import React from 'react'
import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type StyledSecondaryButtonProps = ButtonProps & {
  reverseStyle?: boolean
}

/**
 * StyledSecondaryButton styled-component
 */
export const StyledSecondaryButton = styled(({ reverseStyle, ...rest }) => <Button {...rest} />)<
  StyledSecondaryButtonProps
>`
  height: 2em;
  font-size: 0.9em;
  letter-spacing: 0.25px;
  text-transform: uppercase;

  && {
    background-color: ${({ theme, reverseStyle }) =>
      !reverseStyle ? theme.colors.white : theme.colors.primary};
    color: ${({ theme, reverseStyle }) => (!reverseStyle ? theme.colors.primary : theme.colors.white)};

    &:hover {
      background-color: ${({ theme, reverseStyle }) =>
        !reverseStyle ? theme.colors.whiteHover : theme.colors.primaryDarker1};
    }
  }
`

export default StyledSecondaryButton
