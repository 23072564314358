import { configureStore } from '@reduxjs/toolkit'

import middlewares, { sagaMiddleware } from 'state/store/middlewares'
import reducers from 'state/store/reducers'

export { default as useDispatch } from './useDispatch'

const store = configureStore({
  middleware: middlewares,
  reducer: reducers
})

export { sagaMiddleware }

export default store

export type Dispatch = any
export type RootState = ReturnType<any>
