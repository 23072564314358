import { types as currTypes } from 'state/currentConnection'

import { ReactSelectOption } from 'utils/types'
import { MASKING_STRATEGIES } from 'utils/data/_maskingStrategies'
import { RELATIONSHIP_DATA_ATTRIBUTES } from 'utils/data/_relationships'

export { default as endpoints } from './endpoints'
export { default as externalUrls } from './externalUrls'
export { default as routes } from './routes'
export { default as stateNames } from './stateNames'

/* -------------------------------------------------- */
/* constants that are needed within this file */

export const MASKING_STRATEGIES_WITH_EXTRA_INPUT_VALUE = [MASKING_STRATEGIES.default_value]

export const PLACEHOLDER_FOR_DROPDOWN = 'Select...'

export const UNSUPPORTED_DATABASES_SCHEMA_RETRIEVAL = ['mongodb']

/* -------------------------------------------------- */
/* all other constants - alphabetical order */
export const BASE_NEW_CONNECTION: currTypes.ConnectionProxy = {}

export enum configurationConnectionResults {
  success = 'success',
  failure = 'failure'
}

export const CONFIGURATION_NAME = 'name'

export const CONFIGURATIONS_LABEL = 'configurations'

export enum connectionPropertyNames {
  configurations = 'configurations',
  connectionStatus = 'connectionStatus',
  connectionType = 'connectionType',
  dataMappingStatus = 'dataMappingStatus',
  errorMessage = 'errorMessage',
  id = 'id',
  lastModified = 'lastModified',
  name = 'name'
}

export const CURRENT_CONNECTION_STORAGE_LABEL = 'current'

export enum dataStatuses {
  active = 'active',
  analyzing = 'analyzing',
  not_configured = 'not_configured',
  invalid_structure = 'invalid_structure',
  retrieving = 'retrieving'
}

export const DATA_STATUSES_ENABLE_DOWNLOAD = [dataStatuses.not_configured]

export const DATA_STATUSES_MAP = {
  [dataStatuses.active]: 'Mapped',
  [dataStatuses.analyzing]: 'Pending Review',
  [dataStatuses.not_configured]: 'Awaiting Schema',
  [dataStatuses.invalid_structure]: 'Invalid YAML'
}

export const DATA_STATUSES_BUTTON_MAP = {
  [dataStatuses.active]: 'Manage',
  [dataStatuses.analyzing]: 'Map PII',
  [dataStatuses.not_configured]: 'Map PII',
  [dataStatuses.invalid_structure]: 'Map PII'
}

export enum CONNECTION_STATUSES {
  CONNECTED = 'Connected',
  NOT_CONNECTED = 'Not Connected',
  LOADING = 'Loading...'
}

export const DEFAULT_DROPDOWN_VALUE: ReactSelectOption = {
  label: PLACEHOLDER_FOR_DROPDOWN,
  value: ''
}

export const MAX_TABLE_ROWS = 12

export const GCD_JSON_FIELD_ID = 'credentials'

export const IS_AUTH_STORAGE_LABEL = 'jenga'

export const LOCAL_STORAGE_KEY = 'atlas-app'

export const MAX_SCHEMA_VERSIONS = 8

export const MESSAGES = {
  auth: {
    title: `Unable to retrieve from server.`,
    message: `You have been logged out. Please return to the log in screen and start again.`
  },
  general: {
    title: `An unexpected error has occurred.`
  },
  saveSchema: {
    title: 'Save Success',
    message: `Your schema has been successfully saved!`
  },
  finishSchema: {
    title: 'Finish Success',
    message: `Your schema has been successfully completed!`
  },
  finishSchemaUnmapped: {
    title: 'Removal Success',
    message: `All tables have been successfully unmapped.  If this action is in error, please upload a new or previous YAML configuration file.`
  },
  queryExecutionNoYaml: {
    title: 'No Preview Available',
    message: `A schema (.yaml) file must be provided for a preview to be available.`
  },
  schemaErrors: {
    title: 'Schema has failed to validate.'
  },
  yamlErrors: {
    title: 'YAML has failed to validate',
    default: 'Reason unknown'
  }
}

export enum pageFlows {
  edit = 'edit-connection',
  new = 'new-connection'
}

export const RELATIONSHIP_EXISTS = [
  RELATIONSHIP_DATA_ATTRIBUTES.ref_id,
  RELATIONSHIP_DATA_ATTRIBUTES.ref_immediate
]

export const PREVENT_MASKING_STRATEGY = [
  RELATIONSHIP_DATA_ATTRIBUTES.id,
  RELATIONSHIP_DATA_ATTRIBUTES.ref_id,
  RELATIONSHIP_DATA_ATTRIBUTES.ref_immediate
]

export enum routesFromConnections {
  pii = 'pii',
  new = 'new',
  edit = 'edit'
}

export enum SCHEMA_DIFFERENCES {
  CHANGED = 'CHANGED',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  NEW = 'NEW',
  REJECTED = 'REJECTED'
}

export enum DIFFERENCE_COLORS {
  CHANGED = '#808080',
  DELETED = '#DF340E',
  ERROR = '#DF340E',
  NEW = '#0861CE',
  REJECTED = '#DF340E'
}

export enum schemaPropertyNames {
  dataAttribute = 'dataAttribute',
  dataClass = 'dataClass',
  datatype = 'datatype',
  defaultValue = 'defaultValue',
  difference = 'difference',
  label = 'label',
  maskingStrategy = 'maskingStrategy',
  name = 'name',
  reference = 'reference'
}

export enum timeouts {
  short = 2000,
  standard = 4000,
  long = 8000
}
