import React from 'react'
import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type StyledIconButtonProps = ButtonProps & {
  themeColor?: string
}

/**
 * StyledIconButton styled-component
 */
export const StyledIconButton = styled(RestrictProps)<StyledIconButtonProps>`
  width: initial;

  && {
    color: ${({ theme, themeColor }) => theme.colors[themeColor || 'grayDarker']};
    font-size: 1.2rem;
    margin: 0;
    min-height: 0;
    min-width: 0;
    padding: 0;
  }
`

function RestrictProps({ themeColor, ...rest }: StyledIconButtonProps) {
  return <Button {...rest} />
}

export default StyledIconButton
