import { LOCAL_STORAGE_KEY } from 'utils/constants'
import { StorageValue } from 'utils/types'

export interface getFromLocalStorageParams {
  topLevelLabel?: string
}

export type RetrievedValue = any

export default function getFromLocalStorage({
  topLevelLabel = LOCAL_STORAGE_KEY
}: getFromLocalStorageParams): RetrievedValue {
  try {
    const serializedState: StorageValue = localStorage.getItem(topLevelLabel)
    return serializedState ? JSON.parse(serializedState) : undefined
  } catch (error) {
    return undefined
  }
}
