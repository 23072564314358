import styled from 'styled-components'

/* components */
import TableCell, { StyledTableCellProps } from './TableCell'

/* type declarations */
export type StyledTableHeadingProps = StyledTableCellProps

export const StyledTableHeading = styled(TableCell)<StyledTableHeadingProps>`
  min-width: 100px;
  white-space: nowrap;

  & > button {
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  && {
    padding-left: 2em;

    & > button {
      font-size: 1rem;
      padding: 0;
    }
  }
`

export default StyledTableHeading
