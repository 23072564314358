import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledPrimaryButtonProps = ButtonProps & StyledComponentProps

/**
 * StyledPrimaryButton styled-component
 */
export const StyledPrimaryButton = styled(Button)<StyledPrimaryButtonProps>`
  && {
    border: 1px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
  }
`

export default StyledPrimaryButton
