import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { RaisedLayout, RaisedLayoutProps } from 'components/templates'
import { FieldsConfigurationForm } from 'components/organisms'
import { Progress } from 'components/atoms'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'
import { types as connTypes } from 'state/connections'
import {
  actions as currActions,
  selectors as currSelectors,
  types as currTypes
} from 'state/currentConnection'
import { actions as dbActions, selectors as dbSelectors, types as dbTypes } from 'state/databases'

/* utils */
import { pageFlows, routes } from 'utils/constants'
import { convertStringBooleans } from 'utils/primitives'

/* type declarations */
export type ConfigureFieldsProps = RouteComponentProps<{}> & RaisedLayoutProps & {}

/**
 * ConfigureFields redux- & router-aware component
 */
export default function ConfigureFields({ location }: ConfigureFieldsProps) {
  const { pathname } = location

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* selector hooks */
  const configurationsMap: dbTypes.DatabaseConfigurationsMap =
    useSelector(dbSelectors.selectConfigurations) || []
  const connectionInState: currTypes.ConnectionProxy =
    useSelector(currSelectors.selectCurrentConnection) || {}

  /* state hooks */
  const [isFetching, setIsFetching] = useState(false)

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ currentPath: pathname, isAuthPath: true })), [
    dispatch,
    pathname
  ])

  useEffect(() => {
    if (connectionInState.connectionType) {
      dispatch(dbActions.fetchGetConfigurations({ databaseTypeId: connectionInState.connectionType }))
    }
  }, [connectionInState.connectionType, dispatch])

  /* constants */
  const headerProps = {
    subtitle: `Provide details below for this ${
      connectionInState.name || connectionInState.connectionType
    } database.`,
    title: `Configure Database Connection`
  }

  const formProps = {
    configurationsMap,
    pageVersion: connectionInState.id ? pageFlows.edit : pageFlows.new,
    primaryActions,
    secondaryActions
  }

  /* render */
  if (!connectionInState.connectionType) {
    return <Redirect to={routes.home} />
  }

  return (
    <RaisedLayout {...headerProps}>
      {isFetching ? <Progress /> : <FieldsConfigurationForm {...formProps} />}
    </RaisedLayout>
  )

  /* callbacks */
  function convertDbConfigsToProperties(configs: dbTypes.DatabaseConfigurations) {
    return configs.reduce((acc, { id, value }) => ({ ...acc, [id]: convertStringBooleans(value) }), {})
  }

  function convertDbConfigsToUserConfigs(
    configs: dbTypes.DatabaseConfigurations
  ): connTypes.UserConfigurations {
    return configs.map(({ id, value }) => ({ id, value }))
  }

  async function primaryActions(configurationsMap: dbTypes.DatabaseConfigurationsMap) {
    setIsFetching(true)

    const { advanced, database, primary } = configurationsMap

    const finalConnection = {
      ...connectionInState,
      configurations: convertDbConfigsToUserConfigs(database),
      ...convertDbConfigsToProperties(advanced),
      ...convertDbConfigsToProperties(primary)
    }

    dispatch(currActions.updateConnectionProxy(finalConnection))

    const actionCreator = connectionInState.id
      ? currActions.fetchPutConnection
      : currActions.fetchPostConnection
    try {
      const action = await dispatch(actionCreator(finalConnection))
      const updatedConnection = (await unwrapResult(action)) as connTypes.Connection

      history.push(`${routes.piiAction}/${updatedConnection.id}`)
    } catch (_) {
      setIsFetching(false)
    }
  }

  function secondaryActions() {
    history.push(connectionInState.id ? routes.home : routes.databasesNew)
  }
}
