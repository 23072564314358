/* local ducks */
import { types as appTypes } from 'state/app'

const initialState: appTypes.State = {
  currentPath: '',
  isAuth: false,
  isAuthPath: false,
  previousPath: undefined
}

export default initialState
