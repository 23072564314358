import { TableCell, TableCellProps } from '@material-ui/core'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableCellProps = TableCellProps & StyledComponentProps

/**
 * TableCell styled-component
 */
export const StyledTableCell = styled(TableCell)`
  position: relative;

  background-color: inherit;
  border: solid 1px #e4e4e4;

  width: 350px;
  height: initial;

  && {
    display: block;
    color: ${({ theme }) => theme.colors.grayDarker1};
    font-size: 1rem;
    padding: 0;

    height: auto;
    min-height: 56px;
  }
`

export default StyledTableCell
