import React from 'react'

/* components */
import { LeftAlignCell } from '../StyledElements'

/* state */
import { types as connTypes } from 'state/connections'

/* utils */
import { DATA_STATUSES_MAP } from 'utils/constants'
import { deslugify } from 'utils/primitives'

/* type declarations */
export type DataMappingStatusProps = {
  status?: connTypes.DataMappingStatus
}

export default function DataMappingStatus({ status }: DataMappingStatusProps) {
  return <LeftAlignCell>{DATA_STATUSES_MAP[status || deslugify(status) || '']}</LeftAlignCell>
}
