import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type InputProps = StyledComponentProps

export const Input = styled.input<InputProps>`
  max-height: 39px;
  width: 100%;

  && {
    padding: 9px 6px 10px;
  }
`

export default Input
