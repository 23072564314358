import React from 'react'
import { InputAdornment } from '@material-ui/core'

/* components */
import { TextField, TextFieldProps } from 'components/atoms'
import { InfoIcon } from 'components/atoms/icons/index'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type RenderFieldListProps = StyledComponentProps & {
  fields: Array<TextFieldProps>
}

/**
 * RenderFieldList presentational component
 */
export function RenderFieldList({ fields, ...rest }: RenderFieldListProps) {
  return (
    <>
      {fields.map((field: any) => {
        const InputProps = {
          endAdornment: field.description && (
            <InputAdornment position="end">
              <InfoIcon style={{ cursor: 'default' }} title={field.description} />
            </InputAdornment>
          )
        }

        switch (field.type) {
          default:
            return (
              <TextField
                InputProps={InputProps}
                key={`${field.id}-${field.label || 'field'}`}
                {...field}
                {...rest}
              />
            )
        }
      })}
    </>
  )
}

export default RenderFieldList
