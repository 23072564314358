/* local ducks */
import { types as schemaTypes } from 'state/schemas'

// initial state
export const initialState: schemaTypes.State = {}

export const initialSchemaState: schemaTypes.SchemaForUI = {
  entities: []
}

export default initialState
