import React, { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

/* components */
import { GeneralDialog } from 'components/molecules'
import { Progress } from 'components/atoms'
import { StyledRow } from './StyledRow'
import { StyledTextField } from './StyledTextField'
import { StyledPrimaryButton } from './StyledPrimaryButton'
import { StyledSecondaryButton } from './StyledSecondaryButton'

/* state */
import { useDispatch } from 'state/store'
import { actions as alertActions } from 'state/alerts'
import { actions as connActions } from 'state/connections'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'

/* utils */
import { EventChange } from 'utils/types'

/* type declarations */
export type DeleteConnectionFormProps = {
  handleCancel: () => void
  primaryActions: () => void
  open: boolean
}

/**
 * DeleteConnectionForm state component
 */
export default function DeleteConnectionForm({
  handleCancel,
  primaryActions,
  open
}: DeleteConnectionFormProps) {
  /* library hooks */
  const dispatch = useDispatch()

  /* state hooks */
  const defaultErrorState = {
    error: false,
    helperText: ''
  }
  const [errorState, setErrorState] = useState(defaultErrorState)
  const [isProcessing, setIsProcessing] = useState(false)
  const [value, setValue] = useState('')

  /* selector hooks */
  const currentConnection: currTypes.ConnectionProxy =
    useSelector(currSelectors.selectCurrentConnection) || {}

  /* constants */
  const dialogProps = {
    disabled: isProcessing,
    handleClose: handleCancel,
    name: currentConnection.name || '',
    message: `You have chosen to delete a database connection.`,
    instruction: `Please type the word "Delete" into the field below and select confirm to proceed.`,
    open,
    overrideButtons: createRenderButtons(),
    renderBody: createRenderBody(),
    style: { textAlign: 'center' } as React.CSSProperties
  }

  /* render */
  return <GeneralDialog data-testid="delete_connection_form" {...dialogProps} />

  /* callbacks */
  function createRenderButtons() {
    return (
      <StyledRow>
        <StyledPrimaryButton disabled={isProcessing} onClick={handleCancel}>
          Cancel
        </StyledPrimaryButton>
        <StyledSecondaryButton disabled={isProcessing} onClick={handleSubmit}>
          {isProcessing ? <Progress /> : <>Confirm</>}
        </StyledSecondaryButton>
      </StyledRow>
    )
  }

  function cleanup() {
    primaryActions()
    setValue('')
    setIsProcessing(false)
  }

  function createRenderBody() {
    const fieldProps = {
      error: errorState.error,
      helperText: errorState.helperText,
      onChange: handleChange,
      value
    }
    return (
      <StyledRow>
        <StyledTextField disabled={isProcessing} {...fieldProps} />
      </StyledRow>
    )
  }

  async function deleteConnection(connectionId: currTypes.ConnectionProxyId) {
    try {
      const action = await dispatch(connActions.fetchDeleteConnection({ connectionId }))
      unwrapResult(action)
    } catch (_) {}
  }

  async function handleSubmit() {
    if (value === `DELETE`) {
      setIsProcessing(true)
      await deleteConnection(currentConnection.id || '')
      await renewConnections()
      cleanup()

      dispatch(
        alertActions.createAlert({
          title: `"${currentConnection?.name || currentConnection.id}"`,
          message: `Connection has been successfully deleted.`,
          severity: 'success'
        })
      )
    } else {
      setErrorState({
        error: true,
        helperText: `Field does not match required text`
      })
    }
  }

  function handleChange(event: EventChange) {
    if (errorState.error) {
      setErrorState({
        error: false,
        helperText: ''
      })
    }

    const { value } = event.currentTarget
    setValue(value.toUpperCase())
  }

  async function renewConnections() {
    try {
      const action = await dispatch(connActions.fetchGetConnections({}))
      unwrapResult(action)
    } catch (_) {}
  }
}
