import breakpoints from './breakpoints'
import colors from './colors'
import fonts from './fonts'

const theme = { breakpoints, colors, fonts }

export interface Theme {
  breakpoints: typeof breakpoints
  colors: typeof colors
  fonts: typeof fonts
}

export default theme
