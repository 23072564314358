import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type OptionProps = StyledComponentProps

export const Option = styled.option<OptionProps>``

export default Option
