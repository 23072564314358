import { createSelector } from '@reduxjs/toolkit'

/* local ducks */
import { types as dbTypes } from 'state/databases'

/* foreign ducks */
import { RootState } from 'state/store'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'

/* utils */
import { getFromLocalStorage } from 'utils/app'
import { CURRENT_CONNECTION_STORAGE_LABEL, stateNames } from 'utils/constants'
import additionalConfigurations from 'utils/data/_additionalConfigurations'

const { currentConnection, databases } = stateNames

export const selectBaseDatabasesList = (state: RootState) => state[databases].list

// start selectConfigurations ----
export const selectConfigurations = (state: RootState) => {
  const current =
    getFromLocalStorage({})?.[CURRENT_CONNECTION_STORAGE_LABEL] || state[currentConnection] || {}
  const databaseConfigurations = state[databases].databaseConfigurations || []
  const { primary, advanced } = additionalConfigurations

  const handleAdvancedList = advanced.map((config: dbTypes.ConfigureFields) => {
    if (current[config.id] === undefined) {
      return {
        ...config,
        checked: typeof config.checked === 'boolean' ? config.checked : undefined,
        value: typeof config.checked === 'boolean' ? config.checked : config.value || ''
      }
    }

    return {
      ...config,
      checked: typeof current[config.id] === 'boolean' ? current[config.id] : undefined,
      value: current[config.id] !== undefined ? current[config.id] : ''
    }
  })

  const handlePrimaryList = primary.map((config: dbTypes.ConfigureFields) => {
    if (current[config.id] === undefined) {
      return {
        ...config,
        checked: typeof config.checked === 'boolean' ? config.checked : undefined,
        value: typeof config.checked === 'boolean' ? config.checked : config.value || ''
      }
    }
    return {
      ...config,
      checked: typeof current[config.id] === 'boolean' ? current[config.id] : undefined,
      value: current[config.id] !== undefined ? current[config.id] : ''
    }
  })

  const handleDatabaseList = databaseConfigurations.map((config: dbTypes.ConfigureFields) => {
    const currentConfigurations = current.configurations

    if (Array.isArray(currentConfigurations)) {
      const matched = currentConfigurations.find(
        (currConfig: dbTypes.ConfigureFields) => currConfig.id === config.id
      )

      return {
        ...config,
        value: matched ? matched.value : ''
      }
    }

    return {
      ...config,
      value: ''
    }
  })

  const configurationsMap: dbTypes.DatabaseConfigurationsMap = {
    advanced: handleAdvancedList,
    database: handleDatabaseList,
    primary: handlePrimaryList
  }

  return configurationsMap
}
// end selectConfigurations ----

export const selectCurrentDatabaseType = createSelector(
  currSelectors.selectCurrentConnection,
  selectBaseDatabasesList,
  (connection: currTypes.ConnectionProxy, databases: dbTypes.Databases) =>
    databases?.find((database: dbTypes.Database) => database.type === connection.connectionType)
)

export const selectDatabases = createSelector(
  selectBaseDatabasesList,
  currSelectors.selectCurrentConnection,
  (databases, currentConnection) => {
    if (databases && currentConnection) {
      const { connectionType } = currentConnection

      return databases.map((database: dbTypes.Database) =>
        database.type === connectionType ? { ...database, selected: true } : database
      )
    }

    return databases
  }
)

export const selectHasDatabasesInState = createSelector(
  selectBaseDatabasesList,
  (databases: dbTypes.Databases) => Array.isArray(databases) && databases.length > 0
)

const selectors = {
  selectBaseDatabasesList,
  selectConfigurations,
  selectCurrentDatabaseType,
  selectDatabases,
  selectHasDatabasesInState
}

export default selectors
