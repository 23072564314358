import React, { ReactNode } from 'react'

/* components */
import { Header, HeaderProps, Navbar } from 'components/organisms'
import { Title } from 'components/molecules'
import { Box } from 'components/atoms'
import { StyledContent, StyledContentProps } from './StyledContent'
import { StyledMultiRaisedLayout, StyledMultiRaisedLayoutProps } from './StyledMultiRaisedLayout'

/* type declarations */
export type MultiRaisedLayoutProps = StyledMultiRaisedLayoutProps & {
  contentContainerProps?: StyledContentProps
  headerProps?: HeaderProps
  sections: Sections
  style?: object
  subtitle?: string
  title: string
}

export type Sections = Array<Section>

type Section = ReactNode

/**
 * MultiRaisedLayout presentational component
 */
export default function MultiRaisedLayout({
  contentContainerProps,
  headerProps,
  sections,
  subtitle,
  title,
  ...rest
}: MultiRaisedLayoutProps) {
  return (
    <StyledMultiRaisedLayout data-testid="raised_layout" {...rest}>
      <Navbar />
      <StyledContent {...contentContainerProps}>
        <Header centerElements {...headerProps}>
          <Title title={title} subtitle={subtitle} />
        </Header>
        <Box display="flex" justifyContent="space-between">
          {sections.map((component) => component)}
        </Box>
      </StyledContent>
    </StyledMultiRaisedLayout>
  )
}
