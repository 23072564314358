import React, { FormEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

/* components */
import { GeneralForm } from 'components/molecules'

/* state */
import { selectors as authSelectors, types as authTypes } from 'state/auth'

/* utils */
import { routes } from 'utils/constants'
import { Fields } from 'utils/types'

/* type declarations */
export type VerifyMfaFormProps = {
  primaryActions: () => void
  secondaryActions: () => void
  setState: (verificationCode: authTypes.VerificationCode) => void
  verificationCode: authTypes.VerificationCode
}

export type ErrorState = {
  verificationError: boolean
  verificationHelperText: string | authTypes.VerificationCode
}

/**
 * VerifyMfaForm redux-aware component
 */
export default function VerifyMfaForm({ primaryActions, setState, verificationCode }: VerifyMfaFormProps) {
  /* state hooks */
  const defaultErrorState: ErrorState = {
    verificationError: false,
    verificationHelperText: ''
  }

  const [errorState, setErrorState] = useState(defaultErrorState)

  /* selector hooks */
  const { verificationCode: verificationFieldError }: authTypes.FieldError =
    useSelector(authSelectors.selectFieldErrors) || {}

  /* effect hooks */
  useEffect(() => {
    if (verificationFieldError) {
      setErrorState({
        verificationError: true,
        verificationHelperText: verificationFieldError
      })
    }
  }, [verificationFieldError])

  /* constants */
  const fields: Fields = [
    {
      error: errorState.verificationError,
      helperText: errorState.verificationHelperText,
      label: `Enter verification code`,
      name: 'verification-code',
      onChange: handleChange,
      required: true,
      value: verificationCode
    }
  ]

  const formProps = {
    buttonDirection: 'column',
    fields,
    handlePrimarySubmit,
    primaryButtonText: `Submit`,
    tertiaryProps: {
      message: `Return to Login`,
      to: routes.login
    }
  }

  /* render */
  // @ts-ignore
  return <GeneralForm data-testid="verify_mfa_form" {...formProps} />

  /* callbacks */
  function handleChange(event: FormEvent<HTMLFormElement>) {
    const { value } = event.currentTarget

    setErrorState(handleError(value))

    setState(value || '')
  }

  function handleError(_: string) {
    return defaultErrorState
  }

  function handlePrimarySubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    primaryActions()
  }
}
