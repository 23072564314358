import styled from 'styled-components'
import {
  // The compose utility: https://styled-system.com/api#compose
  compose,
  // regular imports
  flexbox,
  layout,
  position,
  space,
  // type imports
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps
} from 'styled-system'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StackProps = StyledComponentProps & FlexboxProps & LayoutProps & PositionProps & SpaceProps

/**
 * Stack styled-system component
 */
export const Stack = styled('div')<StackProps>`
  /* display */
  display: flex;
  flex-direction: column;

  padding: 2em 2em 1.5em;

  ${compose(flexbox, layout, position, space)}
`

export default Stack
