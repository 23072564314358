import styled from 'styled-components'

/* components */
import { Paper, PaperProps } from 'components/atoms'

/* type declarations */
export type StyledCardProps = PaperProps & {
  disabled?: boolean
}

export const StyledCard = styled(Paper)<StyledCardProps>`
  align-items: center;
  width: 350px;

  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.grayLight2 : theme.colors.white)};

  & > * {
    padding-bottom: 1em;
  }
`

export default StyledCard
