import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type RowItemProps = BoxProps

export const RowItem = styled(Box)<RowItemProps>``

export default RowItem
