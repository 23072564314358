import { createAction } from '@reduxjs/toolkit'

/* local ducks */
import { types as schemaTypes } from 'state/schemas'

/* foreign ducks */
import { types as connTypes } from 'state/connections'
import { types as piiTypes } from 'state/piiMapping'

/* custom action label */
// These constants are being used by redux-saga; Redux Toolkit doesn't require separate constant declarations
export const TRIGGER_SCHEMA_FLOW = 'saga/schema/trigger'

/* saga actions */
export const triggerSchemaFlow = createAction(TRIGGER_SCHEMA_FLOW)
export const retrieveSchemaStart = createAction<connTypes.ConnectionId>('saga/schema/start')
export const retrieveSchemaUpdate = createAction<piiTypes.SchemaProgressResponse>('saga/schema/update')
export const retrieveSchemaComplete = createAction<piiTypes.SchemaComplete>('saga/schema/complete')
export const retrieveSchemaFailure = createAction<piiTypes.SchemaFailure>('saga/schema/failure')

/* sync actions */
export const updateEntityProperty = createAction<schemaTypes.PropertyChange>('update/schema/property')
export const updateSchemaTable = createAction<schemaTypes.SchemaTableChange>('update/schema/table-name')
export const updateSelectedEntity = createAction<schemaTypes.EntityForUI>('update/entity/selected')

const actions = {
  retrieveSchemaStart,
  retrieveSchemaUpdate,
  retrieveSchemaComplete,
  retrieveSchemaFailure,
  TRIGGER_SCHEMA_FLOW,
  triggerSchemaFlow,
  updateEntityProperty,
  updateSchemaTable,
  updateSelectedEntity
}

export default actions
