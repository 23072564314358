import styled from 'styled-components'

/* components */
import { SubmitButtons, SubmitButtonsProps } from 'components/molecules'

/* type declarations */
export type StyledButtonsProps = SubmitButtonsProps

export const StyledButtons = styled(SubmitButtons)<StyledButtonsProps>``

export default StyledButtons
