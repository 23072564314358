import React, { ChangeEvent } from 'react'

/* components */
import * as Styled from '../StyledElements'

/* state */
import { types as piiTypes } from 'state/piiMapping'
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { ReactSelectMissingEventType, ReactSelectOption, StyledComponentProps } from 'utils/types'

/* type declarations */
export type RelationshipMapProps = StyledComponentProps & {
  columnName?: schemaTypes.RelationshipMap
  currentSelection?: schemaTypes.RelationshipMap
  handleChange: (event: ChangeEvent<HTMLSelectElement> | ReactSelectMissingEventType) => void
  items: piiTypes.DataAttributes
}

export default function RelationshipMap({
  columnName,
  currentSelection,
  handleChange,
  items,
  ...rest
}: RelationshipMapProps) {
  const options = convertToDisplay(items)
  const currentValue = getCurrentValue(items, currentSelection)

  const simpleKeyForResettingState = `${!!currentValue?.value}`

  return (
    <Styled.TableCell {...rest}>
      <Styled.Select
        key={simpleKeyForResettingState}
        name={columnName}
        onChange={onChange}
        options={options}
        placeholder="Select Relationship"
        value={currentValue || undefined}
      />
    </Styled.TableCell>
  )

  function convertToDisplay(items: piiTypes.DataAttributes): Array<ReactSelectOption> {
    return items?.map(({ displayValue, value }) => ({
      label: displayValue || 'Select Relationship',
      value
    }))
  }

  function getCurrentValue(
    items: piiTypes.DataAttributes = [],
    currentSelection?: schemaTypes.RelationshipMap
  ): ReactSelectOption | null {
    if (currentSelection) {
      const { displayValue, value } = items?.find(({ value }) => currentSelection === value) || {}

      return {
        label: displayValue || '',
        value: value || ''
      }
    }

    return null
  }

  function onChange({ value }: ReactSelectOption) {
    const event: ReactSelectMissingEventType = {
      currentTarget: {
        name: columnName || '',
        value
      }
    }

    handleChange(event)
  }
}
