import { TableCell, TableCellProps } from '@material-ui/core'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableCellProps = TableCellProps & StyledComponentProps & {}

/**
 * TableCell styled-component
 */
export const StyledTableCell = styled(TableCell)`
  height: 4em;

  && {
    background-color: inherit;
    color: inherit;
    font-size: 1rem;
    padding: 0;
  }
`

export default StyledTableCell
