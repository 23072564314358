import React, { ChangeEvent, useState } from 'react'

/* components */
import * as Styled from '../StyledElements'
import { StyledTableCellProps } from '../StyledElements/TableCell'

/* state */
import { types as schemaTypes } from 'state/schemas'

/* type declarations */
export type UserSelectedLabelProps = StyledTableCellProps & {
  columnName: schemaTypes.PropertyColumn
  handleChange: (event: ChangeEvent<HTMLSelectElement>) => void
  label: schemaTypes.EntityPropertyLabel
}

export default function UserSelectedLabel({
  columnName,
  handleChange,
  label = '',
  ...rest
}: UserSelectedLabelProps) {
  const [value, setValue] = useState(label)
  return (
    <Styled.CellInputContainer {...rest} style={{ padding: '0.5em !important' }}>
      <Styled.Input
        name={columnName}
        onChange={onChange}
        placeholder="Enter Label"
        type="text"
        value={value}
      />
    </Styled.CellInputContainer>
  )

  function onChange(event: any) {
    handleChange(event)
    setValue(event.currentTarget.value)
  }
}
