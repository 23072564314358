import styled from 'styled-components'
import { FormControlLabel, CheckboxProps as OriginalCheckboxProps } from '@material-ui/core'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledCheckboxFieldProps = OriginalCheckboxProps & StyledComponentProps

/**
 * StyledCheckboxField styled-component
 */
export const StyledCheckboxField = styled(FormControlLabel)<StyledCheckboxFieldProps>`
  color: ${({ theme }) => theme.colors.grayDarker1};

  & .MuiFormControlLabel-label.MuiTypography-body1 {
    color: ${({ theme }) => theme.colors.grayDarker1};
    font-size: 0.9rem;
  }

  && {
    & .MuiCheckbox-root {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export default StyledCheckboxField
