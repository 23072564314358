import React from 'react'

/* components */
import { Navbar } from 'components/organisms'
import { SubmitButtons, Title } from 'components/molecules'
import { StyledMainElement, StyledMainElementProps } from './StyledMainElement'
import { StyledContent } from './StyledContent'
import { StyledFlatLayout, StyledFlatLayoutProps } from './StyledFlatLayout'

/* type declarations */
export type FlatLayoutProps = StyledFlatLayoutProps & {
  buttonProps?: any
  containerProps?: StyledMainElementProps
  subtitle?: string
  subtitleProps?: object
  title: string
  titleProps?: object
}

/**
 * FlatLayout presentational component
 */
export default function FlatLayout({
  buttonProps,
  children,
  containerProps,
  subtitle,
  subtitleProps,
  title,
  titleProps,
  ...rest
}: FlatLayoutProps) {
  const additionalTitleProps = {
    subtitle,
    subtitleProps: { style: { paddingBottom: '1.5em' }, ...subtitleProps },
    style: {
      color: 'inherit'
    },
    title,
    ...titleProps
  }

  return (
    <StyledFlatLayout data-testid="flat_layout" {...rest}>
      <Navbar />
      <StyledMainElement {...containerProps}>{children}</StyledMainElement>
      <StyledContent>
        <Title {...additionalTitleProps} />
        {buttonProps && <SubmitButtons {...buttonProps} />}
      </StyledContent>
    </StyledFlatLayout>
  )
}
