import styled from 'styled-components'
import { Input, InputProps } from '@material-ui/core'
import { layout, space, LayoutProps, SpaceProps } from 'styled-system'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledSearchFieldProps = StyledComponentProps & InputProps & LayoutProps & SpaceProps

/**
 * StyledSearchField styled-system component
 */
export const StyledSearchField = styled(Input)<StyledSearchFieldProps>`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  height: 50px;
  width: 1110px;
  padding: 20px;

  ${layout}
  ${space}
`

export default StyledSearchField
