import { DatabaseConfigurations } from 'state/databases/types'

/*
      aws.region: us-east-1      
    aws.access.key.id: AKIAZST3OWKHSCTCW5B7      
    aws.secret.access.key: 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
*/

const primary: DatabaseConfigurations = [
  {
    description: undefined,
    id: 'name',
    label: 'Name',
    name: 'name',
    property: 'name',
    required: true,
    type: 'text'
  }
]

const advanced: DatabaseConfigurations = [
  {
    checked: true,
    description: undefined,
    id: 'supportsAccess',
    label: 'Allow Atlas to retrieve and access data in this database',
    name: 'supportsAccess',
    property: 'supportsAccess',
    required: true,
    type: 'checkbox'
  },
  {
    checked: true,
    description: undefined,
    id: 'supportsErasure',
    label: 'Allow Atlas to delete and update data in this database',
    name: 'supportsErasure',
    property: 'supportsErasure',
    required: true,
    type: 'checkbox'
  }
]

const additionalConfiguration = {
  advanced,
  primary
}

export default additionalConfiguration
