import React from 'react'

/* components */
import { SubmitButtons, SubmitButtonsProps } from 'components/molecules'
import RowItem from './RowItem'

/* utils */
import colors from 'App/theme/colors'

/* type declarations */
export type LoadConnectionProps = SubmitButtonsProps & {
  handleSubmit: () => void
  isActive: boolean
  isLoading?: boolean
}

export default function LoadConnection({ handleSubmit, isActive, isLoading, ...rest }: LoadConnectionProps) {
  const buttonProps = {
    handlePrimarySubmit: handleSubmit,
    primaryButtonText: `Load Selection`,
    primaryIsLoading: isLoading,
    primaryProps: {
      disabled: !isActive,
      style: !isActive
        ? {
            backgroundColor: colors.grayLight3
          }
        : undefined
    }
  }

  return (
    <RowItem {...rest}>
      <SubmitButtons {...buttonProps}>Load Selection</SubmitButtons>
    </RowItem>
  )
}
