import React, { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { WideContentLayout } from 'components/templates'
import { AccountScreen } from 'components/organisms'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type AccountProps = RouteComponentProps<{}>

/**
 * Account redux- & router-aware component
 */
export default function Account({ location: { pathname } }: AccountProps) {
  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ currentPath: pathname, isAuthPath: true })), [
    dispatch,
    pathname
  ])

  /* render */
  return (
    <WideContentLayout title="Atlas Settings">
      <AccountScreen secondaryActions={secondaryActions} />
    </WideContentLayout>
  )

  function secondaryActions() {
    history.push(routes.home)
  }
}
