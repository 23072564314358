import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type PlaceTableHeadWithBodyProps = StyledComponentProps & {
  isHidden?: boolean
}

/**
 * This element orchestrates the need to separate the Table Head from the Table Body when
 *  utilizing a static table design.
 */
export const PlaceTableHeadWithBody = styled.div<PlaceTableHeadWithBodyProps>`
  position: relative;
  display: inline-flex;
  flex-direction: column;

  vertical-align: top;

  && {
    ${({ isHidden, theme }) =>
      isHidden
        ? `
        border: 4px solid ${theme.colors.primary};
      `
        : ''}

    & .table-container-head {
      overflow: hidden;
    }

    & .entities-table-container td,
    & .entities-table-container th {
      width: 350px;
    }

    & .mapping-table-container td,
    & .mapping-table-container th {
      background-color: inherit;
      width: 250px;
    }
  }
`

export default PlaceTableHeadWithBody
