import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { PiiLayout, PiiLayoutProps } from 'components/templates'
import { PiiActionBar, PiiTables, ValidationModal } from 'components/organisms'
import { SubmitButtons, Title } from 'components/molecules'
import { Image, Text } from 'components/atoms'
import { ErrorContainer } from './ErrorContainer'
import { ExtraNavElements } from './ExtraNavElements'
import { NonTableContainer } from './NonTableContainer'
import { StyledProgressBar } from './StyledProgressBar'

/* assets */
import failureImage from 'assets/img/failure.png'

/* state */
import { useDispatch, sagaMiddleware } from 'state/store'
import { actions as appActions } from 'state/app'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'
import { actions as piiActions, selectors as piiSelectors, types as piiTypes } from 'state/piiMapping'
import { sagas as schemaSagas, selectors as schemaSelectors, types as schemaTypes } from 'state/schemas'

/* utils */
import { createRootSaga } from 'utils/app'
import { routes } from 'utils/constants'

/* type declarations */
export type PiiMappingProps = PiiLayoutProps & RouteComponentProps<any>

export default function PiiMapping({ location }: PiiMappingProps) {
  const { pathname } = location

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* state hooks */
  const [isFetchingSchema, setIsFetchingSchema] = useState(false)

  /* selector hooks */
  const entities: schemaTypes.EntitiesForUI = useSelector(schemaSelectors.selectEntities)
  const schemaProgress: piiTypes.SchemaMeta = useSelector(piiSelectors.selectSchemaMeta)
  const selectedEntity: schemaTypes.EntityForUI | undefined = useSelector(
    schemaSelectors.selectCurrentEntityInFocus
  )
  const userSelectedEntityName: schemaTypes.EntityName = useSelector(
    piiSelectors.selectUserSelectedEntityName
  )
  const openYamlValidationModal: piiTypes.OpenYamlValidationModal = useSelector(
    piiSelectors.selectOpenYamlValidationModal
  )
  const validationMessages: piiTypes.YamlValidationErrors = useSelector(
    piiSelectors.selectYamlValidationErrors
  )

  const connection: currTypes.ConnectionProxy = useSelector(currSelectors.selectCurrentConnection)
  const connectionId = connection?.id || ''
  const isDone = !!schemaProgress?.done

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ currentPath: pathname, isAuthPath: true })), [
    dispatch,
    pathname
  ])

  useEffect(() => {
    // Set the conditions for fetching the new schema.
    // Note: The additional guardrails here are for preventing the run command from being executed
    //    multiple times, which would result in multiple, unnecessary fetch requests
    if (connectionId && !isFetchingSchema && !entities && !isDone) {
      setIsFetchingSchema(true)
      sagaMiddleware.run(createRootSaga(schemaSagas, { connectionId }, {}))
      setIsFetchingSchema(false)
    }
  }, [connectionId, isDone, isFetchingSchema, entities])

  useEffect(() => {
    getDefaultListValues()

    async function getDefaultListValues() {
      return await Promise.all([
        dispatch(piiActions.fetchDataClasses()),
        dispatch(piiActions.fetchMaskingStrategies()),
        dispatch(piiActions.fetchRelationships())
      ])
    }
  }, [dispatch])

  /* constants */
  const layoutProps: PiiLayoutProps = {
    connection,
    extraNavElements: <ExtraNavElements />,
    selectedEntity,
    title: `Configure Mapping for table`
  }

  const actionBarProps = {
    connection,
    key: schemaProgress?.status || 'initial-state'
  }

  /* render */
  if (!connectionId) {
    return (
      <PiiLayout {...layoutProps}>
        <PiiActionBar {...actionBarProps} />
        <Text>No connection is selected.</Text>
      </PiiLayout>
    )
  }

  if (schemaProgress?.error) {
    const titleProps = {
      as: 'h2',
      title: `An error occurred when attempting to retrieve a schema`,
      style: {
        color: 'inherit',
        paddingBottom: '1em'
      },
      subtitle: schemaProgress?.error || ''
    }

    const buttonProps = {
      handlePrimarySubmit: () => void history.push(routes.connections),
      primaryButtonText: `Review Connection Settings`,
      primaryProps: {
        style: {
          width: 310
        }
      },
      style: {
        justifyContent: 'center',
        paddingTop: '2em'
      }
    }

    return (
      <PiiLayout {...layoutProps}>
        <PiiActionBar {...actionBarProps} />
        <ErrorContainer>
          <Image src={failureImage} />
          <Title {...titleProps} />
          <SubmitButtons {...buttonProps} />
        </ErrorContainer>
      </PiiLayout>
    )
  }

  if (!isDone) {
    return (
      <PiiLayout {...layoutProps}>
        <PiiActionBar {...actionBarProps} />
        <NonTableContainer>
          <StyledProgressBar variant="determinate" value={schemaProgress?.percentDone || 0} />
          <Text paddingTop="2em">
            We're currently retrieving your schema. You will be notified as soon as the full schema has been
            retrieved.
          </Text>
        </NonTableContainer>
      </PiiLayout>
    )
  }

  const tableProps = {
    connection,
    entities,
    selectedEntity,
    userSelectedEntityName
  }

  return (
    <PiiLayout {...layoutProps}>
      <PiiActionBar {...actionBarProps} />
      {entities && entities.length > 0 ? <PiiTables {...tableProps} /> : <Text>No schema found.</Text>}
      <ValidationModal unformattedMessages={validationMessages} open={openYamlValidationModal} />
    </PiiLayout>
  )
}
