/* local ducks */
import { types as authTypes } from 'state/auth'

const initialState: authTypes.State = {
  email: '',
  fieldErrors: {},
  mfaRefId: undefined,
  resetSecret: undefined,
  verificationCode: undefined
}

export default initialState
