import React from 'react'
import { Image, ImageProps } from 'components/atoms'

import src from 'assets/img/cloud-upload.svg'

export type CloudUploadIconProps = ImageProps

export function CloudUploadIcon({ ...rest }: CloudUploadIconProps) {
  return <Image src={src} {...rest} />
}

export default CloudUploadIcon
