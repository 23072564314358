import styled from 'styled-components'

/* components */
import { Row, RowProps } from 'components/atoms'

/* type declarations */
export type ActionBarProps = RowProps

export const ActionBar = styled(Row)<ActionBarProps>`
  grid-area: action-bar;
  align-items: center;

  padding: 1em 0 2em;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
`

export default ActionBar
