/* foreign ducks */
import { RootState } from 'state/store'

/* utils */
import { getFromLocalStorage } from 'utils/app'
import { CURRENT_CONNECTION_STORAGE_LABEL, stateNames } from 'utils/constants'

const { currentConnection } = stateNames

export const selectCurrentConnection = (state: RootState) =>
  getFromLocalStorage({})?.[CURRENT_CONNECTION_STORAGE_LABEL] || state[currentConnection]

const selectors = {
  selectCurrentConnection
}

export default selectors
