import styled from 'styled-components'

/* components */
import TableCell, { StyledTableCellProps } from './TableCell'

/* type declarations */
export type StyledTableHeadingProps = StyledTableCellProps

export const StyledTableHeading = styled(TableCell)<StyledTableHeadingProps>`
  min-width: 100px;

  & > button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: ${({ theme }) => theme.fonts.primary};

    width: 100%;
  }

  && {
    background-color: #ededed;
    color: #585858;

    & > button {
      font-size: 1rem;
      padding: 16px 2px 16px 22px;
    }
  }
`

export default StyledTableHeading
