/* local ducks */
import { types as userTypes } from 'state/user'

const initialState: userTypes.State = {
  email: '',
  fetchState: undefined,
  name: '',
  organizationName: '',
  organizationId: null,
  version: ''
}

export default initialState
