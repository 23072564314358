import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type TableNameButtonProps = ButtonProps

export const TableNameButton = styled(Button)<TableNameButtonProps>`
  width: initial;

  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0.5em;
    min-height: 2.5em;
    min-width: initial;
    white-space: pre-wrap;
  }
`

export default TableNameButton
