export default function deslugify(str: string | any): string | any {
  if (typeof str !== 'string' || !str) {
    return str
  }

  return str
    .split('_')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ')
}
