import { TextField } from '@material-ui/core'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTextFieldProps = StyledComponentProps & any

/**
 * StyledTextField styled-component
 */
export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  max-width: 445px;
`

export default StyledTextField
