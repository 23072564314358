import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState from './initialState'

/* foreign ducks */
import { types as alertTypes } from 'state/alerts'

const reducer = createReducer(initialState, {
  [actions.createAlert.type]: (state: alertTypes.State, action: PayloadAction<alertTypes.Alert>) => {
    return {
      ...state,
      open: true,
      ...action.payload
    }
  },

  [actions.dismissAlert.type]: (state: alertTypes.State) => ({
    ...state,
    ...initialState,
    open: false
  })
})

export default reducer
