import styled from 'styled-components'

/* components */
import { NoLayout, NoLayoutProps } from 'components/templates'

/* type declarations */
export type PageContainerProps = NoLayoutProps & {
  bg?: any
}

export const PageContainer = styled(NoLayout)<PageContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: url('${({ bg }) => bg}') no-repeat center center fixed;
  background-size: cover;
  color: rgba(255, 255, 255, 0.87);

  & > *:not(:first-child) {
    padding: 16px 0;
  }
`

export default PageContainer
