/* local ducks */
import { types as piiTypes } from 'state/piiMapping'

/* utils */
import { configureInit } from 'utils/app'
import { endpoints } from 'utils/constants'
import { HttpResponse, RequestOptions } from 'utils/types'

export function downloadStructure(
  { connectionId }: piiTypes.DownloadStructureParams,
  _: RequestOptions
): Promise<HttpResponse<string>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/structure/file`
  const init: RequestInit = configureInit({
    method: 'GET'
  })

  return fetch(input, init)
}

export function getDataClasses(): Promise<HttpResponse<piiTypes.DataClassOptions>> {
  const input: RequestInfo = endpoints.dataClasses
  const init: RequestInit = configureInit({})

  return fetch(input, init)
}

export function getMaskingStrategies(): Promise<HttpResponse<piiTypes.MaskingStrategyOptions>> {
  const input: RequestInfo = endpoints.maskingStrategies
  const init: RequestInit = configureInit({})

  return fetch(input, init)
}

export function getQueryPreview({
  connectionId
}: piiTypes.QueryPreviewParams): Promise<HttpResponse<piiTypes.QueryPreview>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/plan`
  const init: RequestInit = configureInit({})

  return fetch(input, init)
}

export function uploadStructure(
  { connectionId, formData }: piiTypes.UploadStructureParams,
  { signal }: RequestOptions
): Promise<HttpResponse<void>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/structure`
  const init: RequestInit = configureInit({
    method: 'POST',
    body: formData,
    dontSetJsonHeaderForBody: true,
    signal
  })

  return fetch(input, init)
}

const requests = {
  downloadStructure,
  getDataClasses,
  getMaskingStrategies,
  getQueryPreview,
  uploadStructure
}

export default requests
