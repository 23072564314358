import styled from 'styled-components'
import { Box, BoxProps } from 'components/atoms'

export type NonTableContainerProps = BoxProps

export const NonTableContainer = styled(Box)<NonTableContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  height: 100%;
`

export default NonTableContainer
