import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

/* components */
import ProtectedRoute from 'App/Routes/ProtectedRoute'
import * as Pages from 'pages/index'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type RoutesProps = { isAuth: boolean }

/* constant declarations */
export const unauthRoutes = [
  { key: 'auth-login', path: routes.login, component: Pages.Login },
  { key: 'auth-verify', path: routes.verify, component: Pages.VerifyMfa },
  { key: 'page-404', path: routes.page404, component: Pages.Page404 }
]

export const authRoutes = [
  // Note: order may affect page rendering
  { key: 'account', path: routes.account, component: Pages.Account },
  { key: 'connections', path: routes.connections, component: Pages.Connections },
  { key: 'connection-success', path: routes.connectionSuccess, component: Pages.ConnectionSuccess },
  { key: 'databases-new', path: routes.databasesNew, component: Pages.ChooseDatabase },
  { key: 'databases-edit', path: routes.databasesEdit, component: Pages.ChooseDatabase },
  { key: 'config-fields-edit', path: routes.matchConfigEdit, component: Pages.ConfigureFields },
  { key: 'config-fields-new', path: routes.matchConfigNew, component: Pages.ConfigureFields },
  {
    key: 'pii-mapping-action',
    path: [routes.piiActionWithParams, routes.piiAction],
    component: Pages.PiiMappingAction
  },
  { key: 'pii-mapping', path: [routes.piiMappingWithParams, routes.piiMapping], component: Pages.PiiMapping }
]

/**
 * Route presentational, router-aware component
 *
 * Handles the page-level component switch
 */
export default function Routes({ isAuth = false }: RoutesProps) {
  return (
    <Switch>
      {/* Unauthenticated Routes */}
      {unauthRoutes.map((route) => (
        <Route exact {...route} />
      ))}

      {/* Authenticated Routes */}
      {authRoutes.map((route) => (
        <ProtectedRoute exact isAuth={isAuth} {...route} />
      ))}

      {/* --- Home route --- */}
      {isAuth ? (
        <ProtectedRoute exact isAuth={isAuth} path={routes.home} component={Pages.Connections} />
      ) : (
        <Route exact path={routes.home} component={Pages.Login} />
      )}

      {/* Fallback route */}
      <Route path="/404" to={Pages.Page404} />
      <Redirect to="/404" />
    </Switch>
  )
}
