import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState from './initialState'
import { types as connTypes } from 'state/connections'

/* utils */
import { SortByProperty } from 'utils/types'

const reducer = createReducer(initialState, {
  [actions.fetchGetConnections.fulfilled.type]: (
    state: connTypes.State,
    action: PayloadAction<connTypes.Connections>
  ) => {
    state.list = action.payload
  },
  [actions.sortColumn.type]: (state: connTypes.State, action: PayloadAction<SortByProperty>) => {
    if (state.tableSortByColumn === action.payload) {
      state.tableSortReverse = !state.tableSortReverse
    } else {
      state.tableSortByColumn = action.payload
      state.tableSortReverse = false
    }
  }
})

export default reducer
