import styled from 'styled-components'

import { Row, RowProps } from 'components/atoms'

export type ButtonsRowProps = RowProps

export const ButtonsRow = styled(Row)<ButtonsRowProps>`
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
  padding-top: 1.5em;
  padding-bottom: 2em;
`

export default ButtonsRow
