import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledTextBodyProps = BoxProps

/**
 * StyledTextBody styled-component
 */
export const StyledTextBody = styled(Box)<StyledTextBodyProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 1.25em;
  max-width: 98%;

  & > * {
    padding-bottom: 1em;
  }
`

export default StyledTextBody
