import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledContentProps = BoxProps

/* StyledContent styled-component */
export const StyledContent = styled(Box)<StyledContentProps>`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  max-width: 44em;

  & > * {
    padding-bottom: 0.5em;
  }

  & > p {
    line-height: 1.6;
  }
`

export default StyledContent
