/* foreign ducks */
import { RootState } from 'state/store'

/* utils */
import { stateNames } from 'utils/constants'

const { alerts } = stateNames

export const selectAlert = (state: RootState) => state[alerts]

const selectors = {
  selectAlert
}

export default selectors
