import React, { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'

/* components */
import { CollapsibleBlock, Heading, HelpSlider } from 'components/molecules'
import * as Render from './RenderElements'
import * as Styled from './StyledElements'
import { StyledTableProps } from './StyledElements/Table'

/* state */
import { selectors as piiSelectors, types as piiTypes } from 'state/piiMapping'
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { MAX_TABLE_ROWS, schemaPropertyNames } from 'utils/constants'
import { ReactSelectMissingEventType } from 'utils/types'
import { RELATIONSHIP_DATA_ATTRIBUTES } from 'utils/data/_relationships'

/* type declarations */
export type MappingTableProps = StyledTableProps & {
  entities: schemaTypes.EntitiesForUI
  schemaValidations?: piiTypes.SchemaValidations
  selectedEntity?: schemaTypes.EntityForUI
  updateProperty: (mergedProperty: schemaTypes.EntityPropertyForUI) => void
}

export function MappingTable({
  entities,
  schemaValidations,
  selectedEntity,
  updateProperty,
  ...rest
}: MappingTableProps) {
  /* state hooks */
  const [openDrawer, setOpenDrawer] = useState(false)

  const openHelpArticlesInitialState = {
    tables: false,
    detectedColumns: false,
    dataClass: false,
    maskingStrategy: false,
    label: false,
    relationshipMap: false,
    pointsTo: false
  }
  const [openHelpArticles, setOpenHelpArticles] = useState(openHelpArticlesInitialState)

  /* selector hooks */
  const dataClasses: piiTypes.DataClassOptions = useSelector(piiSelectors.selectDataClassOptions) || []
  const maskingStrategies: piiTypes.MaskingStrategyOptions =
    useSelector(piiSelectors.selectMaskingStrategyOptions) || []
  const relationships: piiTypes.DataAttributes = useSelector(piiSelectors.selectDataAttributes) || []

  /* constants */
  const properties = selectedEntity?.properties || []
  const emptyRowsNeeded = calculateEmptyRows(properties.length, MAX_TABLE_ROWS)
  const entityName = selectedEntity?.name || ''
  const entityNames = entities.map(({ name = '' }) => ({ name }))

  const schemaValidation = schemaValidations?.[entityName]

  const helpArticles = [
    {
      open: openHelpArticles.tables,
      onChange: generateHandleHelpArticleChange('tables'),
      heading: 'Tables',
      body: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </>
      )
    },
    {
      open: openHelpArticles.detectedColumns,
      onChange: generateHandleHelpArticleChange('detectedColumns'),
      heading: 'Detected Columns',
      body: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </>
      )
    },
    {
      open: openHelpArticles.label,
      onChange: generateHandleHelpArticleChange('label'),
      heading: 'Label',
      body: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </>
      )
    },
    {
      open: openHelpArticles.dataClass,
      onChange: generateHandleHelpArticleChange('dataClass'),
      heading: 'PII Category',
      body: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </>
      )
    },
    {
      open: openHelpArticles.maskingStrategy,
      onChange: generateHandleHelpArticleChange('maskingStrategy'),
      heading: 'Masking Strategy',
      body: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </>
      )
    },
    {
      open: openHelpArticles.relationshipMap,
      onChange: generateHandleHelpArticleChange('relationshipMap'),
      heading: 'Relationship Map',
      body: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </>
      )
    },
    {
      open: openHelpArticles.pointsTo,
      onChange: generateHandleHelpArticleChange('pointsTo'),
      heading: 'Points To',
      body: (
        <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </>
      )
    }
  ]

  const headingProps = {
    className: 'mapping-table-heading'
  }

  const headings = [
    { label: 'Columns', helpProperty: 'detectedColumns' },
    { label: 'Label', helpProperty: 'label' },
    { label: 'PII Category', helpProperty: 'dataClass' },
    { label: 'Masking Strategy', helpProperty: 'maskingStrategy' },
    { label: 'Relationship Map', helpProperty: 'relationshipMap' },
    { label: 'Points to', helpProperty: 'pointsTo' }
  ]

  /* render */
  return (
    <Styled.PlaceTableHeadWithBody data-testid="mapping-table" {...rest}>
      {/* ===== Main Header ===== */}
      <Styled.Table className="mapping-table-container table-container-head">
        <Styled.TableHead>
          <Styled.TableRow className="mapping-heading-row">
            {headings.map(({ label, helpProperty }) => (
              <Render.MappingTableHeading
                key={`heading-${label}`}
                handleSecondaryClick={generateHandleSecondaryClick(helpProperty)}
                label={label}
                {...headingProps}
              />
            ))}
          </Styled.TableRow>
        </Styled.TableHead>
      </Styled.Table>
      {/* ===== Main Table ===== */}
      <Styled.Table className="mapping-table-container table-container-body" overflow="auto">
        <Styled.TableBody>
          {properties.length > 0 && properties.map((property) => renderRow(property))}
          {emptyRowsNeeded > 0 && renderEmptyRows(emptyRowsNeeded)}
        </Styled.TableBody>
      </Styled.Table>
      {/* ===== End Table Elements ===== */}
      {renderHelpDrawer()}
    </Styled.PlaceTableHeadWithBody>
  )

  /* render callbacks */
  function renderEmptyRows(count: number) {
    return [...new Array(count)].map((_, i) => (
      <Styled.TableRow key={`${entityName || 'no-entity'}-empty-row-${i}`}>
        <Styled.TableCell key={`${entityName || 'no-entity'}-empty-row-${i}-col-0`} />
        <Styled.TableCell key={`${entityName || 'no-entity'}-empty-row-${i}-col-1`} />
        <Styled.TableCell key={`${entityName || 'no-entity'}-empty-row-${i}-col-2`} />
        <Styled.TableCell key={`${entityName || 'no-entity'}-empty-row-${i}-col-3`} />
        <Styled.TableCell key={`${entityName || 'no-entity'}-empty-row-${i}-col-4`} />
        <Styled.TableCell key={`${entityName || 'no-entity'}-empty-row-${i}-col-5`} />
      </Styled.TableRow>
    ))
  }

  function renderHelpDrawer() {
    return (
      <HelpSlider handleClose={handleCloseDrawer} open={openDrawer}>
        <Heading text="Configure Table Mapping" />
        {helpArticles.map(({ open, onChange, heading, body }) => (
          <CollapsibleBlock key={`help-${heading}`} heading={heading} onChange={onChange} open={open}>
            {body}
          </CollapsibleBlock>
        ))}
      </HelpSlider>
    )
  }

  function renderRow(property: schemaTypes.EntityPropertyForUI) {
    const errors = schemaValidation?.columnErrors?.[property.name || '']

    const rowProps = {
      className: 'mapping-row',
      key: `${entityName || 'no-selected-entity'}-${property.name}`,
      showError: !!errors
    }

    return (
      <Styled.TableRow {...rowProps}>
        <Render.DetectedColumn difference={property.difference} errors={errors} label={property.name} />
        <Render.UserSelectedLabel
          columnName={schemaPropertyNames.label}
          handleChange={createHandleChange(property)}
          label={property.label || ''}
        />
        <Render.PiiDataClass
          columnName={schemaPropertyNames.dataClass}
          currentSelection={property.dataClass}
          items={dataClasses}
          handleChange={createHandleChange(property)}
        />
        <Render.MaskingStrategy
          columnName={schemaPropertyNames.maskingStrategy}
          currentSelection={property.maskingStrategy}
          dataAttribute={property.dataAttribute}
          defaultValue={property.defaultValue}
          items={maskingStrategies}
          handleChange={createSpecialDefaultValueHandler(property)}
        />
        <Render.RelationshipMap
          columnName={schemaPropertyNames.dataAttribute}
          currentSelection={property.dataAttribute}
          items={relationships}
          handleChange={createRelationshipMapChange(property)}
        />
        <Render.PointsTo
          columnName={schemaPropertyNames.reference}
          currentSelection={property.reference}
          dataAttribute={property.dataAttribute}
          entityNames={entityNames}
          handleChange={createHandleChange(property)}
        />
      </Styled.TableRow>
    )
  }

  /* callbacks */
  /**
   * Only add empty rows if the number of current rows is less than the max allowed number of rows.
   */
  function calculateEmptyRows(currentRows: number, maxRows: number): number {
    const countDifference = maxRows - currentRows

    return Math.sign(countDifference) === 1 ? countDifference : 0
  }

  function createHandleChange(originalProperty: schemaTypes.EntityPropertyForUI) {
    return function handleChange(event: ChangeEvent<HTMLSelectElement> | ReactSelectMissingEventType) {
      const { name, value } = event.currentTarget

      updateProperty({
        ...originalProperty,
        // Note: Emptying a field in HTML simply leaves an empty string; however, "undefined" is the value
        //  the code/API requires for empty values here.
        [name]: value || undefined
      })
    }
  }

  function createRelationshipMapChange(originalProperty: schemaTypes.EntityPropertyForUI) {
    // Note: Updates to relationship mappings requires updating two properties (sometimes)
    return function handleChange(event: ChangeEvent<HTMLSelectElement> | ReactSelectMissingEventType) {
      const { name, value } = event.currentTarget

      if ([RELATIONSHIP_DATA_ATTRIBUTES.ref_id, RELATIONSHIP_DATA_ATTRIBUTES.ref_immediate].includes(value)) {
        updateProperty({
          ...originalProperty,
          [name]: value || undefined
        })
      } else {
        updateProperty({
          ...originalProperty,
          [name]: value || undefined,
          reference: undefined
        })
      }
    }
  }

  function createSpecialDefaultValueHandler(originalProperty: schemaTypes.EntityPropertyForUI) {
    return function specialDefaultValueHandler(
      event: ChangeEvent<HTMLSelectElement> | ReactSelectMissingEventType
    ) {
      // Implementation note: The Masking Strategy field contains two types of controls, as opposed to only one. Therefore,
      //  "value" here is overloaded and can be the value of either field. This means, it can represent either the Masking Strategy
      //  that the user is selecting via the dropdown field OR the input value from the text field.
      // This overloading will make the next step fairly awkward to handle.
      const { value } = event.currentTarget

      // OPTION 1: Handle the "Remove" option
      if (schemaTypes.MASKING_STRATEGIES.remove === value) {
        updateProperty({
          ...originalProperty,
          maskingStrategy: schemaTypes.MASKING_STRATEGIES.default_value,
          defaultValue: undefined
        })
      }
      // OPTION 2: User is sending default_value
      else if (schemaTypes.MASKING_STRATEGIES.default_value === value) {
        updateProperty({
          ...originalProperty,
          maskingStrategy: schemaTypes.MASKING_STRATEGIES.default_value,
          defaultValue: ''
        })
      }
      // OPTION 3: User is sending an empty string - This occurs either when they first select "Default Value", or when
      //  they clear the text field
      else if (value === '') {
        updateProperty({
          ...originalProperty,
          maskingStrategy: schemaTypes.MASKING_STRATEGIES.default_value,
          defaultValue: value
        })
      }
      // OPTION 4: User selects default dropdown value ("Select Masking")
      else if (value === undefined) {
        updateProperty({
          ...originalProperty,
          maskingStrategy: value,
          defaultValue: undefined
        })
      }
      // OPTION 5: User is utilizing the text field - As in, the user is providing a value that isn't a recognized
      //  masking strategy.
      else if (!maskingStrategies.map(({ value }) => value).includes(value)) {
        updateProperty({
          ...originalProperty,
          maskingStrategy: schemaTypes.MASKING_STRATEGIES.default_value,
          defaultValue: value
        })
      }
      // OPTION 6: User is utilizing the dropdown menu to select items that don't have special business rules attached
      else {
        updateProperty({
          ...originalProperty,
          maskingStrategy: value,
          defaultValue: undefined
        })
      }
    }
  }

  function generateHandleHelpArticleChange(helpProperty: string) {
    return () =>
      void setOpenHelpArticles({ ...openHelpArticles, [helpProperty]: !openHelpArticles?.[helpProperty] })
  }

  function generateHandleSecondaryClick(helpProperty: string) {
    if (helpProperty.length) {
      return () => {
        triggerDrawer()
        setOpenHelpArticles({ ...openHelpArticles, [helpProperty]: !openHelpArticles?.[helpProperty] })
      }
    }

    return () => void triggerDrawer()
  }

  function handleCloseDrawer() {
    setOpenHelpArticles(openHelpArticlesInitialState)
    triggerDrawer()
  }

  function triggerDrawer() {
    /*
      TODO: Enable Help Drawer when ready
    */
    // setOpenDrawer(!openDrawer)
    setOpenDrawer(false)
  }
}

export default MappingTable
