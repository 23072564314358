import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type FlatCellContentsProps = StyledComponentProps

export const FlatCellContents = styled.div<FlatCellContentsProps>`
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  height: 100%;
  width: 100%;

  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 16px 22px;
  }
`

export default FlatCellContents
