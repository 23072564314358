import styled from 'styled-components'

/* components */
import { Stack, StackProps } from 'components/atoms'

/* type declarations */
export type StyledContainerProps = StackProps

/**
 * StyledContainer styled-component
 */
export const StyledContainer = styled(Stack)<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 1em;

  & > *:not(:last-child) {
    padding-bottom: 1.5em;
  }
`

export default StyledContainer
