import { LOCAL_STORAGE_KEY } from 'utils/constants'
import getFromLocalStorage from './getFromLocalStorage'

export interface saveToLocalStorageParams {
  topLevelLabel?: string
  key: string
  value: any
}

export default function saveToLocalStorage({
  topLevelLabel = LOCAL_STORAGE_KEY,
  key,
  value
}: saveToLocalStorageParams): void {
  try {
    const currentState: any = getFromLocalStorage({ topLevelLabel }) || {}
    const newStateToSave: string = JSON.stringify({ ...currentState, [key]: value })

    localStorage.setItem(topLevelLabel, newStateToSave)
  } catch (_) {}
}
