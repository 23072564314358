import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledTileProps = BoxProps & {
  disabled?: boolean
}

/**
 * StyledTile styled-component
 */
export const StyledTile = styled(Box)<StyledTileProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.grayLight2 : theme.colors.white)};
  border-radius: 0.25em;
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  margin: 0 0 1.97em;
  height: 10em;
  width: 10em;

  & img {
    max-width: 152px;
  }
`

export default StyledTile
