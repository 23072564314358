import { createAction } from '@reduxjs/toolkit'

/* local ducks */
import { types as alertTypes } from 'state/alerts'

export const createAlert = createAction<alertTypes.Alert>('alert/create')
export const dismissAlert = createAction('alert/dismiss')

const actions = {
  createAlert,
  dismissAlert
}

export default actions
