import React from 'react'

/* components */
import { SubmitButtons } from 'components/molecules'
import { ExternalLink, Row, Text } from 'components/atoms'
import { CloudServerIcon } from 'components/atoms/icons/index'
import { StyledContainer, StyledContainerProps } from './StyledContainer'

/* state */
import { types as connTypes } from 'state/connections'

/* utils */
import { externalUrls, routesFromConnections } from 'utils/constants'
import { EventClick } from 'utils/types'

/* type declarations */
export type ConnectionsNotFoundProps = StyledContainerProps & {
  handleRouteChange: (params: connTypes.ConnectionRouteState) => void
}

/**
 * ConnectionsNotFound presentational component
 */
export default function ConnectionsNotFound({ handleRouteChange, ...rest }: ConnectionsNotFoundProps) {
  /* render */
  const buttonsProps = {
    buttonDirection: 'column',
    handlePrimarySubmit: primaryActions,
    primaryButtonText: `Setup`,
    primaryProps: {
      style: {
        width: 220
      }
    }
  }

  return (
    <StyledContainer data-testid="no_connections_cta" {...rest}>
      <Row>
        <CloudServerIcon style={{ fontSize: '3.42rem' }} />
      </Row>
      <Text style={{ fontSize: '1.1rem' }}> Setup a new database connection.</Text>
      <Row>
        <SubmitButtons {...buttonsProps} />
      </Row>
      <ExternalLink href={externalUrls.learnMore} target="_blank" rel="noopener noreferrer">
        Learn More
      </ExternalLink>
    </StyledContainer>
  )

  /* callbacks */
  function primaryActions(_: EventClick) {
    handleRouteChange({ destination: routesFromConnections.new })
  }
}
