import styled from 'styled-components'
import { Dialog as OriginalDialog, DialogProps as oDialogProps } from '@material-ui/core'

/* type declarations */
export type StyledDialogProps = oDialogProps & {}

/**
 * Styled component
 */
export const StyledDialog = styled(OriginalDialog)<StyledDialogProps>`
  & .MuiPaper-root {
    max-width: initial;
    overflow: hidden;

    min-height: 736px;
    width: 1100px;
  }
`

export default StyledDialog
