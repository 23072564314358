import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { UnauthLayout, UnauthLayoutProps } from 'components/templates'
import { VerifyMfaForm } from 'components/organisms'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'
import { actions as authActions, types as authTypes } from 'state/auth'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type VerifyMfaProps = RouteComponentProps<{}> & UnauthLayoutProps

/**
 * VerifyMfa redux- & router-aware component
 */
export default function VerifyMfa({ location }: VerifyMfaProps) {
  const { pathname } = location

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* state hooks */
  const [verificationCode, setVerificationCode] = useState<authTypes.VerificationCode>('')

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ isAuthPath: false })), [dispatch, pathname])

  /* constants */
  const formProps = {
    primaryActions,
    secondaryActions,
    setState: setVerificationCode,
    verificationCode
  }

  /* render */
  return (
    <UnauthLayout
      subtitle="Please enter the verification code you received by email below."
      title="Two Factor Authentication">
      <VerifyMfaForm {...formProps} />
    </UnauthLayout>
  )

  /* callbacks */
  async function primaryActions() {
    try {
      const action = await dispatch(authActions.fetchPostVerification({ verificationCode }))
      await unwrapResult(action)
      history.push(routes.home)
    } catch (_) {}
  }

  async function secondaryActions() {}
}
