import { createAsyncThunk } from '@reduxjs/toolkit'

/* local ducks */
import requests from './_requests'
import { types as userTypes } from 'state/user'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'
import { actions as appActions } from 'state/app'

/* utils */
import { MESSAGES } from 'utils/constants'
import { ApiError, HttpResponse } from 'utils/types'

const fetchSendDiagnostics = createAsyncThunk(
  'fetch/user/send-diagnostics',
  async (params: userTypes.SendDiagnosticsParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI

    try {
      const res: HttpResponse<userTypes.SendDiagnosticsResponse> = await requests.postSendDiagnostics(
        params,
        { signal }
      )

      switch (res.status) {
        case 200:
        case 202: {
          return {}
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = (await res.json()) as ApiError

          dispatch(
            alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

const fetchGetUser = createAsyncThunk('fetch/user/get-user', async (_: void, thunkAPI) => {
  const { dispatch, rejectWithValue, signal } = thunkAPI

  try {
    const res: HttpResponse<userTypes.UserResponse> = await requests.getUser(null, { signal })

    switch (res.status) {
      case 200: {
        return (await res.json()) as userTypes.User
      }

      case 401: {
        const { title, message } = MESSAGES.auth

        dispatch(appActions.setAuth(false))
        dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

        return rejectWithValue({ message })
      }

      default: {
        const { title } = MESSAGES.general
        const { message }: ApiError = (await res.json()) as ApiError

        dispatch(
          alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
        )

        return rejectWithValue({ message })
      }
    }
  } catch (err) {
    const message = err?.message || ''
    const { title } = MESSAGES.general

    dispatch(
      alertActions.createAlert({
        title,
        message,
        severity: 'error'
      })
    )
    return rejectWithValue({ message })
  }
})

const fetchGetVersion = createAsyncThunk('fetch/user/version', async (_: void, thunkAPI) => {
  const { dispatch, rejectWithValue, signal } = thunkAPI

  try {
    const res = await requests.getVersion(null, { signal })

    switch (res.status) {
      case 200: {
        return await res.text()
      }

      case 401: {
        const { title, message } = MESSAGES.auth

        dispatch(appActions.setAuth(false))
        dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

        return rejectWithValue({ message })
      }

      default: {
        const { title } = MESSAGES.general
        const { message }: ApiError = (await res.json()) as ApiError

        dispatch(
          alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
        )

        return rejectWithValue({ message })
      }
    }
  } catch (err) {
    const message = err?.message || ''
    const { title } = MESSAGES.general

    dispatch(
      alertActions.createAlert({
        title,
        message,
        severity: 'error'
      })
    )
    return rejectWithValue({ message })
  }
})

const actions = {
  fetchGetUser,
  fetchGetVersion,
  fetchSendDiagnostics
}

export default actions
