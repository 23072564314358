import styled from 'styled-components'
import { StyledComponentProps } from 'utils/types'

export type SectionsContainerProps = StyledComponentProps

export const SectionsContainer = styled.section<SectionsContainerProps>`
  & > *:not(:first-child) {
    margin-top: 3em;
  }
`

export default SectionsContainer
