import React, { useState } from 'react'

/* components */
import { UploadFile } from 'components/molecules'
import { Button, ButtonProps, Progress, Text } from 'components/atoms'
import { UploadFileIcon } from 'components/atoms/icons/index'
import RowItem from './RowItem'

/* state */
import { types as currTypes } from 'state/currentConnection'

/* type declarations */
export type UploadYamlProps = ButtonProps & {
  connectionId: currTypes.ConnectionProxyId
  disabled: boolean
  onSuccess: () => void
}

export default function UploadYaml({ disabled, ...rest }: UploadYamlProps) {
  /* state hooks */
  const [isUploadingYaml, setIsUploadingYaml] = useState(false)

  /* render */
  return (
    <UploadFile
      setLoading={setIsUploadingYaml}
      {...rest}
      render={() => (
        <RowItem>
          <Button component="span" disabled={disabled || isUploadingYaml}>
            <UploadFileIcon
              style={{
                color: '#666666',
                fontSize: '1.4rem',
                marginRight: 6
              }}
            />
            {isUploadingYaml ? <Progress style={{ width: 148 }} /> : <Text message="Upload Structure YAML" />}
          </Button>
        </RowItem>
      )}
    />
  )
}
