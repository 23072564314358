import React, { ReactNode } from 'react'

/* components */
import { Header, HeaderProps, Navbar } from 'components/organisms'
import { Title } from 'components/molecules'
import { Box } from 'components/atoms'
import { StyledContent, StyledContentProps } from './StyledContent'
import { StyledWideLayout, StyledWideLayoutProps } from './StyledWideLayout'
import { StyledMain, StyledMainProps } from './StyledMain'

/* type declarations */
export type WideContentLayoutProps = StyledWideLayoutProps & {
  contentContainerProps?: StyledContentProps
  renderExtraTitleElement?: ReactNode
  headerProps?: HeaderProps
  mainProps?: StyledMainProps
  style?: object
  subtitle?: string
  title: string
  values?: object
}

/**
 * WideContentLayout presentational component
 */
export default function WideContentLayout({
  children,
  contentContainerProps,
  headerProps,
  mainProps,
  renderExtraTitleElement,
  subtitle,
  title,
  ...rest
}: WideContentLayoutProps) {
  const titleProps = {
    subtitle,
    title
  }

  const header = renderExtraTitleElement ? (
    <Header
      style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
      }}
      {...headerProps}>
      <Box width="100%">
        <Title
          style={{ paddingBottom: '0.5em' }}
          subtitleProps={{ style: { color: 'inherit' } }}
          {...titleProps}
        />
      </Box>
      <>{renderExtraTitleElement}</>
    </Header>
  ) : (
    <Header style={{ textAlign: 'left' }} {...headerProps}>
      <Title {...titleProps} />
    </Header>
  )

  return (
    <StyledWideLayout data-testid="wide_content_layout" {...rest}>
      <Navbar />
      <StyledContent {...contentContainerProps}>
        <>{header}</>
        <StyledMain as="main" {...mainProps}>
          {children}
        </StyledMain>
      </StyledContent>
    </StyledWideLayout>
  )
}
