import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState from './initialState'
import { types as userTypes } from 'state/user'

/* foreign ducks */
import { actions as authActions } from 'state/auth'

const reducer = createReducer(initialState, {
  [actions.fetchGetUser.pending.type]: () => ({
    ...initialState,
    fetchState: actions.fetchGetUser.pending.type
  }),

  [actions.fetchGetUser.fulfilled.type]: (state: userTypes.State, action: PayloadAction<userTypes.User>) => {
    state.email = action.payload?.email
    state.fetchState = actions.fetchGetUser.fulfilled.type
    state.name = action.payload?.name
    state.organizationName = action.payload?.organizationName
  },

  [actions.fetchGetUser.rejected.type]: (state: userTypes.State) => {
    state.fetchState = actions.fetchGetUser.rejected.type
  },

  [actions.fetchGetVersion.fulfilled.type]: (
    state: userTypes.State,
    action: PayloadAction<userTypes.Version>
  ) => {
    state.version = action.payload
  },

  [authActions.fetchLogout.pending.type]: () => ({ ...initialState })
})

export default reducer
