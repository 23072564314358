import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type LeftAlignCellProps = StyledComponentProps & StyledComponentProps & {}

/**
 * LeftAlignCell
 */
export const LeftAlignCell = styled.td<StyledComponentProps>`
  padding-left: 2em;
`

export default LeftAlignCell
