import React from 'react'

/* components */
import { SettingsIcon } from 'components/atoms/icons/index'
import { IconButton } from '../StyledElements'

/* utils */
import { EventClick } from 'utils/types'

/* type declarations */
export type SettingsProps = {
  connectionId: string
  handleClick: (event: EventClick) => void
}

export default function Settings({ connectionId, handleClick }: SettingsProps) {
  return (
    <IconButton name={connectionId} onClick={handleClick}>
      <SettingsIcon name={connectionId} />
    </IconButton>
  )
}
