import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState from './initialState'
import { types as dbTypes } from 'state/databases'

const reducer = createReducer(initialState, {
  [actions.fetchGetDatabases.pending.type]: (state) => {
    state.list = undefined
  },
  [actions.fetchGetDatabases.fulfilled.type]: (state, action: PayloadAction<dbTypes.ChooseDatabase>) => {
    state.list = action.payload
  },
  [actions.fetchGetConfigurations.pending.type]: (state) => {
    state.databaseConfigurations = undefined
  },
  [actions.fetchGetConfigurations.fulfilled.type]: (
    state,
    action: PayloadAction<dbTypes.DatabaseConfigurations>
  ) => {
    // @ts-ignore
    state.databaseConfigurations = action.payload
  }
})

export default reducer
