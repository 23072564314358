import React, { ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'

/* components */
import { SelectDownIcon } from 'components/atoms/icons/index'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
type StyledIconProps = StyledComponentProps & ComponentPropsWithoutRef<'svg'>
type SelectContainerProps = StyledComponentProps & ComponentPropsWithoutRef<'div'>
type StyledSelectProps = StyledComponentProps & ComponentPropsWithoutRef<'select'>

export type CustomSelectProps = StyledSelectProps & {
  iconProps?: StyledIconProps
  containerProps?: SelectContainerProps
}

export default function CustomSelect({ children, containerProps, iconProps, ...rest }: CustomSelectProps) {
  return (
    <SelectContainer {...containerProps}>
      <StyledSelect {...rest}>{children}</StyledSelect>
      <StyledIcon {...iconProps} />
    </SelectContainer>
  )
}

export const StyledIcon = styled(SelectDownIcon)<StyledIconProps>`
  position: absolute;
  top: 30%;
  right: 2%;
  pointer-events: none;
`

export const SelectContainer = styled.div<SelectContainerProps>`
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
`

export const StyledSelect = styled.select<StyledSelectProps>`
  position: relative;
`
