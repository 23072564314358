import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import FileSaver from 'file-saver'

/* local ducks */
import requests from './_requests'
import { types as piiTypes } from 'state/piiMapping'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'
import { actions as appActions } from 'state/app'
import { actions as schemaActions, types as schemaTypes } from 'state/schemas'

/* utils */
import { MESSAGES } from 'utils/constants'
import { ApiError, HttpResponse } from 'utils/types'

/* actions */
export const addSchemaValidationErrors = createAction<piiTypes.SchemaApiErrors>(
  'add-all/pii-mapping/validation-errors'
)

export const clearCurrentPiiMappingData = createAction('clear/pii-mapping/all')

export const clearQueryPreview = createAction('clear/pii-mapping/query-preview')

export const clearSchemaChanges = createAction('clear/pii-mapping/schema-changes')

export const clearSchemaValidationErrors = createAction('clear/pii-mapping/validation-errors')

export const clearYamlValidationErrors = createAction('clear/pii-mapping/clear-yaml-errors')

export const triggerYamlValidationModal = createAction<piiTypes.OpenYamlValidationModal>(
  'trigger/pii-mapping/yaml-validation-modal'
)

export const updateSelectedEntityName = createAction<schemaTypes.EntityName>('update/selected-schema')

export const updateYamlFileName = createAction<piiTypes.YamlFileName>('update/pii-mapping/yaml')

/* async actions */
export const fetchDataClasses = createAsyncThunk('fetch/pii-mapping/data-classes', async (_, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI

  try {
    const res: HttpResponse<piiTypes.DataClassOptions> = await requests.getDataClasses()

    switch (res.status) {
      case 200: {
        return (await res.json()) as piiTypes.DataClassOptions
      }

      case 401: {
        const { title, message } = MESSAGES.auth

        dispatch(appActions.setAuth(false))
        dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

        return rejectWithValue({ message })
      }

      default: {
        const { title } = MESSAGES.general
        const { message }: ApiError = (await res.json()) as ApiError

        dispatch(
          alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
        )

        return rejectWithValue({ message })
      }
    }
  } catch (err) {
    const message = err?.message || ''
    const { title } = MESSAGES.general

    dispatch(
      alertActions.createAlert({
        title,
        message,
        severity: 'error'
      })
    )
    return rejectWithValue({ message })
  }
})

export const fetchDownloadStructure = createAsyncThunk(
  'fetch/pii-mapping/download-structure',
  async (params: piiTypes.DownloadStructureParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI

    try {
      const res: HttpResponse<string> = await requests.downloadStructure(params, { signal })

      switch (res.status) {
        case 200: {
          const blob: Blob = await res.blob()

          // Note: Blobs aren't serializable, so the download "mutation" is handled here
          //  rather than elsewhere.
          FileSaver.saveAs(blob, `${params.connectionId}.yaml`)

          return {}
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        case 404: {
          /* Note: This status is most likely thrown when the connection lacks a YAML file. */

          const { message: apiMessage }: ApiError = (await res.json()) as ApiError

          const alertMessage = `Please upload a schema file (.yaml) before proceeding.`
          dispatch(
            alertActions.createAlert({
              title: `${apiMessage} (404)`,
              message: alertMessage,
              severity: 'warning'
            })
          )

          return rejectWithValue({ message: alertMessage })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = (await res.json()) as ApiError

          dispatch(
            alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchMaskingStrategies = createAsyncThunk(
  'import/pii-mapping/masking-strategies',
  async (_, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI

    try {
      const module = await import('utils/data/_maskingStrategies')
      return module.default
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchRelationships = createAsyncThunk(
  'import/pii-mapping/relationship-map',
  async (_, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI

    try {
      const module = await import('utils/data/_relationships')
      return module.default
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchQueryPreview = createAsyncThunk(
  'fetch/pii-mapping/query-preview',
  async (params: piiTypes.QueryPreviewParams, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI

    try {
      const res: HttpResponse<void> = await requests.getQueryPreview(params)

      switch (res.status) {
        case 200: {
          const { messages } = (await res.json()) as piiTypes.QueryPreviewResponse

          if (messages) {
            return messages.join('\n')
          }

          const { title, message } = MESSAGES.queryExecutionNoYaml

          dispatch(alertActions.createAlert({ title, message, severity: 'info' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = (await res.json()) as ApiError

          dispatch(
            alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchUploadStructure = createAsyncThunk(
  'fetch/schemas/upload-schema',
  async (params: piiTypes.UploadStructureParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI
    const { yamlErrors } = MESSAGES

    try {
      const res: HttpResponse<void> = await requests.uploadStructure(params, { signal })

      switch (res.status) {
        case 200: {
          dispatch(schemaActions.triggerSchemaFlow())
          return {}
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        case 422: {
          const { errors } = (await res.json()) as piiTypes.SchemaApiErrorsResponse

          return rejectWithValue({
            errors
          })
        }

        case 500: {
          const { message, messages }: ApiError = (await res.json()) as ApiError

          // Currently, the service doesn't provide a useful message on a 500 error.
          const modifiedMessage = `${yamlErrors.title}: ${message || yamlErrors.default}.`
          return rejectWithValue({ message: modifiedMessage, messages })
        }

        default: {
          const { message, messages }: ApiError = (await res.json()) as ApiError

          return rejectWithValue({ message, messages })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

const actions = {
  addSchemaValidationErrors,
  clearCurrentPiiMappingData,
  clearQueryPreview,
  clearSchemaChanges,
  clearSchemaValidationErrors,
  clearYamlValidationErrors,
  fetchDataClasses,
  fetchDownloadStructure,
  fetchMaskingStrategies,
  fetchQueryPreview,
  fetchRelationships,
  fetchUploadStructure,
  triggerYamlValidationModal,
  updateSelectedEntityName,
  updateYamlFileName
}

export default actions
