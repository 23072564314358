/* local ducks */
import { types as currTypes } from 'state/currentConnection'

/* utils */
import { configureInit } from 'utils/app'
import { endpoints } from 'utils/constants'
import { HttpResponse, RequestOptions } from 'utils/types'

export function postConnection(
  bodyParams: currTypes.ConnectionProxyFetchBody,
  { signal }: RequestOptions
): Promise<HttpResponse<currTypes.ConnectionProxyResponse>> {
  const input: RequestInfo = endpoints.connection
  const init: RequestInit = configureInit({
    method: 'POST',
    body: JSON.stringify(bodyParams),
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

export function putConnection(
  bodyParams: currTypes.ConnectionProxyFetchBody,
  { signal }: RequestOptions
): Promise<HttpResponse<currTypes.ConnectionProxyResponse>> {
  const input: RequestInfo = `${endpoints.connection}`
  const init: RequestInit = configureInit({
    method: 'PUT',
    body: JSON.stringify(bodyParams),
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

const requests = {
  postConnection,
  putConnection
}

export default requests
