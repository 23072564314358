import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

/* components */
import { Navbar } from 'components/organisms'
import { Box } from 'components/atoms'
import { CategoryNameHandler } from './CategoryNameHandler'
import { ModifiedHeader, ModifiedHeaderProps } from './ModifiedHeader'
import { StyledPiiLayout, StyledPiiLayoutProps } from './StyledPiiLayout'
import { StyledText } from './StyledText'
import { TableNameHandler } from './TableNameHandler'

/* state */
import { useDispatch } from 'state/store'
import { types as currTypes } from 'state/currentConnection'
import { actions as schemaActions, selectors as schemaSelectors, types as schemaTypes } from 'state/schemas'

/* type declarations */
export type PiiLayoutProps = StyledPiiLayoutProps & {
  connection: currTypes.ConnectionProxy
  extraNavElements: ReactNode
  headerProps?: ModifiedHeaderProps
  selectedEntity?: schemaTypes.EntityForUI
  title: string
}

export default function PiiLayout({
  children,
  connection,
  extraNavElements,
  headerProps,
  selectedEntity,
  title,
  ...rest
}: PiiLayoutProps) {
  /* library hooks */
  const dispatch = useDispatch()

  /* selector hooks */
  const hasEntityInFocus: boolean = useSelector(schemaSelectors.hasEntityInFocus)

  /* constants */
  const connectionId = connection?.id || ''
  const entityCategory = selectedEntity?.category || ''
  const entityName = selectedEntity?.name || ''
  const entityLabel = selectedEntity?.label || ''
  const modifiedTitle = `${title}${entityName ? ' : ' : ''}`

  const nameHandlerProps = {
    changeValue,
    connectionId,
    entityName,
    entityLabel,
    key: `actionbar-${entityName}`,
    modifiedTitle
  }

  const categoryNameProps = {
    changeValue,
    connectionId,
    entityCategory,
    entityName,
    key: `actionbar-${entityName}-${entityCategory}`
  }

  /* render */
  return (
    <StyledPiiLayout {...rest}>
      <Navbar />
      <Box gridArea="extra-nav">{extraNavElements}</Box>
      <ModifiedHeader {...headerProps}>
        {!hasEntityInFocus && <StyledText>Configure Mapping</StyledText>}
        {hasEntityInFocus && <TableNameHandler {...nameHandlerProps} />}
        {hasEntityInFocus && <CategoryNameHandler {...categoryNameProps} />}
      </ModifiedHeader>
      <Box gridArea="content" style={{ whiteSpace: 'nowrap' }}>
        {children}
      </Box>
    </StyledPiiLayout>
  )

  function changeValue(props: schemaTypes.SchemaTableChange) {
    dispatch(schemaActions.updateSchemaTable(props))
  }
}
