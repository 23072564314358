import styled from 'styled-components'
import { Box, BoxProps } from 'components/atoms'

export type RightColumnProps = BoxProps

export const RightColumn = styled(Box)<RightColumnProps>`
  flex: 1;
  margin-top: 0.5em;
`

export default RightColumn
