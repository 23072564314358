import React from 'react'

/* components */
import { CloseIcon } from 'components/atoms/icons/index'
import { StyledCloseButton, StyledCloseButtonProps } from './StyledCloseButton'

/* type declarations */
export type CloseButtonProps = StyledCloseButtonProps & {
  handleClose: () => void
}

export default function CloseButton({ className, handleClose, style, ...rest }: CloseButtonProps) {
  return (
    <StyledCloseButton onClick={handleClose} {...rest}>
      <CloseIcon />
    </StyledCloseButton>
  )
}
