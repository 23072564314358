import React from 'react'

/* components */
import { Button, ButtonProps, Progress, Text } from 'components/atoms'
import { RefreshIcon } from 'components/atoms/icons/index'
import RowItem from './RowItem'

/* type declarations */
export type RefreshSchemaProps = ButtonProps & {
  disabled: boolean
  handleSubmit: () => void
  isLoading?: boolean
}

export default function RefreshSchema({
  disabled,
  handleSubmit,
  isLoading = false,
  message,
  ...rest
}: RefreshSchemaProps) {
  const buttonContents = (
    <>
      <RefreshIcon
        style={{
          color: '#666666',
          fontSize: '1.4rem',
          marginRight: 6
        }}
      />
      <Text message="Refresh Schema" />
    </>
  )

  return (
    <RowItem {...rest}>
      <Button disabled={disabled} onClick={handleSubmit} style={{ minWidth: 155 }}>
        {isLoading ? <Progress style={{ minWidth: 155 }} /> : buttonContents}
      </Button>
    </RowItem>
  )
}
