import React, { ChangeEvent, useEffect, useState } from 'react'

/* components */
import { CompoundForm } from 'components/molecules'

/* state */
import { types as dbTypes } from 'state/databases'

/* utils */
import { pageFlows } from 'utils/constants'
import { EventClick, FieldSections, PageVersion } from 'utils/types'

/* type declarations */
export type FieldsConfigurationFormProps = {
  configurationsMap: dbTypes.DatabaseConfigurationsMap
  pageVersion: PageVersion
  primaryActions: (configurationsMap: dbTypes.DatabaseConfigurationsMap) => void
  secondaryActions: () => void
}

/**
 * FieldsConfigurationForm state component
 */
export default function FieldsConfigurationForm({
  configurationsMap: configs,
  pageVersion,
  primaryActions,
  secondaryActions,
  ...rest
}: FieldsConfigurationFormProps) {
  /* state hooks */
  const [advancedConfigs, setAdvancedConfigs] = useState<dbTypes.DatabaseConfigurations>(
    controlled(configs.advanced)
  )
  const [databaseConfigs, setDatabaseConfigs] = useState<dbTypes.DatabaseConfigurations>(
    controlled(configs.database)
  )
  const [primaryConfigs, setPrimaryConfigs] = useState<dbTypes.DatabaseConfigurations>(
    controlled(configs.primary)
  )

  /* effect hooks */
  useEffect(() => {
    if (configs.database.length !== databaseConfigs.length) setDatabaseConfigs(configs.database)
  }, [databaseConfigs, configs.database])

  /* constants */
  const sections: FieldSections = [
    {
      handleChange: configureHandleChange(primaryConfigs, setPrimaryConfigs),
      fields: primaryConfigs,
      id: 'primary',
      startAsHidden: false
    },
    {
      handleChange: configureHandleChange(databaseConfigs, setDatabaseConfigs),
      fields: databaseConfigs,
      id: 'database',
      startAsHidden: false
    },
    {
      handleChange: configureHandleChange(advancedConfigs, setAdvancedConfigs),
      fields: advancedConfigs,
      id: 'advanced',
      sectionTitle: `Advanced`,
      startAsHidden: true
    }
  ]

  const pageConfig = {
    [pageFlows.edit]: {
      primaryButtonText: `Save connection`
    },
    [pageFlows.new]: {
      primaryButtonText: `Save connection`
    }
  }

  const formProps = {
    handlePrimarySubmit,
    handleSecondarySubmit,
    primaryButtonText: pageConfig[pageVersion].primaryButtonText,
    secondaryButtonText: `Back`,
    secondaryButtonType: 'button',
    sections,
    ...rest
  }

  /* render */
  return <CompoundForm data-testid="configuration_form" {...formProps} />

  /* callbacks */
  function calculateNewConfig({
    config,
    checked,
    value,
    type
  }: {
    checked?: boolean
    config: dbTypes.ConfigureFields
    type: string
    value: any
  }): dbTypes.ConfigureFields {
    if (type === 'checkbox') {
      return { ...config, checked, value: `${checked}` }
    } else {
      return { ...config, value }
    }
  }

  function configureHandleChange(
    configs: dbTypes.DatabaseConfigurations,
    callback: React.Dispatch<React.SetStateAction<dbTypes.DatabaseConfigurations>>
  ) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { id, value, checked, type } = event.target

      const i = configs.findIndex((config) => config.id === id)

      if (i >= 0) {
        callback([
          ...configs.slice(0, i),
          calculateNewConfig({ config: configs[i], checked, type, value }),
          ...configs.slice(i + 1)
        ])
      }
    }
  }

  function controlled(configs: dbTypes.DatabaseConfigurations): dbTypes.DatabaseConfigurations {
    return configs.map((config) => ({
      ...config,
      checked: config.checked || false,
      value: typeof config.checked === 'boolean' ? `${config.checked}` : config.value || ''
    }))
  }

  function handlePrimarySubmit(event: EventClick) {
    event.preventDefault()

    primaryActions({
      advanced: advancedConfigs,
      database: databaseConfigs,
      primary: primaryConfigs
    })
  }

  function handleSecondarySubmit(event: EventClick) {
    event.preventDefault()

    secondaryActions()
  }
}
