import React from 'react'

/* components */
import { Tiles } from 'components/molecules'
import { Row } from 'components/atoms'
import { StyledSecondaryButton } from './StyledSecondaryButton'

/* state */
import { types as dbTypes } from 'state/databases'

/* utils */
import { pageFlows } from 'utils/constants'

/* type declarations */
export type DatabasesFormProps = {
  currentType?: dbTypes.DatabaseTypeId
  databases: dbTypes.ChooseDatabase
  pageVersion: string
  primaryActions: (event: any) => void
  secondaryActions: (event: any) => void
}

/**
 * DatabasesForm state-component
 */
export default function DatabasesForm({
  currentType,
  databases,
  pageVersion,
  primaryActions,
  secondaryActions
}: DatabasesFormProps) {
  /* constants */
  const isEditing = pageVersion === pageFlows.edit

  const items = databases.map(({ description, logoUrl, selected, type }) => ({
    description,
    disabled: isEditing ? currentType !== type : false,
    id: type,
    image: logoUrl,
    name: type,
    selected
  }))

  const tilesProps = {
    items,
    tileProps: {
      buttonProps: {
        onClick: primaryActions
      }
    }
  }

  /* render */
  return (
    <div data-testid="databases_form">
      <>{items.length > 0 && <Tiles {...tilesProps} />}</>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledSecondaryButton variant="outlined" onClick={secondaryActions}>
          Back
        </StyledSecondaryButton>
      </Row>
    </div>
  )

  /* callbacks */
}
