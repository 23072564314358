import React from 'react'
import { Checkbox as OriginalCheckbox } from '@material-ui/core'

/* components */
import { StyledCheckboxField, StyledCheckboxFieldProps } from './StyledCheckboxField'

/* type declarations */
export type CheckboxFieldProps = StyledCheckboxFieldProps & {
  label: string
}

/**
 * CheckboxField presentational component
 */
export default function CheckboxField({
  checked = false,
  label,
  id = 'checkbox-field',
  value,
  ...rest
}: CheckboxFieldProps) {
  const checkboxProps = {
    checked,
    id,
    value: determineValue(checked, value),
    ...rest
  }

  const fieldProps = {
    control: <OriginalCheckbox color="primary" {...checkboxProps} />,
    'data-testid': 'checkbox_field',
    id,
    label
  }

  return <StyledCheckboxField {...fieldProps} />
}

function determineValue(checked: boolean, value: any) {
  if (value === '') {
    return 'false'
  }

  if (typeof value === 'string') {
    return value
  }

  return `${checked}`
}
