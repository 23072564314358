import React, { FormEvent, PropsWithoutRef, ReactNode } from 'react'

/* components */
import { CustomLink, CustomLinkProps } from './CustomLink'
import { RenderFieldList, RenderFieldListProps } from './RenderFieldList'
import { StyledGeneralForm, StyledGeneralFormProps } from './StyledGeneralForm'
import SubmitButtons, { SubmitButtonsProps } from '../SubmitButtons'

/* utils */
import { Fields } from 'utils/types'

/* type declarations */
export type GeneralFormProps = StyledGeneralFormProps &
  SubmitButtonsProps & {
    containerProps?: StyledGeneralFormProps & PropsWithoutRef<JSX.IntrinsicElements['form']>
    fields?: Fields
    fieldProps?: RenderFieldListProps & any
    handleFormSubmit?: (event: FormEvent<HTMLInputElement>) => void
    renderAboveButtons?: ReactNode
    styleSecondaryAsLink?: boolean
    tertiaryProps?: CustomLinkProps
  }

/**
 * GeneralForm presentational component
 */
export default function GeneralForm({
  buttonDirection,
  fieldProps = {},
  fields,
  handleFormSubmit,
  handlePrimarySubmit,
  handleSecondarySubmit,
  primaryButtonText,
  primaryProps,
  renderAboveButtons,
  secondaryButtonText,
  secondaryProps,
  tertiaryProps,
  ...rest
}: GeneralFormProps) {
  const buttonProps = {
    buttonDirection,
    handlePrimarySubmit,
    handleSecondarySubmit,
    primaryButtonText,
    primaryProps,
    secondaryButtonText,
    secondaryProps
  }

  return (
    <StyledGeneralForm data-testid="general_form" onSubmit={onFormSubmit} {...rest}>
      <>{fields && <RenderFieldList fields={fields} {...fieldProps} />}</>
      <>{renderAboveButtons}</>
      <>{buttonProps && <SubmitButtons {...buttonProps} />}</>
      <>{tertiaryProps && <CustomLink {...tertiaryProps} />}</>
    </StyledGeneralForm>
  )

  function onFormSubmit(event: FormEvent<HTMLInputElement>) {
    event.preventDefault()

    if (handleFormSubmit) {
      handleFormSubmit(event)
    }
  }
}
