import styled from 'styled-components'
import { grid, layout, space, GridProps, LayoutProps, SpaceProps } from 'styled-system'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type ImageProps = StyledComponentProps & LayoutProps & SpaceProps & GridProps

/* Image styled-system component */
export const Image = styled.img<ImageProps>`
  display: block;
  ${grid}
  ${layout}
  ${space}
`

export default Image
