import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState, { initialSchemaState } from './initialState'
import { types as piiTypes } from 'state/piiMapping'

/* foreign ducks */
import { actions as connActions, types as connTypes } from 'state/connections'
import { types as currTypes } from 'state/currentConnection'
import {
  actions as schemaActions,
  fetchFinishSchema,
  fetchSaveSchema,
  types as schemaTypes
} from 'state/schemas'

const reducer = createReducer(initialState, {
  [actions.addSchemaValidationErrors.type]: (state: piiTypes.State) => {
    state.schemas = {}
  },

  [actions.clearCurrentPiiMappingData.type]: (state: piiTypes.State) => {
    state.schemaValidations = undefined
  },

  [actions.clearQueryPreview.type]: (state: piiTypes.State) => {
    state.queryPreview = undefined
  },

  [actions.clearSchemaValidationErrors.type]: (state: piiTypes.State) => {
    state.schemaValidations = undefined
  },

  [actions.clearSchemaChanges.type]: (state: piiTypes.State) => {
    state.schemaChanges.length = 0
  },

  [actions.clearYamlValidationErrors.type]: (state: piiTypes.State) => {
    state.yamlValidationErrors = undefined
  },

  [actions.fetchDataClasses.fulfilled.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.DataClassOptions>
  ) => {
    state.dataClasses = action.payload
  },

  [actions.fetchMaskingStrategies.fulfilled.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.MaskingStrategyOptions>
  ) => {
    state.maskingStrategies = action.payload
  },

  [actions.fetchQueryPreview.fulfilled.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.QueryPreview>
  ) => {
    state.queryPreview = action.payload
  },

  [actions.fetchRelationships.fulfilled.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.EntityRelationships>
  ) => {
    state.dataAttributes = action.payload.map((relationship) => ({
      displayValue: relationship.displayValue,
      value: relationship.value
    }))
  },

  [actions.fetchUploadStructure.fulfilled.type]: (state: piiTypes.State) => {
    state.yamlValidationErrors = undefined
    state.openYamlValidationModal = false
  },

  [actions.fetchUploadStructure.rejected.type]: (state: piiTypes.State, action: PayloadAction<any>) => {
    const { errors, message, messages } = action.payload

    state.yamlValidationErrors = errors || message || messages || undefined
    state.openYamlValidationModal = true
  },

  [actions.triggerYamlValidationModal.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.OpenYamlValidationModal>
  ) => {
    const openYamlValidationModal = action.payload

    state.openYamlValidationModal = openYamlValidationModal

    if (!openYamlValidationModal) {
      state.yamlValidationErrors = undefined
    }
  },

  [actions.updateSelectedEntityName.type]: (
    state: piiTypes.State,
    action: PayloadAction<schemaTypes.EntityName>
  ) => {
    state.selectedEntity = action.payload
  },

  [actions.updateYamlFileName.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.YamlFileName>
  ) => {
    state.yamlFileName = action.payload
  },

  [schemaActions.updateSelectedEntity.type]: (
    state: piiTypes.State,
    action: PayloadAction<schemaTypes.EntityForUI>
  ) => {
    state.selectedEntity = action.payload.name
  },

  [fetchFinishSchema.fulfilled.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.SchemaComplete>
  ) => {
    const { connectionId } = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId].error = undefined
    state.schemas[connectionId].status = fetchFinishSchema.fulfilled.type
    state.schemaChanges.length = 0
  },

  [fetchFinishSchema.rejected.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.SchemaFailure>
  ) => {
    const { connectionId, message } = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId].error = message
    state.schemas[connectionId].status = fetchFinishSchema.rejected.type
    state.schemas[connectionId].done = true
  },

  [fetchSaveSchema.fulfilled.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.SchemaComplete>
  ) => {
    const { connectionId } = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId].error = undefined
    state.schemas[connectionId].status = fetchSaveSchema.fulfilled.type
    state.schemaChanges.length = 0
  },

  [fetchSaveSchema.rejected.type]: (state: piiTypes.State, action: PayloadAction<piiTypes.SchemaFailure>) => {
    const { connectionId, message } = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId].error = message
    state.schemas[connectionId].status = fetchSaveSchema.rejected.type
    state.schemas[connectionId].done = true
  },

  [schemaActions.retrieveSchemaStart.type]: (
    state: piiTypes.State,
    action: PayloadAction<currTypes.ConnectionProxyId>
  ) => {
    const connectionId = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId] = initialSchemaState
    state.schemaChanges.length = 0
  },

  [schemaActions.retrieveSchemaFailure.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.SchemaFailure>
  ) => {
    const { connectionId, message } = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId].error = message
    state.schemas[connectionId].status = schemaActions.retrieveSchemaFailure.type
    state.schemas[connectionId].done = true
  },

  [schemaActions.retrieveSchemaComplete.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.SchemaComplete>
  ) => {
    const { connectionId } = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId].done = true
    state.schemas[connectionId].error = undefined
    state.schemas[connectionId].percentDone = 100
    state.schemas[connectionId].status = schemaActions.retrieveSchemaComplete.type
  },

  [schemaActions.retrieveSchemaUpdate.type]: (
    state: piiTypes.State,
    action: PayloadAction<piiTypes.SchemaProgressResponse>
  ) => {
    const { connectionId, meta } = action.payload

    if (!state.schemas[connectionId]) {
      state.schemas[connectionId] = {}
    }

    state.schemas[connectionId].done = meta.done
    state.schemas[connectionId].endTime = meta.endTime
    state.schemas[connectionId].numTablesDone = meta.numTablesDone
    state.schemas[connectionId].numTablesTotal = meta.numTablesTotal
    state.schemas[connectionId].percentDone = meta.percentDone
    state.schemas[connectionId].startTime = meta.startTime
  },

  [connActions.fetchDeleteConnection.fulfilled.type]: (
    state: piiTypes.State,
    action: PayloadAction<{ connectionId: connTypes.ConnectionId }>
  ) => {
    const { connectionId } = action.payload

    if (connectionId && state.schemas[connectionId]) {
      delete state.schemas[connectionId]
    }
  }
})

export default reducer
