import { createAction } from '@reduxjs/toolkit'

/* local ducks */
import { types as appTypes } from 'state/app'

/* utils */
import { saveToLocalStorage } from 'utils/app'
import { IS_AUTH_STORAGE_LABEL } from 'utils/constants'

export const setAuth = createAction('auth/set-is-auth', (isAuth: appTypes.IsAuth) => {
  saveToLocalStorage({ key: IS_AUTH_STORAGE_LABEL, value: isAuth })
  return {
    payload: isAuth
  }
})

export const updateRoute = createAction<appTypes.PathData>('app/route/update')

const actions = {
  setAuth,
  updateRoute
}

export default actions
