import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type TableNameInputProps = StyledComponentProps

export const TableNameInput = styled.input<TableNameInputProps>`
  width: initial;

  && {
    background-color: ${({ theme }) => theme.colors.grayLight1};
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0.5em;
    min-height: 2.5em;
    min-width: initial;
    padding: 0 0.5em;
  }
`

export default TableNameInput
