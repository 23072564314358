import React from 'react'
import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type StyledPrimaryButtonProps = ButtonProps & {
  reverseStyle?: boolean
}

const PropsFilter = ({ reverseStyle, ...rest }: StyledPrimaryButtonProps) => <Button {...rest} />

/**
 * StyledPrimaryButton styled-component
 */
export const StyledPrimaryButton = styled(PropsFilter)<StyledPrimaryButtonProps>`
  height: 2em;
  text-transform: uppercase;

  && {
    background-color: ${({ theme, disabled, reverseStyle }) => {
      if (disabled) {
        return theme.colors.gray
      }
      return !reverseStyle ? theme.colors.primary : theme.colors.white
    }};
    color: ${({ theme, reverseStyle }) => (!reverseStyle ? theme.colors.white : theme.colors.primary)};
    letter-spacing: 1.25px;

    &:hover {
      background-color: ${({ theme, reverseStyle }) =>
        !reverseStyle ? theme.colors.primaryDarker1 : theme.colors.whiteHover};
    }
  }
`

export default StyledPrimaryButton
