import React from 'react'

/* components */
import { ItemsProps, Tile, TileContainerProps } from 'components/molecules'
import { StyledTiles, StyledTilesProps } from './StyledTiles'

/* type declarations */
export type TilesProps = StyledTilesProps & {
  items: Array<ItemsProps>
  tileProps?: TileContainerProps
}

/**
 * Tiles presentational component
 */
export default function Tiles({ items, tileProps, ...rest }: TilesProps) {
  return (
    <StyledTiles data-testid="tiles" {...rest}>
      {items.map((item: ItemsProps) => (
        <Tile key={item.id} {...item} {...tileProps} />
      ))}
    </StyledTiles>
  )
}
