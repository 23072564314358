export default function mergeMessages(value: Array<string> | string | undefined) {
  if (typeof value === 'string') {
    return value
  }

  if (!value || !Array.isArray(value)) {
    return ''
  }

  return value.join('. ')
}
