import React from 'react'

/* components */
import { StyledButton, StyledButtonProps } from './StyledButton'
import { Text } from 'components/atoms'

/* type declarations */
export type ButtonProps = StyledButtonProps & {
  component?: any
  message?: string
  secondaryMessage?: string
  type?: string
}

/**
 * Button presentational component
 */
export default function Button({ children, secondaryMessage, message, type="submit", ...rest }: ButtonProps) {
  return (
    <StyledButton data-testid="button" type={type} {...rest}>
      {message || children}
      {secondaryMessage && (<Text fontWeight="500" message={secondaryMessage} />)}
    </StyledButton>
  )
}
