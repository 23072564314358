import styled from 'styled-components'

import { Box, BoxProps } from 'components/atoms'

export type StyledMainProps = BoxProps & {}

export const StyledMain = styled(Box)<StyledMainProps>`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 100%;
  }
`

export default StyledMain
