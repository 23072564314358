import styled from 'styled-components'
import { Table, TableProps } from '@material-ui/core'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableProps = StyledComponentProps & TableProps

/**
 * StyledTable styled-component
 */
export const StyledTable = styled(Table)<StyledTableProps>``

export default StyledTable
