import React, { LazyExoticComponent, ElementType } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  component: ElementType | LazyExoticComponent<any>
  isAuth: boolean
}

/**
 * ProtectedRoute router-aware component
 */
export default function ProtectedRoute({ component: Component, isAuth, ...rest }: ProtectedRouteProps) {
  return Component ? <Route {...rest} render={renderProps} /> : null

  function renderProps(props: RouteProps) {
    return isAuth ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: routes.login,
          state: { from: props.location }
        }}
      />
    )
  }
}
