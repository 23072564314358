export enum MASKING_STRATEGIES {
  none = 'none',
  default_value = 'default_value',
  internal_hash_correlate = 'internal_hash_correlate',
  not_masked = 'not_masked',
  remove = 'remove',
  null_value = 'null_value',

}

const maskingStrategies = [
  { value: MASKING_STRATEGIES.not_masked, displayValue: 'None' },
  { value: MASKING_STRATEGIES.remove, displayValue: 'Default Value' },
  { value: MASKING_STRATEGIES.default_value, displayValue: 'Custom Value' },
  { value: MASKING_STRATEGIES.internal_hash_correlate, displayValue: 'Hash' },
  { value: MASKING_STRATEGIES.null_value, displayValue: 'Null' }
]

export default maskingStrategies
