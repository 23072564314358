import React from 'react'

/* components */
import { Box, BoxProps, HorizontalLine, Subheading } from 'components/atoms'

/* type declarations */
export type HeadingProps = BoxProps & {
  text: string
}

/* component */
export default function Heading({ text, ...rest }: HeadingProps) {
  return (
    <Box width="100%" {...rest}>
      <Subheading>{text}</Subheading>
      <HorizontalLine />
    </Box>
  )
}
