import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { RaisedLayout, RaisedLayoutProps, WideContentLayout } from 'components/templates'
import { ConnectionsNotFound, ConnectionsTable, LoadingScreen } from 'components/organisms'
import { AddNewConnection } from './AddNewConnection'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions, selectors as appSelectors, types as appTypes } from 'state/app'
import { actions as connActions, selectors as connSelectors, types as connTypes } from 'state/connections'
import { actions as currActions, types as currTypes } from 'state/currentConnection'
import { actions as dbActions, selectors as dbSelectors, types as dbTypes } from 'state/databases'
import { actions as piiActions } from 'state/piiMapping'
import { selectors as userSelectors, types as userTypes } from 'state/user'

/* utils */
import { BASE_NEW_CONNECTION, routes, routesFromConnections, timeouts } from 'utils/constants'

/* type declarations */
export type ConnectionsProps = RouteComponentProps<{}> & RaisedLayoutProps

/**
 * Connections redux- & router-aware component
 */
export default function Connections({ location }: ConnectionsProps) {
  const { pathname } = location

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* state hooks */
  const [isLoaded, setIsLoaded] = useState(false)

  /* selector hooks */
  const isAuth: appTypes.IsAuth = useSelector(appSelectors.selectIsAuth)
  const connections: connTypes.Connections = useSelector(connSelectors.selectConnections) || []
  const hasDatabasesInState: dbTypes.HasDatabasesInState = useSelector(dbSelectors.selectHasDatabasesInState)
  const organizationName: userTypes.OrganizationName = useSelector(userSelectors.selectOrganizationName)

  /* effect hooks */
  // Update current route
  useEffect(() => void dispatch(appActions.updateRoute({ currentPath: pathname, isAuthPath: true })), [
    dispatch,
    pathname
  ])

  // On load, reset the user's currently selected connection and/or schema
  useEffect(() => {
    dispatch(currActions.updateConnectionProxy({}))
    dispatch(piiActions.clearCurrentPiiMappingData())
    dispatch(piiActions.clearQueryPreview())
  }, [dispatch])

  // Fetch connections
  useEffect(() => {
    if (isAuth && !isLoaded) {
      getConnections()
    }

    async function getConnections() {
      await dispatch(connActions.fetchGetConnections({}))
      setIsLoaded(true)
    }
  }, [dispatch, isAuth, isLoaded, setIsLoaded])

  // Handle if this page renders despite the lack of authentication
  useEffect(() => {
    setTimeout(() => {
      if (!isAuth) {
        history.push(routes.login)
      }
    }, timeouts.short)
  }, [history, isAuth])

  // Load database types if they're not already loaded; this information will be used elsewhere in the app
  useEffect(() => {
    if (!hasDatabasesInState) {
      dispatch(dbActions.fetchGetDatabases())
    }
  }, [dispatch, hasDatabasesInState])

  /* render */
  if (isLoaded && connections.length > 0) {
    const newButtonProps = {
      handlePrimarySubmit: () => void handleRouteChange({ destination: routesFromConnections.new }),
      primaryButtonText: `Add a New Database Connection`
    }

    const layoutProps = {
      headerProps: {
        style: {
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          paddingBottom: '1.4em'
        }
      },
      renderExtraTitleElement: <AddNewConnection {...newButtonProps} />,
      subtitle: `Existing ${organizationName} database connections`,
      title: `Manage Atlas Database Connections`
    }

    return (
      <WideContentLayout mainProps={{ backgroundColor: 'white' }} {...layoutProps}>
        <ConnectionsTable connections={connections} handleRouteChange={handleRouteChange} />
      </WideContentLayout>
    )
  }

  if (isLoaded) {
    const layoutProps = {
      contentContainerProps: { style: { maxWidth: 540 } },
      paperContainerProps: {
        style: {
          margin: '0 auto',
          padding: '1.97em 2.46em 1.77em',
          width: 350
        }
      },
      subtitle: `Atlas has been successfully deployed for ${
        organizationName || 'your company'
      }. The next step is to set up your first database connection. Once you have configured your database connection, you can then add PII mapping for each database.`,
      title: `Get Started with Atlas`
    }

    return (
      <RaisedLayout {...layoutProps}>
        <ConnectionsNotFound handleRouteChange={handleRouteChange} />
      </RaisedLayout>
    )
  }

  return <LoadingScreen />

  /* callbacks */
  function findConnection(
    connectionId: connTypes.ConnectionId,
    connections: connTypes.Connections
  ): connTypes.Connection | undefined {
    return connections.find((connection: connTypes.Connection) => connection.id === connectionId)
  }

  function handleRouteChange({ connectionId, destination, type }: connTypes.ConnectionRouteState) {
    loadConnectionToState(connectionId)

    if (connectionId && destination === routesFromConnections.pii) {
      history.push(`${routes.piiAction}/${connectionId}`)
    } else if (connectionId && type && destination === routesFromConnections.edit) {
      history.push(routes.generateConfigRoute({ connectionId, databaseTypeId: type || '' }))
    } else {
      history.push(routes.generateDatabasesRoute({ connectionId }))
    }
  }

  function loadConnectionToState(connectionId: connTypes.ConnectionId = ''): void {
    const connectionIfFound = findConnection(connectionId, connections) || BASE_NEW_CONNECTION
    const currentConnection: currTypes.ConnectionProxy = connectionId
      ? connectionIfFound
      : BASE_NEW_CONNECTION

    dispatch(currActions.updateConnectionProxy(currentConnection))
  }
}
