import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type StyledSecondaryButtonProps = ButtonProps

/**
 * StyledSecondaryButton styled-component
 */
export const StyledSecondaryButton = styled(Button)<StyledSecondaryButtonProps>`
  width: 200px;

  && {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }
`

export default StyledSecondaryButton
