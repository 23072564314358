import React, { ChangeEvent } from 'react'

/* components */
import * as Styled from '../StyledElements'

/* state */
import { types as piiTypes } from 'state/piiMapping'
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { DEFAULT_DROPDOWN_VALUE } from 'utils/constants'
import { ReactSelectMissingEventType, ReactSelectOption, StyledComponentProps } from 'utils/types'

/* type declarations */
export type PiiDataClassProps = StyledComponentProps & {
  columnName: schemaTypes.PropertyColumn
  currentSelection?: schemaTypes.PiiDataClass
  handleChange: (event: ChangeEvent<HTMLSelectElement> | ReactSelectMissingEventType) => void
  items: piiTypes.DataClassOptions
}

export default function PiiDataClass({
  columnName,
  currentSelection,
  items,
  handleChange,
  ...rest
}: PiiDataClassProps) {
  const options = convertToDisplay(items)
  const currentValue = getCurrentValue(items, currentSelection)

  const simpleKeyForResettingState = `${!!currentValue?.value}`

  return (
    <Styled.TableCell {...rest}>
      <Styled.Select
        key={simpleKeyForResettingState}
        name={columnName}
        onChange={onChange}
        options={options}
        placeholder="Select Data Class"
        value={currentValue || undefined}
      />
    </Styled.TableCell>
  )

  function convertToDisplay(items: piiTypes.DataClassOptions): Array<ReactSelectOption> {
    return [
      DEFAULT_DROPDOWN_VALUE,
      ...items?.map(({ displayValue, value }) => ({ label: displayValue, value }))
    ]
  }

  function getCurrentValue(
    items: piiTypes.DataClassOptions = [],
    currentSelection?: schemaTypes.PiiDataClass
  ): ReactSelectOption | null {
    if (currentSelection) {
      const { displayValue, value } = items?.find(({ value }) => currentSelection === value) || {}

      return {
        label: displayValue || '',
        value: value || ''
      }
    }

    return null
  }

  function onChange({ value }: ReactSelectOption) {
    const event: ReactSelectMissingEventType = {
      currentTarget: {
        name: columnName,
        value
      }
    }

    handleChange(event)
  }
}
