import styled from 'styled-components'
import { Box, BoxProps } from 'components/atoms'

export type LeftColumnProps = BoxProps

export const LeftColumn = styled(Box)<LeftColumnProps>`
  flex: 0.25;
  font-style: italic;
  margin-top: 0.5em;
`

export default LeftColumn
