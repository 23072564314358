import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledNavbarProps = BoxProps

/**
 * StyledNavbar styled-component
 */
export const StyledNavbar = styled(Box)<StyledNavbarProps>`
  /* display */
  grid-area: nav;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto 1fr auto auto;
  grid-template-areas: 'logo title . user log';
  align-items: center;

  height: 70px;
  width: 100%;

  /* color */
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};

  /* layout */
  border-bottom: 1px solid black;

  /* space */

  & > * {
    padding-right: 1em;
  }
`

export default StyledNavbar
