import React from 'react'

/* components */
import { Link, LinkProps } from 'components/atoms'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type ExtraNavElementsProps = LinkProps

export function ExtraNavElements() {
  return <Link to={routes.connections}>Back to Database Connections</Link>
}

export default ExtraNavElements
