import styled from 'styled-components'
import { TableHead, TableHeadProps } from '@material-ui/core'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableHeadProps = StyledComponentProps & TableHeadProps & {}

/**
 * StyledHead styled-component
 */
export const StyledHead = styled(TableHead)<StyledTableHeadProps>`
  background-color: ${({ theme }) => theme.colors.grayLight2};

  & .MuiTableCell-head {
    border-bottom: 1px solid lightgray;
    font-weight: bold;
  }

  .MuiTableCell-head:focus,
  .MuiTableCell-head:active,
  button:focus,
  button:active {
    outline: none;
  }
`

export default StyledHead
