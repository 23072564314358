import React from 'react'
import * as Styled from './StyledElements'

/* type declarations */
export type TableHeadingProps = {
  label: string
  sortByProp?: string
  style?: object
}

/**
 * RenderSingleHeading presentational component
 */
export function TableHeading({ label, ...rest }: TableHeadingProps) {
  return <Styled.TableHeading {...rest}>{label}</Styled.TableHeading>
}

export default TableHeading
