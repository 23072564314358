import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledCompoundFormProps = StyledComponentProps

/**
 * StyledCompoundForm styled-component
 */
export const StyledCompoundForm = styled.form<StyledCompoundFormProps>`
  display: flex;
  flex-direction: column;

  & .MuiFormControl-root.MuiTextField-root:not(:last-child) {
    padding-bottom: 1.97em;
  }
`

export default StyledCompoundForm
