import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type IconFloatRightProps = StyledComponentProps

export const IconFloatRight = styled.div<IconFloatRightProps>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  right: 5%;
  top: 30%;

  font-size: 1.4em;
`

export default IconFloatRight
