import styled from 'styled-components'
import { Box, BoxProps } from 'components/atoms'

export type PositionContentsProps = BoxProps

export const PositionContents = styled(Box)<PositionContentsProps>`
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-rows: 4em 4em 4em auto 4em 2.5em;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas:
    '. . .'
    'nav nav nav'
    '. . .'
    '. content .'
    '. footer .'
    '. . .';
  width: 100%;
`

export default PositionContents
