import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type ColumnProps = BoxProps

/**
 * Styled component
 */
export const Column = styled(Box)<ColumnProps>`
  flex: 1;

  display: flex;
  flex-direction: column;
`

export default Column
