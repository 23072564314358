/* local ducks */
import { types as dbTypes } from 'state/databases'

// initial state
export const initialState: dbTypes.State = {
  databaseConfigurations: undefined,
  databaseTypeId: undefined,
  list: undefined
}

export default initialState
