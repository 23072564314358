import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type AlertContainerProps = StyledComponentProps

export const AlertContainer = styled.div<AlertContainerProps>`
  width: 96%;

  position: fixed;
  left: 2%;
  z-index: 5;

  & > * + * {
    margin-top: 1em;
  }

  & .MuiAlert-root {
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 1px 10px 1px #222;
    opacity: 0.99;
  }

  & .MuiAlertTitle-root {
    height: 28px;
    margin: 0 0 5px 19px;
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: 0.5px;
  }

  & .MuiAlert-message {
    padding: 0 0 8px;
  }

  & .MuiAlert-message > p {
    min-height: 20px;
    margin: 5px 0 0 19px;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.25px;
  }

  & .MuiAlert-action {
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
  }
`

export default AlertContainer
