/* local ducks */
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { configureInit } from 'utils/app'
import { endpoints } from 'utils/constants'
import {HttpResponse, RequestOptions} from 'utils/types'
import {types as piiTypes} from "../piiMapping";

export function finishSchema(
  { connectionId, schema }: schemaTypes.FinalSchemaRequestParams,
  _: RequestOptions
): Promise<HttpResponse<schemaTypes.SchemaForUI>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/schema/finalize`
  const init: RequestInit = configureInit({
    method: 'PUT',
    body: JSON.stringify(schema)
  })

  return fetch(input, init)
}

export function getSchema(
  { connectionId }: schemaTypes.InitialSchemaRequestParams,
  _: RequestOptions
): Promise<HttpResponse<schemaTypes.SchemaForUI>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/schema`
  const init: RequestInit = configureInit({
    method: 'GET'
  })

  return fetch(input, init)
}

export function getSchemaProgress(
  { connectionId }: schemaTypes.InitialSchemaRequestParams,
  _: RequestOptions
): Promise<HttpResponse<piiTypes.SchemaProgressApiResponse>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/schema/progressreport`
  const init: RequestInit = configureInit({
    method: 'GET'
  })

  return fetch(input, init)
}

export function saveSchema(
  { connectionId, schema }: schemaTypes.FinalSchemaRequestParams,
  _: RequestOptions
): Promise<HttpResponse<schemaTypes.SchemaForUI>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/schema`
  const init: RequestInit = configureInit({
    method: 'PUT',
    body: JSON.stringify(schema)
  })

  return fetch(input, init)
}

export function startSchemaRetrieval(
  { connectionId }: schemaTypes.InitialSchemaRequestParams,
  _: RequestOptions
): Promise<HttpResponse<void>> {
  const input: RequestInfo = `${endpoints.connection}/${connectionId}/schema/retrieve`
  const init: RequestInit = configureInit({
    method: 'POST'
  })

  return fetch(input, init)
}

const requests = {
  finishSchema,
  getSchema,
  getSchemaProgress,
  saveSchema,
  startSchemaRetrieval
}

export default requests
