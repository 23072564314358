import styled from 'styled-components'

/* components */
import { Select, SelectProps } from 'components/atoms'

/* type declarations */
export type StyledSelectProps = SelectProps

export const StyledSelect = styled(Select)<StyledSelectProps>`
  height: 100%;

  & > .react-select__control {
    background-color: inherit;
    border: none;
    height: 100%;
  }

  & .react-select__value-container {
    border: none;
    height: 100%;
  }

  & .react-select__menu {
    position: absolute;
    overflow: visible;
    z-index: 5;
  }
`

export default StyledSelect
