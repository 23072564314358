import React, { ChangeEvent } from 'react'

/* components */
import * as Styled from '../StyledElements'
import RowItem from './RowItem'

/* state */
import { types as connTypes } from 'state/connections'
import { types as currTypes } from 'state/currentConnection'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type ConnectionsListProps = StyledComponentProps & {
  connectionRows: Array<ConnectionRow>
  setSelectedConnectionId: (connectionId: currTypes.ConnectionProxyId) => void
}

export type ConnectionRow = currTypes.ConnectionProxy | connTypes.Connection

export default function ConnectionsList({
  connectionRows,
  setSelectedConnectionId,
  ...rest
}: ConnectionsListProps) {
  return (
    <RowItem {...rest}>
      <Styled.Select onChange={handleChange}>
        {connectionRows.map(({ id, name }: ConnectionRow, i: number) => (
          <Styled.Option key={id || `empty-row-${i}`} value={id || ''}>
            {name || ''}
          </Styled.Option>
        ))}
      </Styled.Select>
    </RowItem>
  )

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget

    setSelectedConnectionId(value)
  }
}
