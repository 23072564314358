import React from 'react'
import styled from 'styled-components'

/* components */
import { EthycaLogo } from 'components/molecules'

/* utils */
import { externalUrls } from 'utils/constants'
import { StyledComponentProps } from 'utils/types'

/**
 * UnauthNavbarContainer styled-component
 */
export const UnauthNavbarContainer = styled.nav<StyledComponentProps>`
  grid-area: nav;
  justify-self: center;
  padding-top: 2em;
  z-index: 10;
`

/**
 * UnauthNavbar presentational component
 */
export function UnauthNavbar() {
  return (
    <UnauthNavbarContainer data-testid="unauth_navbar">
      <EthycaLogo href={externalUrls.ethyca} />
    </UnauthNavbarContainer>
  )
}

export default UnauthNavbar
