import styled from 'styled-components'
import { Accordion, AccordionProps } from '@material-ui/core'

export type StyledAccordionProps = AccordionProps

export const StyledAccordion = styled(Accordion)<StyledAccordionProps>`
  && {
    box-shadow: none;

    &:before {
      display: none;
    }
  }
`

export default StyledAccordion
