import styled from 'styled-components'
import { Box, BoxProps } from 'components/atoms'

export type ErrorContainerProps = BoxProps

export const ErrorContainer = styled(Box)<ErrorContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ErrorContainer
