import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type CellButtonProps = StyledComponentProps & {}

/**
 * CellButton styled-component
 */
export const CellButton = styled.button<CellButtonProps>`
  background-color: inherit;
  border: none;
  color: inherit;
  height: 100%;
  text-align: left;
  width: 100%;
`

export default CellButton
