import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type TemplateContainerProps = BoxProps

export const TemplateContainer = styled(Box)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.2em;

  height: 100%;
  width: 100%;
`

export default TemplateContainer
