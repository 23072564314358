import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledGeneralFormProps = StyledComponentProps

/**
 * StyledGeneralForm styled-component
 */
export const StyledGeneralForm = styled.form<StyledGeneralFormProps>`
  display: flex;
  flex-direction: column;

  & .MuiFormControl-root.MuiTextField-root {
    padding-bottom: 2.5em;
  }
`

export default StyledGeneralForm
