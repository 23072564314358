import React from 'react'
import styled from 'styled-components'

/* components */
import { Button, ButtonProps, Image } from 'components/atoms'
import { Checkmark } from 'components/atoms/icons/index'
import { StyledTile, StyledTileProps } from './StyledTile'

/* utils */
import { endpoints } from 'utils/constants'
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type ItemsProps = {
  description?: string
  disabled?: boolean
  id?: string
  image?: string
  name?: string
  selected?: boolean
}

export type TileProps = StyledTileProps &
  ItemsProps & {
    buttonProps?: ButtonProps
  }

/**
 * Tile presentational component
 */
export default function Tile({
  buttonProps,
  description,
  disabled,
  image = endpoints.placeholder,
  name,
  selected,
  ...rest
}: TileProps) {
  const imageElement = selected ? (
    <>
      <Image
        alt={name}
        src={image}
        title={description || name}
        style={{ position: 'relative', left: '0.8em', bottom: '0.4em' }}
      />
      <HandleIconElement>{<Checkmark style={{ fontSize: '1.5rem' }} />}</HandleIconElement>
    </>
  ) : (
    <>
      <Image alt={name} src={image} title={description || name} />
    </>
  )

  return (
    <StyledTile as="li" data-testid="tile" disabled={disabled} {...rest}>
      <Button disabled={disabled} height="100%" name={name} width="100%" {...buttonProps}>
        {imageElement}
      </Button>
    </StyledTile>
  )
}

/**
 * PositionElement styled-component
 */
export const HandleIconElement = styled.div<StyledComponentProps>`
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  top: -4.4em;
  left: -1.1em;
`
