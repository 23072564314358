import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

/* components */
import * as Styled from '../StyledElements'
import { CloseIcon } from 'components/atoms/icons/index'

/* state */
import { types as piiTypes } from 'state/piiMapping'
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { MASKING_STRATEGIES_WITH_EXTRA_INPUT_VALUE, PREVENT_MASKING_STRATEGY } from 'utils/constants'
import { ReactSelectMissingEventType, ReactSelectOption, StyledComponentProps } from 'utils/types'

/* type declarations */
export type MaskingStrategyProps = StyledComponentProps & {
  columnName: schemaTypes.PropertyColumn
  currentSelection?: string
  dataAttribute?: schemaTypes.RelationshipMap
  defaultValue?: schemaTypes.PropertyDefaultValue
  items: piiTypes.MaskingStrategyOptions
  handleChange: (event: ChangeEvent<HTMLSelectElement> | ReactSelectMissingEventType) => void
}

const CompoundFieldCell = styled(Styled.TableCell)`
  display: flex;
  flex-direction: column;
  height: auto;

  & > * {
    flex: 1;
  }

  & .react-select__control {
    height: auto;
  }

  & .custom-select-field {
    height: auto;
    margin-bottom: 5px;
  }
`
const InputWithIconContainerForCompoundCell = styled.div`
  & > svg {
    cursor: pointer;
    position: absolute;
    min-width: 40px;
    top: 67%;
    right: 0%;
  }
`

export default function MaskingStrategy({
  columnName,
  currentSelection,
  dataAttribute,
  defaultValue,
  handleChange,
  items,
  ...rest
}: MaskingStrategyProps) {
  /* state hooks */
  const [inputValue, setInputValue] = useState('')
  const [showInput, setShowInput] = useState(false)

  const DEFAULT_DROPDOWN_VALUE = {
    label: 'Select Masking',
    value: undefined
  }

  // when there is no value supplied for default_value masking strategy, this is called "remove" on front-end
  const overrideShowRemoveInstead = currentSelection === schemaTypes.MASKING_STRATEGIES.default_value && defaultValue === undefined

  /* effect hooks */
  useEffect(() => {
    if (
      MASKING_STRATEGIES_WITH_EXTRA_INPUT_VALUE.includes(
        currentSelection as schemaTypes.MASKING_STRATEGIES
      ) &&
      !overrideShowRemoveInstead
    ) {
      setShowInput(true)
    } else {
      setShowInput(false)
    }
  }, [currentSelection, overrideShowRemoveInstead])

  useEffect(() => {
    if (defaultValue && showInput) {
      setInputValue(defaultValue)
    }
  }, [defaultValue, setInputValue, showInput])

  /* constants */
  const options = convertToDisplay(items)
  const currentValue = getCurrentValue(items, currentSelection, defaultValue)

  const allowMasking = !PREVENT_MASKING_STRATEGY.includes(dataAttribute as schemaTypes.RELATIONSHIP_DATA_ATTRIBUTES)

  const simpleKeyForResettingState = `${!!currentValue?.value}`

  /* render */
  if (showInput && options?.length > 0 && allowMasking) {
    return (
      <CompoundFieldCell {...rest}>
        <Styled.Select
          className="custom-select-field"
          key={simpleKeyForResettingState}
          name={columnName}
          onChange={onChange}
          options={options}
          placeholder="Select Masking Strategy"
          value={currentValue || undefined}
        />
        <InputWithIconContainerForCompoundCell>
          <Styled.Input
            name={columnName}
            onChange={handleInput}
            placeholder="Enter a Default Value"
            value={inputValue}
          />
          <CloseIcon onClick={handleCloseIcon} />
        </InputWithIconContainerForCompoundCell>
      </CompoundFieldCell>
    )
  }

  return (
    <Styled.TableCell {...rest}>
      {options?.length > 0 && allowMasking && (
        <Styled.Select
          key={simpleKeyForResettingState}
          name={columnName}
          onChange={onChange}
          options={options}
          placeholder="Select Masking"
          value={currentValue || undefined}
        />
      )}
    </Styled.TableCell>
  )

  function handleCloseIcon() {
    setShowInput(false)
    const removeItem = items.find(({ value }) => value === schemaTypes.MASKING_STRATEGIES.remove)
    onChange({
      label: removeItem?.displayValue || 'Default Value',
      value: removeItem?.value || schemaTypes.MASKING_STRATEGIES.remove
    })
  }

  function convertToDisplay(items: piiTypes.MaskingStrategyOptions): Array<ReactSelectOption> {
    return [
      // @ts-ignore
      DEFAULT_DROPDOWN_VALUE,
      ...items?.map(({ displayValue, value }) => ({ label: displayValue, value }))
    ]
  }

  function getCurrentValue(
    items: piiTypes.MaskingStrategyOptions = [],
    currentSelection?: string,
    defaultValue?: schemaTypes.PropertyDefaultValue
  ): ReactSelectOption | null {
    // OPTION 1: Handle the default_value case
    if (overrideShowRemoveInstead) {
      return {
        label: 'Default Value',
        value: schemaTypes.MASKING_STRATEGIES.remove
      }
    }

    // OPTION 2: If there is any other current selection, either display the masking strategy or show
    //  empty fields (if it doesn't exist in the masking strategies list)
    if (currentSelection) {
      const { displayValue, value } = items?.find(({ value }) => currentSelection === value) || {}

      return {
        label: displayValue || '',
        value: value || ''
      }
    }

    // OPTION 3: Show nothing if there's no value currently selected
    return null
  }

  function handleInput(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget
    setInputValue(value)
    handleChange(event)
  }

  function onChange({ value }: ReactSelectOption) {
    /* This is as per business requirements */
    if (
      value === schemaTypes.MASKING_STRATEGIES.default_value &&
      currentSelection !== schemaTypes.MASKING_STRATEGIES.default_value
    ) {
      setShowInput(true)
      setInputValue('')

      const event: ReactSelectMissingEventType = {
        currentTarget: {
          name: columnName,
          value: ''
        }
      }

      handleChange(event)
    } else {
      const event: ReactSelectMissingEventType = {
        currentTarget: {
          name: columnName,
          value
        }
      }

      handleChange(event)
    }
  }
}
