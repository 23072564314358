import { createSelector } from 'reselect'

/* local ducks */
import { types as piiTypes } from 'state/piiMapping'

/* foreign ducks */
import { RootState } from 'state/store'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'

/* utils */
import { stateNames } from 'utils/constants'

const { piiMapping } = stateNames

const selectSchemaMetaMap = (state: RootState) => state[piiMapping].schemas

export const selectSchemaMeta = createSelector(
  currSelectors.selectCurrentConnection,
  selectSchemaMetaMap,
  (
    connection: currTypes.ConnectionProxy,
    schemaMap: Record<currTypes.ConnectionProxyId, piiTypes.SchemaMeta>
  ) => schemaMap?.[connection?.id || '']
)

export const selectDataAttributes = (state: RootState) => state[piiMapping]?.dataAttributes
export const selectDataClassOptions = (state: RootState) => state[piiMapping]?.dataClasses
export const selectDownloadedYaml = (state: RootState) => state[piiMapping]?.downloadedYaml
export const selectMaskingStrategyOptions = (state: RootState) => state[piiMapping]?.maskingStrategies
export const selectOpenYamlValidationModal = (state: RootState) => state[piiMapping]?.openYamlValidationModal
export const selectPreviousEntityName = (state: RootState) => state[piiMapping]?.previousSelectedEntity
export const selectQueryPreview = (state: RootState) => state[piiMapping]?.queryPreview
export const selectSchemaHasChanges = (state: RootState) => state[piiMapping].schemaChanges.length > 0
export const selectUserSelectedEntityName = (state: RootState) => state[piiMapping]?.selectedEntity
export const selectYamlValidationErrors = (state: RootState) => state[piiMapping]?.yamlValidationErrors
export const selectYamlFileName = (state: RootState) => state[piiMapping]?.yamlFileName

export const selectSchemaIsDone = createSelector(
  selectSchemaMeta,
  (schemaMeta: piiTypes.SchemaMeta): piiTypes.SchemaIsDone => !!schemaMeta?.done
)

export const selectSchemaValidations = (state: RootState) => state[piiMapping]?.schemaValidations

const selectors = {
  selectDataAttributes,
  selectDataClassOptions,
  selectDownloadedYaml,
  selectMaskingStrategyOptions,
  selectOpenYamlValidationModal,
  selectPreviousEntityName,
  selectQueryPreview,
  selectSchemaHasChanges,
  selectSchemaMeta,
  selectSchemaIsDone,
  selectSchemaValidations,
  selectUserSelectedEntityName,
  selectYamlFileName,
  selectYamlValidationErrors
}

export default selectors
