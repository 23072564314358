/* local ducks */
import { types as currTypes } from 'state/currentConnection'

// initial state
export const initialState: currTypes.State = {
  configurations: undefined,
  connectionStatus: undefined,
  connectionType: undefined,
  id: undefined,
  lastModified: undefined,
  dataMappingStatus: undefined,
  name: undefined
}

export default initialState
