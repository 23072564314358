import React from 'react'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTextProps = StyledComponentProps

export type SubtitleProps = StyledTextProps & { message?: string }

export const StyledText = styled.p<StyledTextProps>`
  color: ${({ theme }) => theme.colors.black};
  white-space: normal;
`

export function Subtitle({ children, message, ...rest }: SubtitleProps) {
  return <StyledText {...rest}>{message || children}</StyledText>
}

export default Subtitle
