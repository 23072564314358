import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'

/* components */
import { SubmitButtons, Title } from 'components/molecules'
import { StyledPrimaryButton } from 'components/molecules/SubmitButtons/StyledPrimaryButton'
import { StyledSecondaryButton } from "../../molecules/SubmitButtons/StyledSecondaryButton";
import { Box, Row, Text } from 'components/atoms'
import { DownloadFileIcon, CloudUploadIcon } from 'components/atoms/icons/index'
import { Column } from './Column'
import { StyledDialog, StyledDialogProps } from './StyledDialog'

/* state */
import { useDispatch } from 'state/store'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'
import { actions as piiActions, selectors as piiSelectors, types as piiTypes } from 'state/piiMapping'
import { selectors as dbSelectors } from 'state/databases'

/* utils */
import colors from 'App/theme/colors'
import { routes } from 'utils/constants'
import { composer } from 'utils/functions'

/* type declarations */
export type ValidationModalProps = StyledDialogProps & {
  unformattedMessages: piiTypes.UnknownValidationMessagesFormat
}

/* Modal */
export default function ValidationModal({ unformattedMessages, open, ...rest }: ValidationModalProps) {
  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* state hooks */
  const [file, setFile] = useState<any>(null)
  const [isFetchingUpload, setIsFetchingUpload] = useState(false)
  const [savedMessages, setSavedMessages] = useState('')
  const [allowNewMessage, setAllowNewMessage] = useState(true)

  /* selector hooks */
  const currentConnection: currTypes.ConnectionProxy =
    useSelector(currSelectors.selectCurrentConnection) || {}
  const previousFileName: piiTypes.YamlFileName = useSelector(piiSelectors.selectYamlFileName)
  const currentDatabaseType = useSelector(dbSelectors.selectCurrentDatabaseType)

  /* effect hooks */
  useEffect(() => {
    if (allowNewMessage && unformattedMessages) {
      setSavedMessages(formatMessages(unformattedMessages))
    }
  }, [allowNewMessage, setSavedMessages, unformattedMessages])

  /* constants */
  const { id: connectionId = '' } = currentConnection
  const disableMapping = !!currentDatabaseType?.disableMapping

  const submitButtonProps = {
    handlePrimarySubmit: saveFile,
    handleSecondarySubmit: skipUpload,
    primaryButtonText: 'SAVE AND VALIDATE',
    secondaryButtonText: 'SKIP YAML UPLOAD',
    primaryIsLoading: isFetchingUpload,
    primaryProps: {
      disabled: !file,
      style: {
        marginLeft: 20
      }
    },
    secondaryProps: {
      disabled: disableMapping
    },
    style: {
      justifyContent: 'flex-end'
    }
  }

  const currentFileName = previousFileName || file?.name

  /* render */
  return (
    <StyledDialog open={open} {...rest}>
      {/* Titles */}
      <Row border={`1px solid ${colors.grayLight2}`}>
        <Column padding="13px 4px 13px 25px">
          <Row justifyContent="space-between" alignItems="center">
            <Title title="Structured YAML" />
            <DownloadFileIcon
              onClick={downloadFile}
              style={{ color: colors.grayDarker1, cursor: 'pointer', fontSize: 18 }}
            />
          </Row>
        </Column>
        <Column padding="13px 25px">
          <Row>
            <Title title="Errors" />
          </Row>
        </Column>
      </Row>

      {/* Body */}
      <Row>
        <Column alignItems="center" padding="20px">
          <Text fontSize="17px" height="30px" paddingBottom="10px">
            {currentFileName && (
              <>
                YAML filename : <span style={{ fontWeight: 'bold' }}>{currentFileName}</span>
              </>
            )}
          </Text>
          <Text color={colors.grayDarker1}>You may choose to upload another YAML file at any time.</Text>
          <Text color={colors.grayDarker1}>This will overwrite the existing uploaded YAML file.</Text>
          <Box padding="45px 0 23px">
            <CloudUploadIcon />
          </Box>
          <Text fontSize="17px" paddingBottom="20px">
            Upload a new YAML file
          </Text>
          <Column>
            {/* Note: Keys here are required here as a way to avoid a certain nasty bug. Atlas's design requires that there be file upload inputs
            for different parts of the app that each have different behavior. Without these keys, React has the potential to think that these inputs are actually
            the same html--thereby triggering the wrong behavior for the mistaken elements without there being an obvious cause for the effect.  */}
            <input
              accept=".yaml"
              id="validation-modal__input"
              key="validation-modal__input"
              style={{ display: 'none' }}
              onChange={handleFile}
              type="file"
            />
            <label htmlFor="validation-modal__input" key="validation-modal__label">
              <StyledPrimaryButton component="span" key="validation-modal__button" style={{ width: 225 }}>
                Upload
              </StyledPrimaryButton>
            </label>
            <StyledSecondaryButton color="primary" variant="outlined" onClick={cancelYamlUpload} component="span"
              style={{ width: 225, marginTop: '20px' }}>
              Cancel
            </StyledSecondaryButton>
          </Column>
        </Column>
        <Column
          backgroundColor={colors.grayLight2}
          color={colors.red}
          height="586px"
          overflow="hidden"
          padding="20px">
          <Text>{savedMessages}</Text>
        </Column>
      </Row>

      {/* Submit Buttons */}
      <Row>
        <Column padding="20px 25px">
          <SubmitButtons {...submitButtonProps} />
        </Column>
      </Row>
    </StyledDialog>
  )

  function downloadFile() {
    try {
      dispatch(piiActions.fetchDownloadStructure({ connectionId }))
    } catch (_) {}
  }

  function cancelYamlUpload() {
    dispatch(piiActions.triggerYamlValidationModal(false))
  }

  function formatMessages(unformattedMessages: piiTypes.UnknownValidationMessagesFormat) {
    const LINE_BREAK = '\n'
    const INITIAL_STRING = ''

    const formattedMessage = composer(unformattedMessages)
      .map(formatIfEmpty)
      .map(formatIfObject)
      .map(formatIfArray)
      .resolve(formatAllOthers)

    return formattedMessage

    // --------------------
    function formatIfArray(messages: piiTypes.UnknownValidationMessagesFormat) {
      if (Array.isArray(messages)) {
        return messages.join(LINE_BREAK)
      }

      return messages
    }

    function formatIfEmpty(messages: piiTypes.UnknownValidationMessagesFormat) {
      if (!messages) {
        return ''
      }

      return messages
    }

    function formatIfObject(messages: piiTypes.UnknownValidationMessagesFormat) {
      if (typeof messages === 'object') {
        return Object.keys(messages).reduce((entitiesWithMessages: string, entityName: string, i: number) => {
          const columnErrors = messages[entityName]?.columnErrors || {}
          const mergedFieldMessages = Object.keys(columnErrors).reduce(
            (fieldsWithMessages: string, field: string, j: number) => {
              const messages =
                columnErrors[field]?.reduce((innerMessages: string, message: string, k: number) => {
                  const errorNumber = 1 + i + j + k
                  return `(${errorNumber}) ${innerMessages}${entityName} [${field}]: ${message}${LINE_BREAK}`
                }, INITIAL_STRING) || ''

              return `${fieldsWithMessages}${messages}`
            },
            INITIAL_STRING
          )

          return `${entitiesWithMessages}${mergedFieldMessages}`
        }, INITIAL_STRING)
      }

      return messages
    }

    function formatAllOthers(messages: piiTypes.UnknownValidationMessagesFormat) {
      if (typeof messages === 'string') {
        return messages
      }

      console.warn('Unexpected message format for errors: ', typeof messages, messages)

      return ''
    }
  }

  function handleFile(event: any) {
    const uploadedFile: File | undefined = event.target?.files[0]

    if (uploadedFile) {
      setFile(uploadedFile)
      setAllowNewMessage(false)
      setSavedMessages('')

      dispatch(piiActions.updateYamlFileName(uploadedFile.name))
    }
  }

  async function saveFile() {
    if (file) {
      setIsFetchingUpload(true)
      const formData = new FormData()
      formData.append('structure-file', file)
      try {
        const action = await dispatch(piiActions.fetchUploadStructure({ connectionId, formData }))
        await unwrapResult(action)

        setFile(null)
        setIsFetchingUpload(false)
        setSavedMessages('')
        setAllowNewMessage(true)

        history.push(`${routes.piiMapping}/${connectionId}`)
      } catch (_) {
        setIsFetchingUpload(false)
        setSavedMessages('')
        setAllowNewMessage(true)
      }
    }
  }

  function skipUpload() {
    setFile(null)
    setSavedMessages('')
    setAllowNewMessage(true)

    dispatch(piiActions.triggerYamlValidationModal(false))
    history.push(`${routes.piiMapping}/${connectionId}`)
  }
}
