import { RequestOptions, WebToken } from 'utils/types'

export interface ConfigureInitParams extends RequestOptions {
  body?: string | FormData
  ethycaEnvironment?: string
  dontSetJsonHeaderForBody?: boolean
  jwt?: WebToken
  method?: string
  requiresAuth?: boolean
}

/**
 *
 */
export default function configureInit({
  body,
  ethycaEnvironment = process.env.REACT_APP_ETHYCA_ENVIRONMENT || 'java_local',
  dontSetJsonHeaderForBody = false,
  jwt = process.env.REACT_APP_ETHYCA_LOCAL_TOKEN || '',
  method = 'GET',
  requiresAuth = false,
  signal
}: ConfigureInitParams): RequestInit {
  let headers: HeadersInit = {}

  if (body && !dontSetJsonHeaderForBody) {
    headers = { ...headers, 'Content-Type': 'application/json' }
  } else {
    // don't set
  }

  if (requiresAuth) {
    if (ethycaEnvironment === 'dev2') {
      headers = { ...headers, temporary: jwt }
    } else {
      // don't set
    }

    return {
      body,
      credentials: 'include',
      headers,
      method,
      signal
    }
  }

  return {
    body,
    headers,
    method,
    signal
  }
}
