import styled from 'styled-components'

/* state */
import { types as connTypes } from 'state/connections'

/* utils */
import { DATA_STATUSES_BUTTON_MAP } from 'utils/constants'
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type IconsContainerProps = StyledComponentProps &
  StyledComponentProps & {
    status: connTypes.DataMappingStatus
  }

// const label = DATA_STATUSES_BUTTON_MAP[status || '']
// const hasBeenMapped = !label

/**
 * CenterAlignCell
 */
export const IconsContainer = styled.td<IconsContainerProps>`
  text-align: right;
  padding-right: 1em;

  && {
    & > * {
      margin: 0 0.4em;
    }

    & button {
      color: ${({ status, theme }) =>
        !DATA_STATUSES_BUTTON_MAP[status || ''] ? theme.colors.gray : 'inherit'};
    }
  }
`

export default IconsContainer
