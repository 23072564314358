import styled from 'styled-components'

/* components */
import { CustomSelect, CustomSelectProps } from 'components/atoms'

/* type declarations */
export type SelectProps = CustomSelectProps

export const Select = styled(CustomSelect)<CustomSelectProps>`
  background-color: #ebebeb;
  color: ${({ theme }) => theme.colors.black};

  border: none;
  border-radius: 4px;
  height: 50px;
  width: 264px;

  padding: 16px 16px 16px 22px;
`

export default Select
