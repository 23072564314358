import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

export type StyledContainerProps = BoxProps

export const StyledContainer = styled(Box)<StyledContainerProps>`
  color: ${({ theme }) => theme.colors.black};
  height: 100%;
  width: 450px;
`

export default StyledContainer
