import React, { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { EthycaLogo } from 'components/molecules'
import { Image, Row, Text } from 'components/atoms'
import { PageContainer, PageContainerProps } from './PageContainer'
import { StyledButtons } from './StyledButtons'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'

/* assets */
import bg from 'assets/img/bg_404.svg'
import helmetImg from 'assets/img/helmet.svg'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type Page404Props = RouteComponentProps<{}> &
  PageContainerProps & {
    img?: any
  }

/**
 * Page404 redux- & router-aware component
 */
export default function Page404({ location: { pathname } }: Page404Props) {
  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ currentPath: pathname, isAuthPath: false })), [
    dispatch,
    pathname
  ])

  /* constants */
  const buttonProps = {
    handleSecondarySubmit,
    secondaryButtonText: `Return to Ethyca`
  }

  /* render */
  return (
    <PageContainer paddingTop={150} bg={bg}>
      <EthycaLogo to={routes.base} />
      <Text as="h1" fontSize="13rem" padding="0.15em 0">
        4<Image display="inline-block" padding="0 0.05em" src={helmetImg} />4
      </Text>
      <Text fontSize="2.4rem">Whoops! This page is lost.</Text>
      <Text>Sorry, space is a big place and we can't find the page you were looking for.</Text>
      <Row padding="1.6em">
        <StyledButtons {...buttonProps} />
      </Row>
    </PageContainer>
  )

  function handleSecondarySubmit() {
    history.push(routes.base)
  }
}
