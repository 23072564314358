import React from 'react'

/* components */
import { SubmitButtons, SubmitButtonsProps } from 'components/molecules'
import { DialogContainer } from './DialogContainer'

/* type declarations */
export type UnsavedChangesFormProps = {
  buttonProps?: SubmitButtonsProps
  handleClose: () => void
  open: boolean
  primaryActions: () => void
}

export default function UnsavedChangesForm({
  buttonProps = {},
  handleClose,
  open,
  primaryActions,
  ...rest
}: UnsavedChangesFormProps) {
  /* constants */
  const dialogProps = {
    handleClose,
    name: `Warning Unsaved Changes`,
    message: `You made changes to this database mapping.`,
    instruction: `Do you wish to save your changes before switching to another database?`,
    open,
    overrideButtons: renderOverrideButtons(),
    ...rest
  }

  /* render */
  return <DialogContainer data-testid="schema_validations_form" {...dialogProps}></DialogContainer>

  /* render callbacks */
  function renderOverrideButtons() {
    const additionalButtonProps = {
      handlePrimarySubmit,
      primaryButtonText: `Save`,
      handleSecondarySubmit,
      secondaryButtonText: `Don't Save`,
      ...buttonProps
    }

    return <SubmitButtons {...additionalButtonProps} />
  }

  /* callbacks */
  function handlePrimarySubmit() {
    primaryActions()
  }

  function handleSecondarySubmit() {
    handleClose()
  }
}
