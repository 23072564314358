import React, { ReactNode } from 'react'

/* components */
import { Box, BoxProps, Dialog, DialogProps, Row, Text, TextProps } from 'components/atoms'
import { CloseButton, CloseButtonProps } from 'components/molecules'

import { StyledPrimaryButton } from './StyledPrimaryButton'
import { StyledTextBody } from './StyledTextBody'

/* type declarations */
export type GeneralDialogProps = DialogProps & {
  bodyProps?: BoxProps
  children?: ReactNode
  closeButtonProps?: CloseButtonProps
  disabled?: boolean
  handleClose: () => void
  instruction?: string
  message: string
  name: string
  overrideButtons?: ReactNode
  renderBody?: ReactNode
  textButtonProps?: TextProps
}

/**
 * GeneralDialog presentational component
 */
export default function GeneralDialog({
  bodyProps,
  children,
  closeButtonProps,
  disabled = false,
  handleClose,
  instruction,
  message,
  name,
  overrideButtons,
  renderBody,
  textButtonProps,
  ...rest
}: GeneralDialogProps) {
  const dialogProps = {
    'aria-labelledby': name,
    'aria-describedby': message,
    'data-testid': 'general_dialog',
    ...rest
  }

  return (
    <Dialog {...dialogProps}>
      <CloseButton handleClose={handleClose} {...closeButtonProps} />
      <StyledTextBody>
        {name && (
          <Text
            as="h2"
            fontSize="0.8125rem"
            fontWeight="600"
            letterSpacing="0.05rem"
            style={{ textTransform: 'uppercase' }}
            {...textButtonProps}>
            {name}
          </Text>
        )}
        {message && (
          <Text fontSize="1.3125rem" width="100%">
            {message}
          </Text>
        )}
        {instruction && <Text width="100%">{instruction}</Text>}
        {(renderBody || children) && <Box {...bodyProps}>{renderBody || children}</Box>}
      </StyledTextBody>
      {overrideButtons || (
        <Row>
          <StyledPrimaryButton disabled={disabled} onClick={handleClose}>
            OK
          </StyledPrimaryButton>
        </Row>
      )}
    </Dialog>
  )
}
