/* local ducks */
import { types as schemaTypes } from 'state/schemas'

/* foreign ducks */
import { types as piiTypes } from 'state/piiMapping'
import { types as alertTypes } from 'state/alerts'

/* utils */
import { mergeColumnErrorMessages, mergeMessages } from 'utils/arrays'
import { MESSAGES, SCHEMA_DIFFERENCES } from 'utils/constants'
import { composer } from 'utils/functions'
import { allPropertiesAreUndefined } from 'utils/objects'

export function checkIfAllUnmapped(
  entityProperties: schemaTypes.EntityPropertiesForUI
): schemaTypes.HasNoMappings {
  // Dev note: I'm not sure why Typescript doesn't like the following code.  It's pretty standard JS
  //  to use .reduce to compose an array into a single boolean check or something similar.
  // @ts-ignore
  return entityProperties.reduce(
    // @ts-ignore
    (bool, { hasNoMapping }) => {
      // Note: If there's a property that has a mapping, then just ignore everything else;
      //  otherwise, keep looking and continue to confirm or not if everything has been
      //  given the `hasNoMapping` property.
      return !bool ? false : !!hasNoMapping
    },
    true
  )
}

export function convertSchemaErrorsToAlerts(apiErrors: piiTypes.SchemaApiErrors): alertTypes.Alert {
  const entityNames = Object.keys(apiErrors)

  const finalString = entityNames.reduce((acc, key) => singleLine(acc, key), '')
  return {
    ...MESSAGES.schemaErrors,
    message: finalString,
    severity: 'error'
  }

  function singleLine(acc: string, key: string) {
    const LIST_ITEM = '•'
    const LINE_BREAK = '\n'

    return `${acc}${LIST_ITEM} ${convertValuesToStrings(key)}${LINE_BREAK}`
  }

  function convertValuesToStrings(key: string): string {
    const { columnErrors, errors } = apiErrors[key]

    const base = `${key} -`

    if (errors) {
      return `${base} ${mergeMessages(errors)}.`
    } else if (columnErrors) {
      return `${base} ${mergeColumnErrorMessages(columnErrors)}.`
    }

    return `"${base}": An error has occurred.`
  }
}

export function determineIfUnmapping(
  entityProperty: schemaTypes.EntityPropertyForUI
): schemaTypes.HasNoMappings {
  return composer(entityProperty).map(filterIrrevelantValues).resolve(allPropertiesAreUndefined)

  function filterIrrevelantValues({
    datatype,
    difference,
    hasNoMapping,
    name,
    ...rest
  }: schemaTypes.EntityPropertyForUI): object {
    return rest
  }
}

export function handleUnmappings({ entities, ...rest }: schemaTypes.SchemaForUI): schemaTypes.SchemaForUI {
  return {
    entities: composer(entities).resolve(tagUnmappingEntities),
    ...rest
  }

  function tagUnmappingEntities(entities: schemaTypes.EntitiesForUI): schemaTypes.EntitiesForUI {
    return entities.map(({ differences, hasNoMappings, properties, ...rest }) => ({
      differences: hasNoMappings ? [SCHEMA_DIFFERENCES.NEW] : differences || undefined,
      properties: tagUnmappingProperties(properties || []),
      ...rest
    }))
  }

  function tagUnmappingProperties(
    entityProperties: schemaTypes.EntityPropertiesForUI
  ): schemaTypes.EntityPropertiesForUI {
    return entityProperties.map(({ difference, hasNoMapping, ...rest }: schemaTypes.EntityPropertyForUI) => ({
      difference: hasNoMapping ? SCHEMA_DIFFERENCES.NEW : difference || undefined,
      ...rest
    }))
  }
}

// export function removeFullyUnmappedEntities(entities: schemaTypes.EntitiesForUI): schemaTypes.EntitiesForUI {
//   return entities?.map(({ properties, ...rest }) => ({
//     hasNoMappings: checkIfAllUnmapped(properties || []),
//     properties,
//     ...rest
//   }))
// }

export function whitelistValuesForSchema({ entities }: schemaTypes.SchemaForUI): schemaTypes.FinalSchema {
  // Javascript Note: The following operations may leave a large number of "undefined" property values;
  //  however, these undefined properties will be inherently stripped out from the fetch request and, therefore,
  //  can be safely ignored.
  return {
    entities: handleWhitelistingEntities(entities)
  }

  function handleWhitelistingEntities(entities: schemaTypes.EntitiesForUI): schemaTypes.FinalEntities {
    return entities.map(({ category, differences, hasPII, label, name, properties }) => ({
      category,
      differences,
      hasPII,
      label,
      name,
      properties: properties ? handleWhitelistingProperties(properties) : undefined
    }))
  }

  function handleWhitelistingProperties(
    entityProperties: schemaTypes.EntityPropertiesForUI
  ): schemaTypes.FinalEntityProperties {
    return entityProperties.map(
      ({
        name,
        dataAttribute,
        dataClass,
        datatype,
        defaultValue,
        difference,
        label,
        maskingStrategy,
        reference
      }) => ({
        name,
        dataAttribute,
        dataClass,
        datatype,
        defaultValue,
        difference,
        label,
        maskingStrategy,
        reference
      })
    )
  }
}

const utils = {
  checkIfAllUnmapped,
  convertSchemaErrorsToAlerts,
  determineIfUnmapping,
  handleUnmappings,
  whitelistValuesForSchema
}

export default utils
