import { call, delay, put, select, takeEvery } from 'redux-saga/effects'

/* local ducks */
import requests from './_requests'
import { types as piiTypes } from 'state/piiMapping'
import { actions as schemaActions, types as schemaTypes } from 'state/schemas'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'
import { getConnection } from 'state/connections/_requests'
import { types as connTypes } from 'state/connections'
import { UNSUPPORTED_DATABASES_SCHEMA_RETRIEVAL } from 'utils/constants'
import { HttpResponse } from '../../utils/types'

/* generator actions */
/**
 * schemaFlow generator function - redux-sagas
 *
 * Redux Sagas is being utilized here due to the fact that the backend API utilizes a long-polling flow for
 *  retrieving schema information.  The pattern here may differ significantly from Redux Thunk, which is what is
 *  being used by the rest of the application (under the hood of @redux/toolkit).  Redux Thunk is just a small
 *  library for "consuming" promises within Redux's action chain; therefore, a more advanced pattern is necessary
 *  to handle long-polling requests.
 */
export function* schemaFlow({ connectionId }: schemaTypes.InitialSchemaRequestParams) {
  const { currentConnection } = yield select()

  const id = currentConnection?.id || connectionId

  yield put(schemaActions.retrieveSchemaStart(id))
  let connectionType
  try {
    const res: Response = yield call(() => getConnection({ connectionId: id }, {}))
    switch (res.status) {
      case 200: {
        const connection: connTypes.Connection = yield res.json()
        connectionType = connection?.connectionType

        break
      }
      default: {
        yield put(
          alertActions.createAlert({
            title: `An error occurred while trying to retrieve the schema (Status: ${res.status})`,
            message: `Fetch error for Connection ID: ${id}`,
            severity: 'error'
          })
        )
        throw new Error(`Fetch error (Status: ${res.status}): ${res.url}`)
      }
    }
  } catch (err) {
    console.error(err)
    yield put(
      alertActions.createAlert({
        title: `An error occurred while trying to retrieve the schema`,
        message: `Error for Connection ID: ${id}: ${err?.message || 'Unknown'}`,
        severity: 'error'
      })
    )
    return yield put(schemaActions.retrieveSchemaFailure({ connectionId: id, message: err?.message }))
  }

  // Start schema process
  try {
    if (UNSUPPORTED_DATABASES_SCHEMA_RETRIEVAL.includes(connectionType)) {
      return yield call(() => getSchema({ connectionId: id }))
    }
    const res: HttpResponse<void> = yield call(() => requests.startSchemaRetrieval({ connectionId: id }, {}))

    switch (res.status) {
      case 200:
      case 202: {
        break
      }

      default: {
        yield put(
          alertActions.createAlert({
            title: `An error occurred while trying to retrieve the schema (Status: ${res.status})`,
            message: `Fetch error for Connection ID: ${id}`,
            severity: 'error'
          })
        )
        throw new Error(`Fetch error (Status: ${res.status}): ${res.url}`)
      }
    }
  } catch (err) {
    console.error(err)
    yield put(
      alertActions.createAlert({
        title: `An error occurred while trying to retrieve the schema`,
        message: `Error for Connection ID: ${id}: ${err?.message || 'Unknown'}`,
        severity: 'error'
      })
    )
    return yield put(schemaActions.retrieveSchemaFailure({ connectionId: id, message: err?.message }))
  }

  // Get schema updates
  while (true) {
    try {
      const res: HttpResponse<piiTypes.SchemaProgressApiResponse> = yield call(() =>
        requests.getSchemaProgress({ connectionId: id }, {})
      )

      switch (res.status) {
        case 200:
        case 202: {
          let json: piiTypes.SchemaProgressApiResponse
          // in case where schema progress might not be added yet to atlas_connection_progress_report, client will try again
          try {
            if (!res.body) {
              continue
            }
            json = yield res.json()
          } catch {
            continue
          }
          yield put(schemaActions.retrieveSchemaUpdate({ connectionId, meta: json }))

          // When the schema is available, a separate request is required to achieve it in full
          if (json.done) {
            const res: HttpResponse<schemaTypes.SchemaForUI> = yield call(() =>
              requests.getSchema({ connectionId: id }, {})
            )

            switch (res.status) {
              case 200:
              case 202: {
                const { entities }: schemaTypes.SchemaForUI = yield res.json()

                return yield put(
                  schemaActions.retrieveSchemaComplete({ connectionId: id, entities: entities || [] })
                )
              }

              default: {
                const { message } = yield res.json()

                yield put(
                  alertActions.createAlert({
                    title: `Fetch error (Status: ${res.status})`,
                    message: message,
                    severity: 'error'
                  })
                )

                return yield put(schemaActions.retrieveSchemaFailure({ connectionId: id, message }))
              }
            }

            // Iterate to the next update
          } else {
            yield delay(750)

            break
          }
        }

        default: {
          yield put(
            alertActions.createAlert({
              title: `An error occurred while trying to retrieve the schema (Status: ${res.status})`,
              message: `Fetch error for Connection ID: ${id}`,
              severity: 'error'
            })
          )

          return yield put(schemaActions.retrieveSchemaFailure({ connectionId: id, statusCode: res.status }))
        }
      }
    } catch (err) {
      console.error(err)
      yield put(
        alertActions.createAlert({
          title: `An error occurred while trying to retrieve the schema`,
          message: `Error for Connection ID: ${id}: ${err?.message || 'Unknown'}`,
          severity: 'error'
        })
      )

      return yield put(schemaActions.retrieveSchemaFailure({ connectionId: id, message: err?.message }))
    }
  }
}

function* getSchema({ connectionId }: schemaTypes.InitialSchemaRequestParams) {
  try {
    const res: HttpResponse<schemaTypes.SchemaForUI> = yield call(() =>
      requests.getSchema({ connectionId }, {})
    )

    switch (res.status) {
      case 200:
      case 202: {
        const { entities }: schemaTypes.SchemaForUI = yield res.json()

        return yield put(schemaActions.retrieveSchemaComplete({ connectionId, entities: entities || [] }))
      }

      default: {
        yield put(
          alertActions.createAlert({
            title: `An error occurred while trying to retrieve the schema (Status: ${res.status})`,
            message: `Fetch error for Connection ID: ${connectionId}`,
            severity: 'error'
          })
        )

        return yield put(schemaActions.retrieveSchemaFailure({ connectionId, statusCode: res.status }))
      }
    }
  } catch (err) {
    yield put(
      alertActions.createAlert({
        title: `Fetch error`,
        message: `An error occurred while trying to retrieve the schema for connectionId ${connectionId}`,
        severity: 'error'
      })
    )
  }
}

export function* watchPiiData({ connectionId }: schemaTypes.InitialSchemaRequestParams) {
  yield takeEvery(schemaActions.TRIGGER_SCHEMA_FLOW, schemaFlow, { connectionId })
}

/* export */
const sagas = [watchPiiData, schemaFlow]

export default sagas
