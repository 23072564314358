import React, { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

/* components */
import { DeleteConnectionForm, QueryPreviewDrawer } from 'components/organisms'
import { TableHeading } from './TableHeading'
import * as Render from './RenderElements'
import * as Styled from './StyledElements'

/* state */
import { useDispatch } from 'state/store'
import { types as connTypes } from 'state/connections'
import { actions as piiActions } from 'state/piiMapping'
import { actions as currActions, types as currTypes } from 'state/currentConnection'

/* utils */
import colors from 'App/theme/colors'
import { dataStatuses, routesFromConnections } from 'utils/constants'
import { EventClick } from 'utils/types'

/* type declarations */
export type ConnectionsTableProps = {
  connections: connTypes.Connections
  handleRouteChange: (params: connTypes.ConnectionRouteState) => void
}

/**
 * ConnectionsTable redux-aware component
 */
export default function ConnectionsTable({ connections, handleRouteChange }: ConnectionsTableProps) {
  /* library hooks */
  const dispatch = useDispatch()

  /* state hooks */
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showQueryDrawer, setShowQueryDrawer] = useState(false)

  /* constants */
  const CREATE_NEW_CONNECTION = 'new'

  const dialogDeleteProps = {
    handleCancel: clearConnectionState,
    primaryActions: deletePrimaryActions,
    open: showDeleteDialog
  }

  const queryDrawerProps = {
    handleClose: queryClose,
    open: showQueryDrawer
  }

  /* render */
  return (
    <form onSubmit={(event) => void event.preventDefault()} style={{ boxShadow: colors.boxShadow }}>
      <Styled.Table data-testid="connections_table">
        <Styled.TableHead>{renderHeadings()}</Styled.TableHead>
        <Styled.TableBody>{connections.map((connection) => renderRow(connection))}</Styled.TableBody>
      </Styled.Table>
      <QueryPreviewDrawer {...queryDrawerProps} />
      <DeleteConnectionForm {...dialogDeleteProps} />
    </form>
  )

  function renderHeadings() {
    return (
      <Styled.TableRow key="connection-table-headings">
        <TableHeading label="Database Name" />
        <TableHeading label="Last Modified" />
        <TableHeading label="Connection Status" />
        <TableHeading label="Data Mapping Status" />
        <TableHeading label="" />
        <TableHeading label="Settings" style={{ textAlign: 'right', paddingRight: '2em' }} />
      </Styled.TableRow>
    )
  }

  function renderRow({ dataMappingStatus, id, lastModified, name }: connTypes.Connection) {
    return (
      <Styled.TableRow key={id}>
        <Render.Name value={name} />
        <Render.Dates lastModified={lastModified} />
        <Render.ConnectionStatus id={id} />
        <Render.DataMappingStatus status={dataMappingStatus} />
        <Render.ManageStructure
          connectionId={id}
          handleClick={handleDatabaseMapping}
          status={dataMappingStatus}
        />
        <Styled.IconsContainer status={dataMappingStatus}>
          {dataMappingStatus !== dataStatuses.not_configured && (
            <Render.QueryPreview connectionId={id} handleClick={querySubmit} />
          )}
          <Render.Settings connectionId={id} handleClick={connectionSubmit} />
          <Render.Delete connectionId={id} handleClick={handleDeletion} />
        </Styled.IconsContainer>
      </Styled.TableRow>
    )
  }

  /* callbacks */
  function clearConnectionState() {
    currActions.updateConnectionProxy({})
    setShowDeleteDialog(false)
  }

  function connectionSubmit(event: EventClick) {
    const { name: connectionId } = event.currentTarget
    const isNewConnection = connectionId === CREATE_NEW_CONNECTION

    const currentConnection: currTypes.ConnectionProxy =
      connections.find((connection: connTypes.Connection) => connection.id === connectionId) || {}

    if (isNewConnection) {
      handleRouteChange({
        destination: isNewConnection ? routesFromConnections.new : routesFromConnections.edit
      })
    } else {
      handleRouteChange({
        connectionId,
        destination: isNewConnection ? routesFromConnections.new : routesFromConnections.edit,
        type: currentConnection.connectionType
      })
    }
  }

  async function deletePrimaryActions() {
    clearConnectionState()
  }

  function handleDatabaseMapping(event: EventClick) {
    const { name: connectionId } = event.currentTarget

    dispatch(currActions.updateConnectionProxy({ id: connectionId }))
    handleRouteChange({ connectionId, destination: routesFromConnections.pii })
  }

  function handleDeletion(event: EventClick) {
    const { name: connectionId } = event.currentTarget

    const currentConnection: currTypes.ConnectionProxy =
      connections.find((connection: connTypes.Connection) => connection.id === connectionId) || {}

    dispatch(currActions.updateConnectionProxy(currentConnection))
    setShowDeleteDialog(true)
  }

  function queryClose() {
    setShowQueryDrawer(false)
    dispatch(piiActions.clearQueryPreview())
  }

  async function querySubmit(event: EventClick) {
    const { name: connectionId } = event.currentTarget

    try {
      const action = await dispatch(piiActions.fetchQueryPreview({ connectionId }))
      const result = await unwrapResult(action)

      if (result) {
        setShowQueryDrawer(true)
      }
    } catch (_) {}
  }
}
