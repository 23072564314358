import React from 'react'
import styled from 'styled-components'

/* components */
import { Link, LinkProps, Text, TextProps } from 'components/atoms'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type ContainerProps = StyledComponentProps

export type StyledLinkProps = StyledComponentProps

export type CustomLinkProps = LinkProps & StyledLinkProps & TextProps

/**
 * Container styled-component
 */
export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  padding: 1em 0;
`

/**
 * StyledLink styled-component
 */
export const StyledLink = styled(Link)<StyledLinkProps>`
  &:hover {
    text-decoration: none;
  }
`

/**
 * CustomLink presentational component
 */
export function CustomLink({ className, message, ...rest }: CustomLinkProps) {
  return (
    <Container className={className}>
      <StyledLink {...rest}>
        <Text message={message} />
      </StyledLink>
    </Container>
  )
}

export default CustomLink
