import React from 'react'
import ReactTooltip from 'react-tooltip'

/* components */
import { SimpleDotIcon } from 'components/atoms/icons/index'
import * as Styled from '../StyledElements'
import { StyledTableCellProps } from '../StyledElements/TableCell'

/* state */
import { types as schemaTypes } from 'state/schemas'

/* utils */
import { mergeMessages } from 'utils/arrays'
import { capitalize } from 'utils/primitives'
import { ErrorMessages } from 'utils/types'

/* type declarations */
export type DetectedColumnProps = StyledTableCellProps & {
  difference?: schemaTypes.Difference
  errors?: ErrorMessages
  label?: schemaTypes.EntityPropertyLabel
}

export default function DetectedColumn({ difference, errors, label = '', ...rest }: DetectedColumnProps) {
  /* constants */
  const { DIFFERENCE_COLORS, SCHEMA_DIFFERENCES } = schemaTypes

  /* render */
  const validationMessage = errors && mergeMessages(errors)
  const renderValidation = errors && (
    <SimpleDotIcon
      data-tip={mergeMessages(errors)}
      style={{
        color: DIFFERENCE_COLORS[SCHEMA_DIFFERENCES.ERROR]
      }}
    />
  )

  const differenceMessage = difference && capitalize(difference.toLowerCase())
  const renderDifference = difference && (
    <SimpleDotIcon
      data-tip={capitalize(difference.toLowerCase())}
      style={{ color: DIFFERENCE_COLORS[difference] }}
    />
  )

  const tooltip = validationMessage || differenceMessage || undefined

  return (
    <Styled.TableCell data-tip={tooltip} {...rest}>
      <Styled.FlatCellContents>
        <span style={{ paddingRight: '1em' }}>{label}</span>
        <ReactTooltip />
        {errors ? renderValidation : renderDifference || null}
      </Styled.FlatCellContents>
    </Styled.TableCell>
  )
}
