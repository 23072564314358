import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/* components */
import { QueryPreviewDrawer, UnsavedChangesForm } from 'components/organisms'
import * as Render from './RenderElements'
import { ActionBar, ActionBarProps } from './StyledElements/ActionBar'

/* state */
import { useDispatch } from 'state/store'
import { actions as connActions, selectors as connSelectors, types as connTypes } from 'state/connections'
import { actions as currActions, types as currTypes } from 'state/currentConnection'
import { actions as piiActions, selectors as piiSelectors, types as piiTypes } from 'state/piiMapping'
import { actions as schemaActions } from 'state/schemas'

/* utils */
import { DATA_STATUSES_ENABLE_DOWNLOAD, dataStatuses, routes } from 'utils/constants'

/* type declarations */
export type PiiActionBarProps = ActionBarProps & {
  connection: currTypes.ConnectionProxy
}

export default function PiiActionBar({ connection, ...rest }: PiiActionBarProps) {
  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* selector hooks */
  const connections: connTypes.Connections = useSelector(connSelectors.selectConnections) || []
  const schemaIsDone: piiTypes.SchemaIsDone = useSelector(piiSelectors.selectSchemaIsDone)
  const hasUnsavedChanges: piiTypes.HasUnsavedChanges = useSelector(piiSelectors.selectSchemaHasChanges)

  const connectionId = connection?.id || ''

  // connections selector hook contains most updated dataMappingStatus
  const currentConnection = connections?.filter((connection) => {
    return connection?.id === connectionId
  })[0]
  const disableDownload =
    !schemaIsDone ||
    DATA_STATUSES_ENABLE_DOWNLOAD.includes(currentConnection?.dataMappingStatus as dataStatuses)

  /* state hooks */
  const [hasThrownErrorOnDownload, setHasThrownErrorOnDownload] = useState(false)
  const [showQueryDrawer, setShowQueryDrawer] = useState(false)

  const [isLoadingConnection, setIsLoadingConnection] = useState(false)
  const [isRefreshingSchema, setIsRefreshingSchema] = useState(false)
  const [isDownloadingYaml, setIsDownloadingYaml] = useState(false)
  const [isFetchingPreview, setIsFetchingPreview] = useState(false)

  const [selectedConnectionId, setSelectedConnectionId] = useState<currTypes.ConnectionProxyId>('')
  const [showUnsavedWarning, setUnsavedWarning] = useState(false)

  /* effect hooks */
  useEffect(() => {
    const abortController = new AbortController()
    dispatch(connActions.fetchGetConnections({ signal: abortController.signal }))

    return () => void abortController.abort()
  }, [dispatch])

  /* constants */
  const disableActionButton = !schemaIsDone

  const dropdownProps = {
    connectionRows: createRenderConnectionRows(connections, connection),
    connection,
    setSelectedConnectionId
  }

  const unsavedProps = {
    handleClose: () => void setUnsavedWarning(false),
    open: showUnsavedWarning,
    primaryActions: loadConnection,
    buttonProps: {
      reverseStyle: true,
      style: {
        padding: '1em'
      },
      secondaryProps: {
        style: {
          marginRight: '1em'
        }
      }
    }
  }

  const queryDrawerProps = {
    handleClose: queryClose,
    open: showQueryDrawer
  }

  /* render */
  return (
    <ActionBar {...rest}>
      <Render.ConnectionLabel message="Select Database" />
      {/* Connections UI */}
      <Render.ConnectionsList {...dropdownProps} />
      <Render.LoadConnection
        handleSubmit={handleLoadConnection}
        isActive={!!selectedConnectionId}
        isLoading={isLoadingConnection}
      />
      {/* Icons UI */}
      <Render.RefreshSchema
        disabled={disableActionButton}
        handleSubmit={refreshSchema}
        isLoading={isRefreshingSchema}
      />
      <Render.UploadYaml
        connectionId={connectionId}
        disabled={disableActionButton}
        onSuccess={onUploadSuccess}
      />
      <Render.DownloadYaml
        disabled={hasThrownErrorOnDownload || disableDownload}
        handleSubmit={handleDownloadYaml}
        isLoading={isDownloadingYaml}
      />
      <Render.QueryPreview
        disabled={disableActionButton}
        handleSubmit={querySubmit}
        isLoading={isFetchingPreview}
      />

      {/* "Hidden" until triggered UI */}
      <UnsavedChangesForm {...unsavedProps} />
      <QueryPreviewDrawer {...queryDrawerProps} />
    </ActionBar>
  )

  /* callbacks */
  function createRenderConnectionRows(
    connections: connTypes.Connections,
    currentConnection: currTypes.ConnectionProxy
  ) {
    if (connections && Array.isArray(connections)) {
      return [currentConnection, ...connections.filter(({ id }) => id !== currentConnection.id)]
    }
    return [currentConnection]
  }

  async function handleDownloadYaml() {
    setIsDownloadingYaml(true)

    try {
      // Note: Because a blob isn't serializable, the actual download itself is being handled within the action.
      const action = await dispatch(piiActions.fetchDownloadStructure({ connectionId }))
      unwrapResult(action)
    } catch (err) {
      setHasThrownErrorOnDownload(true)
    }

    setIsDownloadingYaml(false)
  }

  function queryClose() {
    setShowQueryDrawer(false)
    dispatch(piiActions.clearQueryPreview())
  }

  async function querySubmit() {
    setIsFetchingPreview(true)

    try {
      const action = await dispatch(piiActions.fetchQueryPreview({ connectionId }))
      const result = await unwrapResult(action)

      setIsFetchingPreview(false)

      if (result) {
        setShowQueryDrawer(true)
      }
    } catch (_) {
      setIsFetchingPreview(false)
    }
  }

  async function onUploadSuccess() {
    await dispatch(schemaActions.triggerSchemaFlow())
  }

  function handleLoadConnection() {
    if (hasUnsavedChanges) {
      setUnsavedWarning(true)
    } else {
      loadConnection()
    }
  }

  async function loadConnection() {
    setIsLoadingConnection(true)

    if (selectedConnectionId) {
      const selectedConnection = connections?.find(({ id }) => id === selectedConnectionId) || {
        id: selectedConnectionId
      }

      await dispatch(currActions.updateConnectionProxy(selectedConnection))
      setIsLoadingConnection(false)

      history.push(`${routes.piiMapping}/${selectedConnection.id}`)
    }
  }

  async function refreshSchema() {
    setIsRefreshingSchema(true)

    // The timeout here is only to ensure that the progress bar is seen. The "trigger schema" action
    //    doesn't incorporate the whole flow, so therefore this function runs too quickly to see
    //    the progress bar otherwise.
    setTimeout(() => {
      dispatch(schemaActions.triggerSchemaFlow())
      setIsRefreshingSchema(false)
    }, 250)
  }
}
