import React from 'react'

/* components */
import { StyledHeader, StyledHeaderProps } from './StyledHeader'

/* type declarations */
export type HeaderProps = StyledHeaderProps

/**
 * Header presentational component
 */
export default function Header({ children, ...rest }: HeaderProps) {
  return (
    <StyledHeader as="header" data-testid="header" gridArea="header" paddingTop="0" {...rest}>
      {children}
    </StyledHeader>
  )
}
