import { ConfigurationConnectionResult } from 'utils/types'
import { types } from 'state/connections'

const base = ''
const connectionFlow = `${base}/configuration`
const schemaFlow = `${base}/schema`
const databaseFlow = `${base}/database`

const routes = {
  base: `${base}/`,
  schemaFlow,
  connectionFlow,
  databaseFlow,
  account: `${base}/account`,
  connections: `${base}/connections`,
  connectionSuccess: `${connectionFlow}/connections/success`,
  databasesEdit: `${connectionFlow}/:connectionId`,
  databasesNew: `${connectionFlow}/`,
  emailSent: `${base}/password/email-sent`,
  example: `${base}/example`,
  forgotPassword: `${base}/password/initiate-reset`,
  home: `${base}/`,
  login: `${base}/login`,
  matchConfigEdit: `${connectionFlow}/:connectionId/:databaseTypeId/details`,
  matchConfigNew: `${connectionFlow}/new/:databaseTypeId/details`,
  matchResultEdit: `${connectionFlow}/:connectionId/:databaseTypeId/:result`,
  matchResultNew: `${connectionFlow}/new/:databaseTypeId/:result`,
  page404: `${base}/404`,
  piiAction: `${schemaFlow}/action`,
  piiActionWithParams: `${schemaFlow}/action/:connectionId`,
  piiMapping: schemaFlow,
  piiMappingWithParams: `${schemaFlow}/:connectionId`,
  resetPassword: `${base}/password/reset`,
  structureFlow: `${databaseFlow}/upload`,
  verify: `${base}/verification`,
  /*
    generated route values
  */
  generateDatabasesRoute,
  generateConfigRoute
}

export type generateDatabasesRouteProps = {
  connectionId?: types.ConnectionId
}

function generateDatabasesRoute({ connectionId }: generateDatabasesRouteProps) {
  return `${connectionFlow}${connectionId ? `/${connectionId}` : ''}`
}

export type generateResultRouteProps = {
  connectionId?: types.ConnectionId
  databaseTypeId: string
  result: ConfigurationConnectionResult
}

export type generateConfigRouteProps = {
  connectionId?: types.ConnectionId
  databaseTypeId: string
}

function generateConfigRoute({ connectionId, databaseTypeId }: generateConfigRouteProps) {
  const selection = connectionId || 'new'
  return `${connectionFlow}/${selection}/${databaseTypeId}/details`
}

export default routes
