import React from 'react'

/* components */
import { TemplateContainer, TemplateContainerProps } from './TemplateContainer'

/* type declarations */
export type NoLayoutProps = TemplateContainerProps

export default function NoLayout({ children, ...rest }: NoLayoutProps) {
  return (
    <TemplateContainer data-testid="no_layout" {...rest}>
      {children}
    </TemplateContainer>
  )
}
