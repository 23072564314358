import React, { ReactNode } from 'react'

// routing
import { BrowserRouter as Router } from 'react-router-dom'

// state
import { Provider } from 'react-redux'
import store from 'state/store'

// styling
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import styledTheme from 'App/theme'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import muiTheme from 'App/theme/muiTheme'

export type ProvidersProps = {
  children: ReactNode
}

export function Providers({ children }: ProvidersProps) {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <StyledThemeProvider theme={styledTheme}>
          <Router>{children}</Router>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </Provider>
  )
}

export default Providers
