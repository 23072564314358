import styled, { DefaultTheme } from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledHeaderProps = BoxProps & {
  centerElements?: boolean
  theme?: DefaultTheme
}

/**
 * StyledHeader styled-component
 */
export const StyledHeader = styled(Box)<StyledHeaderProps>`
  display: flex;
  flex-direction: column;

  padding-bottom: 2.63em;

  & > * {
    padding-bottom: 0.35em;
    ${({ centerElements }) => (centerElements ? 'text-align: center' : '')};
  }
`

export default StyledHeader
