import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type StyledPrimaryButtonProps = ButtonProps

/**
 * StyledPrimaryButton styled-component
 *
 * Modifies an underlying Material UI component
 */
export const StyledPrimaryButton = styled(Button)<StyledPrimaryButtonProps>`
  && {
    margin-top: 1em;
    width: 236px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export default StyledPrimaryButton
