import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledContentProps = BoxProps

/**
 * StyledContent styled-component
 */
export const StyledContent = styled(Box)`
  grid-area: content;
  min-width: 410px;
  padding-top: 2em;
  position: relative;
  width: 25em;
  z-index: 10;
`

export default StyledContent
