import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState from './initialState'
import { types as authTypes } from 'state/auth'

/* utils */
import { ApiError } from 'utils/types'

const reducer = createReducer(initialState, {
  /* For 'Login' Page */
  [actions.fetchPostCredentials.pending.type]: () => ({ ...initialState }),
  [actions.fetchPostCredentials.fulfilled.type]: (
    state: authTypes.State,
    action: PayloadAction<authTypes.LoginCredentialsResponse>
  ) => {
    state.mfaRefId = action.payload.mfaRefId
  },

  /* For 'Logout' button */
  [actions.fetchLogout.pending.type]: () => ({ ...initialState }),

  /* For 'VerifyMfa' Page */
  [actions.fetchPostVerification.pending.type]: (state: authTypes.State) => {
    state.fieldErrors = {}
  },

  [actions.fetchPostVerification.rejected.type]: (
    state: authTypes.State,
    action: PayloadAction<ApiError>
  ) => {
    const { type, message } = action.payload

    switch (type) {
      case 'verificationCode': {
        state.fieldErrors.verificationCode = message
        break
      }
    }
  }
})

export default reducer
