import React, { ChangeEvent, useState } from 'react'

/* components */
import { GeneralForm } from 'components/molecules'
import { ExternalLink, Row } from 'components/atoms'

/* state */
import { types as authTypes } from 'state/auth'

/* utils */
import { externalUrls } from 'utils/constants'
import { EventClick, Fields } from 'utils/types'

/* type declarations */
export type LoginFormProps = authTypes.LoginParams & {
  primaryActions: () => void
  saveUsername: () => void
  setState: (params: authTypes.LoginParams) => void
}

/**
 * LoginForm state component
 */
export default function LoginForm({
  email,
  password,
  primaryActions,
  saveUsername,
  setState
}: LoginFormProps) {
  /* state hooks */
  const defaultErrorState = {
    emailError: false,
    emailHelperText: '',
    passwordError: false,
    passwordHelperText: ''
  }
  const [errorState, setErrorState] = useState(defaultErrorState)

  /* constants */
  const fields: Fields = [
    {
      error: errorState.emailError,
      helperText: errorState.emailHelperText,
      label: `Email`,
      name: 'email',
      onChange: handleChange,
      required: true,
      value: email
    },
    {
      error: errorState.passwordError,
      helperText: errorState.passwordHelperText,
      label: `Password`,
      name: 'password',
      onChange: handleChange,
      required: true,
      type: 'password',
      value: password
    }
  ]

  const formProps = {
    buttonDirection: 'column',
    fields,
    primaryButtonText: `Sign In`,
    handlePrimarySubmit,
    renderAboveButtons: buildAdditionalFields()
  }

  /* render */
  return <GeneralForm data-testid="login_form" {...formProps} />

  /* render callbacks */
  function buildAdditionalFields() {
    return (
      <Row style={{ alignItems: 'center', justifyContent: 'flex-end', paddingBottom: '2.63em' }}>
        <ExternalLink href={externalUrls.forgotPassword} target="_blank" rel="noopener noreferrer">
          Forgot Password?
        </ExternalLink>
      </Row>
    )
  }

  /* callbacks */
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget

    setErrorState(handleError(name, value))
    setState({ email, password, [name]: value })
  }

  function handleError(name: string, value: string) {
    if (name === 'password') {
      if (value.length < 6) {
        return {
          ...defaultErrorState,
          passwordError: true,
          passwordHelperText: `Your password must be 6 characters long.`
        }
      }
    }

    return defaultErrorState
  }

  function handlePrimarySubmit(event: EventClick) {
    event.preventDefault()
    saveUsername()
    primaryActions()
  }
}
