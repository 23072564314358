import { setBaseUrl } from 'utils/app'

const baseUrl = setBaseUrl({ currentEnv: process.env?.REACT_APP_ETHYCA_ENVIRONMENT || '' })

const connection = `${baseUrl}/organization/connection`

/* database types */
const databases = `${baseUrl}/database-types`

/* login */
const loginInit = `${baseUrl}/user/login/init`
const verifyMfa = `${baseUrl}/user/login/finalize`

/* misc */
const dataClasses = `${baseUrl}/property/dataclass`
const example = 'https://jsonplaceholder.typicode.com/todos'
const maskingStrategies = `${baseUrl}/property/masking-strategy`
const placeholder = `https://via.placeholder.com/100x80`
const sendDiagnostics = `${baseUrl}/sendlogs`
const version = `${baseUrl}/version`

/* user */
const userResource = `${baseUrl}/user`
const logout = `${baseUrl}/user/logout`

const endpoints = {
  connection,
  baseUrl,
  databases,
  dataClasses,
  example,
  loginInit,
  logout,
  maskingStrategies,
  verifyMfa,
  placeholder,
  sendDiagnostics,
  userResource,
  version
}

export default endpoints
