import React from 'react'
import { Image, ImageProps } from 'components/atoms'

import src from 'assets/img/writing-links.svg'

export type WritingLinksImageProps = ImageProps

export function WritingLinksImage({ ...rest }: WritingLinksImageProps) {
  return <Image src={src} {...rest} />
}

export default WritingLinksImage
