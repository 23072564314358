import React from 'react'

/* components */
import { Progress } from 'components/atoms'
import { StyledPrimaryButton, StyledPrimaryButtonProps } from './StyledPrimaryButton'
import { StyledSecondaryButton, StyledSecondaryButtonProps } from './StyledSecondaryButton'
import { StyledSubmitButtons, StyledSubmitButtonsProps } from './StyledSubmitButtons'

/* utils */
import { EventClick } from 'utils/types'

/* type declarations */
export type SubmitButtonsProps = StyledSubmitButtonsProps & {
  buttonDirection?: string
  handlePrimarySubmit?: (event: EventClick) => void
  handleSecondarySubmit?: (event: EventClick) => void
  primaryButtonText?: string
  primaryIsLoading?: boolean
  primaryProps?: StyledPrimaryButtonProps
  reverseStyle?: boolean
  secondaryButtonText?: string
  secondaryButtonType?: string
  secondaryIsLoading?: boolean
  secondaryProps?: StyledSecondaryButtonProps
  styleSecondaryAsLink?: boolean
}

/**
 * SubmitButtons presentational component
 */
export default function SubmitButtons({
  buttonDirection = 'row',
  handlePrimarySubmit,
  handleSecondarySubmit,
  primaryButtonText,
  primaryIsLoading = false,
  primaryProps,
  reverseStyle,
  secondaryButtonText,
  secondaryButtonType,
  secondaryIsLoading = false,
  secondaryProps,
  ...rest
}: SubmitButtonsProps) {
  const propsForPrimaryButton = {
    onClick: handlePrimarySubmit,
    role: 'link',
    variant: 'outlined',
    reverseStyle,
    ...primaryProps
  }

  const propsForSecondaryButton = {
    color: 'primary',
    onClick: handleSecondarySubmit,
    role: 'link',
    variant: 'outlined',
    type: secondaryButtonType,
    reverseStyle,
    ...secondaryProps
  }

  const primaryButtonContents = primaryIsLoading ? <Progress /> : <>{primaryButtonText}</>
  const secondaryButtonContents = secondaryIsLoading ? <Progress /> : <>{secondaryButtonText}</>

  if (!primaryButtonText && secondaryButtonText) {
    return (
      <StyledSubmitButtons buttonDirection={buttonDirection} data-testid="submit_buttons" {...rest}>
        <StyledSecondaryButton {...propsForSecondaryButton}>{secondaryButtonContents}</StyledSecondaryButton>
      </StyledSubmitButtons>
    )
  }

  return (
    <StyledSubmitButtons buttonDirection={buttonDirection} data-testid="submit_buttons" {...rest}>
      <>
        {buttonDirection === 'row' && secondaryButtonText && (
          <StyledSecondaryButton {...propsForSecondaryButton}>
            {secondaryButtonContents}
          </StyledSecondaryButton>
        )}
      </>
      <StyledPrimaryButton {...propsForPrimaryButton}>{primaryButtonContents}</StyledPrimaryButton>
      <>
        {buttonDirection === 'column' && secondaryButtonText && (
          <StyledSecondaryButton {...propsForSecondaryButton}>
            {secondaryButtonContents}
          </StyledSecondaryButton>
        )}
      </>
    </StyledSubmitButtons>
  )
}
