/* foreign ducks */
import { RootState } from 'state/store'

/* utils */
import { getAuthFromStorage } from 'utils/app'
import { stateNames } from 'utils/constants'

const { app } = stateNames

export const selectCurrentPath = (state: RootState) => state[app]?.currentPath
export const selectIsAuth = (state: RootState) => state[app]?.isAuth || getAuthFromStorage()
export const selectIsAuthPath = (state: RootState) => state[app]?.isAuthPath
export const selectPreviousPath = (state: RootState) => state[app]?.previousPath

const selectors = {
  selectCurrentPath,
  selectIsAuth,
  selectIsAuthPath,
  selectPreviousPath
}

export default selectors
