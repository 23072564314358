import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'

/* components */
import * as Render from './RenderElements'
import * as Styled from './StyledElements'
import { StyledTableProps } from './StyledElements/Table'

/* state */
import { types as schemaTypes } from 'state/schemas'
import { types as piiTypes } from 'state/piiMapping'

/* utils */
import { mergeColumnErrorMessages, mergeMessages } from 'utils/arrays'

/* type declarations */
export type EntitiesTableProps = StyledTableProps & {
  changeEntitySelection: (newEntity: schemaTypes.EntityForUI) => void
  columnName?: string
  entities: schemaTypes.EntitiesForUI
  schemaValidations?: piiTypes.SchemaValidations
  selectedEntity?: schemaTypes.EntityForUI
  userSelectedEntityName?: schemaTypes.EntityName
}

export function EntitiesTable({
  changeEntitySelection,
  columnName,
  entities,
  schemaValidations,
  selectedEntity,
  userSelectedEntityName,
  ...rest
}: EntitiesTableProps) {
  /* state hooks */
  const [isHidden, setIsHidden] = useState(false)

  /* constants */
  const hideButtonProps = {
    handleClick: () => void setIsHidden(!isHidden),
    isHidden
  }

  /* render */
  return (
    <Styled.PlaceTableHeadWithBody
      data-testid="entities-table"
      isHidden={isHidden}
      style={{ maxWidth: isHidden ? 0 : 350, marginRight: '1em' }}
      {...rest}>
      <ReactTooltip />
      <Render.HideTableButton {...hideButtonProps} />
      {/* ===== Main Header ===== */}
      <Styled.Table className="entities-table-container table-container-head">
        <Styled.TableHead>
          <Styled.TableRow>
            <Render.EntityTableHeading label={columnName || 'Table Names'} />
          </Styled.TableRow>
        </Styled.TableHead>
      </Styled.Table>
      {/* ===== Main Table ===== */}
      <Styled.Table className="entities-table-container table-container-body" {...rest}>
        <Styled.TableBody>{entities.map((entity) => renderRow(entity))}</Styled.TableBody>
      </Styled.Table>
      {/* ===== End Table Elements ===== */}
    </Styled.PlaceTableHeadWithBody>
  )

  function renderRow(entity: schemaTypes.EntityForUI) {
    const { name = '', ...rest } = entity

    const schemaValidation = schemaValidations?.[name]
    const errors: piiTypes.SchemaOverloadedErrorsType =
      schemaValidation?.errors || schemaValidation?.columnErrors
    const errorMessage = Array.isArray(errors) ? mergeMessages(errors) : mergeColumnErrorMessages(errors)
    const showAsSelected = name === userSelectedEntityName ? 'bold' : 'normal'

    const rowProps = {
      className: 'entities-row',
      'data-tip': errorMessage,
      key: name,
      showError: !!errorMessage
    }
    const entityProps = {
      errorMessage,
      fontWeight: showAsSelected,
      handleClick: () => handleClick(entity),
      name,
      ...rest
    }

    return (
      <Styled.TableRow {...rowProps}>
        <Render.EntityName {...entityProps} />
      </Styled.TableRow>
    )
  }

  /* callbacks */
  function handleClick(newEntity: schemaTypes.EntityForUI) {
    changeEntitySelection(newEntity)
  }
}

export default EntitiesTable
