export type BaseUrl = string | undefined

export type SetBaseUrl = ({ currentEnv }: SetBaseUrlParams) => BaseUrl
export type SetBaseUrlParams = {
  currentEnv?: string
}

export default function setBaseUrl({ currentEnv = process.env.REACT_APP_ETHYCA_ENVIRONMENT }): BaseUrl {
  // A switch statement is being used instead of a table here because Typescript assumes that process.env.*
  //    can be undefined. Typescript subsequently does not allow undefined as an accessor in a table.
  switch (currentEnv) {
    case 'dev': {
      return process.env.REACT_APP_ETHYCA_HOST_DEV_API
    }
    case 'dev2': {
      return process.env.REACT_APP_ETHYCA_HOST_DEV2_API
    }
    case 'java_local': {
      return process.env.REACT_APP_ETHYCA_HOST_JAVA_LOCAL_API
    }
    case 'node_local': {
      return process.env.REACT_APP_ETHYCA_HOST_NODE_LOCAL_API
    }
    case 'mock': {
      return process.env.REACT_APP_ETHYCA_HOST_MOCK_API
    }
    default: {
      /* As determined by the project's current production settings */
      return ''
    }
  }
}
