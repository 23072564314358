import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledContentProps = BoxProps

/**
 * StyledContent styled-component
 */
export const StyledContent = styled(Box)<StyledContentProps>`
  grid-area: content;
  max-width: 740px;
  width: 100%;
`

export default StyledContent
