import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledSecondaryButtonProps = ButtonProps & StyledComponentProps

/**
 * StyledPrimaryButton styled-component
 */
export const StyledSecondaryButton = styled(Button)<StyledSecondaryButtonProps>`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-color: ${({ theme }) => theme.colors.whiteHover};
    }
  }
`

export default StyledSecondaryButton
