import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState from './initialState'
import { types as currTypes } from 'state/currentConnection'

/* foreign ducks */
import { actions as connActions } from 'state/connections'

const reducer = createReducer(initialState, {
  [actions.updateConnectionProxy.type]: (
    _: currTypes.State,
    action: PayloadAction<currTypes.ConnectionProxy>
  ) => {
    return action.payload
  },

  [connActions.fetchDeleteConnection.fulfilled.type]: () => {
    return {}
  }
})

export default reducer
