import styled from 'styled-components'

/* components */
import { Row, RowProps } from 'components/atoms'

/* type declarations */
export type StyledMainElementProps = RowProps

/**
 * StyledMainElement styled-component
 */
export const StyledMainElement = styled(Row)<StyledMainElementProps>`
  grid-area: main-content;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export default StyledMainElement
