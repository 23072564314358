import React from 'react'
import { useSelector } from 'react-redux'

/* components */
import GlobalStyle from 'App/GlobalStyle'
import Routes from 'App/Routes'
import { AlertBanner } from 'components/organisms'

/* state */
import { selectors as appSelectors } from 'state/app'

/* utils */
import { getAuthFromStorage } from 'utils/app'

/**
 * App redux-, router-aware component
 *
 * Top-level application container
 */
export default function App() {
  // "userIsAuthenticated" is used for determining the user's authorization
  //    to access certain routes
  const isAuthFromState = useSelector(appSelectors.selectIsAuth)
  const userIsAuthenticated = getAuthFromStorage() || isAuthFromState

  // "useIsOnAuthenticatedPage" is used to determine which global styles should be rendered
  //    based on the user's path in the app; as per business rules, the parent styling
  //    between authenticated and unauthenticated routes must be noticeably different
  const userIsOnAuthenticatedPage = useSelector(appSelectors.selectIsAuthPath)

  /* render */
  // Note: GlobalStyle must be forced to re-render when switching between auth and unauth pages
  //    (This can be achieved by changing its key between these two states)
  return (
    <>
      <GlobalStyle key={+userIsOnAuthenticatedPage} onAuthPath={userIsOnAuthenticatedPage} />
      <Routes isAuth={userIsAuthenticated} />
      <AlertBanner />
    </>
  )
}
