import styled from 'styled-components'
import {
  // The compose utility: https://styled-system.com/api#compose
  compose,
  // regular imports
  border,
  flexbox,
  layout,
  space,
  // type imports
  BorderProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps
} from 'styled-system'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type RowProps = StyledComponentProps & BorderProps & FlexboxProps & LayoutProps & SpaceProps

/**
 * Row styled-system component
 */
export const Row = styled('div')<RowProps>`
  display: flex;

  ${compose(border, flexbox, layout, space)}
`

export default Row
