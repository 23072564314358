import React from 'react'
import { MdFiberManualRecord } from 'react-icons/md'

export type ConnectionStatusIconProps = {
    style: any
}

export default function ConnectionStatusIcon({ ...connectionStatusIconProps }) {
    return <MdFiberManualRecord data-testid="connection_status_icon" {...connectionStatusIconProps} />
}
