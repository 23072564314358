import React from 'react'

/* components */
import { SimpleDotIcon } from 'components/atoms/icons/index'
import * as Styled from '../StyledElements'
import { StyledTableCellProps } from '../StyledElements/TableCell'

/* state */
import { types as schemaTypes } from 'state/schemas'
import { types as piiTypes } from 'state/piiMapping'

/* utils */
import { mergeMessages } from 'utils/arrays'
import { capitalize } from 'utils/primitives'

/* type declarations */
export type EntityNameProps = StyledTableCellProps &
  schemaTypes.EntityForUI & {
    handleClick: (event: any) => void
  } & {
    errorMessage?: piiTypes.MergedErrorMessages
    fontWeight: string
  }

export default function EntityName({
  className,
  differences,
  errorMessage,
  fontWeight,
  handleClick,
  name,
  style
}: EntityNameProps) {
  /* constants */
  const { DIFFERENCE_COLORS, SCHEMA_DIFFERENCES } = schemaTypes

  /* render */
  const renderValidation = errorMessage && (
    <SimpleDotIcon
      data-tip={mergeMessages(errorMessage)}
      style={{
        color: DIFFERENCE_COLORS[SCHEMA_DIFFERENCES.ERROR]
      }}
    />
  )

  const renderDifference = differences?.map((difference, i) => {
    return (
      <SimpleDotIcon
        data-tip={capitalize(difference.toLowerCase())}
        key={`difference-${i}`}
        style={{ color: DIFFERENCE_COLORS[difference] }}
      />
    )
  })

  return (
    <Styled.TableCell className={className} style={style}>
      <Styled.CellButton name={name} fontWeight={fontWeight} onClick={handleClick}>
        {name}
        <Styled.IconFloatRight>
          {errorMessage ? renderValidation : renderDifference || null}
        </Styled.IconFloatRight>
      </Styled.CellButton>
    </Styled.TableCell>
  )
}
