import { createSelector } from 'reselect'

/* local ducks */
import { types as schemaTypes } from 'state/schemas'

/* foreign ducks */
import { RootState } from 'state/store'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'
import { selectUserSelectedEntityName } from 'state/piiMapping/selectors'

/* utils */
import { stateNames } from 'utils/constants'

const selectSchemaMap = (state: RootState) => state[stateNames.schemas]

export const selectSchema = createSelector(
  currSelectors.selectCurrentConnection,
  selectSchemaMap,
  (connection: currTypes.ConnectionProxy, schemaMap: schemaTypes.State) => schemaMap?.[connection?.id || '']
)

export const selectEntities = createSelector(
  selectSchema,
  (schema: schemaTypes.SchemaForUI) => schema?.entities
)

export const selectCurrentEntityInFocus = createSelector(
  selectEntities,
  selectUserSelectedEntityName,
  (entities: schemaTypes.EntitiesForUI, selectedEntityName: schemaTypes.EntityName) =>
    entities?.find((entity) => entity.name === selectedEntityName)
)

export const hasEntityInFocus = createSelector(
  selectEntities,
  selectUserSelectedEntityName,
  (entities: schemaTypes.EntitiesForUI, selectedEntityName: schemaTypes.EntityName) =>
    !!entities?.find((entity) => entity.name === selectedEntityName)
)

const selectors = {
  hasEntityInFocus,
  selectCurrentEntityInFocus,
  selectEntities,
  selectSchema
}

export default selectors
