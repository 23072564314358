import React from 'react'

/* components */
import { Header, HeaderProps, Navbar } from 'components/organisms'
import { Title } from 'components/molecules'
import { Paper, PaperProps } from 'components/atoms'
import { StyledContent, StyledContentProps } from './StyledContent'
import { StyledRaisedLayout, StyledRaisedLayoutProps } from './StyledRaisedLayout'

/* type declarations */
export type RaisedLayoutProps = StyledRaisedLayoutProps & {
  contentContainerProps?: StyledContentProps
  headerProps?: HeaderProps
  paperContainerProps?: PaperProps
  style?: object
  subtitle?: string
  title: string
  usePaperBackground?: boolean
  values?: object
}

/**
 * RaisedLayout presentational component
 */
export default function RaisedLayout({
  children,
  contentContainerProps,
  headerProps,
  paperContainerProps,
  subtitle,
  title,
  ...rest
}: RaisedLayoutProps) {
  return (
    <StyledRaisedLayout data-testid="raised_layout" {...rest}>
      <Navbar />
      <StyledContent {...contentContainerProps}>
        <Header centerElements {...headerProps}>
          <Title title={title} subtitle={subtitle} />
        </Header>
        <Paper as="main" {...paperContainerProps}>
          {children}
        </Paper>
      </StyledContent>
    </StyledRaisedLayout>
  )
}
