import styled from 'styled-components'

/* components */
import { Image, ImageProps } from 'components/atoms'

/* type declarations */
export type StyledImageProps = ImageProps

/**
 * StyledImage styled-component
 */
export const StyledImage = styled(Image)<StyledImageProps>`
  width: 113px;
`

export default StyledImage
