import alerts from 'state/alerts'
import app from 'state/app'
import auth from 'state/auth'
import connections from 'state/connections'
import currentConnection from 'state/currentConnection'
import databases from 'state/databases'
import piiMapping from 'state/piiMapping'
import schemas from 'state/schemas'
import user from 'state/user'

import { stateNames } from 'utils/constants'

const reducers: any = {
  [stateNames.alerts]: alerts,
  [stateNames.app]: app,
  [stateNames.auth]: auth,
  [stateNames.connections]: connections,
  [stateNames.currentConnection]: currentConnection,
  [stateNames.databases]: databases,
  [stateNames.piiMapping]: piiMapping,
  [stateNames.schemas]: schemas,
  [stateNames.user]: user
}

export default reducers
