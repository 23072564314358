import { useSelector } from 'react-redux'

/* components */
import { Heading, HelpSlider, HelpSliderProps } from 'components/molecules'
import StyledSecondaryButton from 'components/molecules/SubmitButtons/StyledSecondaryButton'
import { Box, BoxProps, HorizontalLine, Text } from 'components/atoms'

/* state */
import { selectors as piiSelectors, types as piiTypes } from 'state/piiMapping'

/* utils */
import fonts from 'App/theme/fonts'

/* type declarations */
export type QueryPreviewDrawerProps = HelpSliderProps & {
  containerProps?: BoxProps
}

export function QueryPreviewDrawer({ containerProps, handleClose, ...rest }: QueryPreviewDrawerProps) {
  /* selector hooks */
  const queryPreview: piiTypes.QueryPreview = useSelector(piiSelectors.selectQueryPreview)

  /* constants */
  const additionalSliderProps = {
    style: { width: '100%' }
  }

  /* render */
  return (
    <HelpSlider anchor="bottom" containerProps={additionalSliderProps} handleClose={handleClose} {...rest}>
      <Box {...containerProps}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Heading text="Preview Query Execution" />
          <StyledSecondaryButton onClick={handleClose} style={{ width: 'fit-content' }}>
            Close
          </StyledSecondaryButton>
        </Box>
        {queryPreview && (
          <>
            <br />
            <Text fontFamily={fonts.monospace}>{queryPreview}</Text>
            <br />
            <HorizontalLine />
          </>
        )}
      </Box>
    </HelpSlider>
  )
}

export default QueryPreviewDrawer
