export enum RELATIONSHIP_DATA_ATTRIBUTES {
  none = '',
  id = 'id',
  identity = 'identity',
  ref_id = 'ref_id',
  ref_immediate = 'ref_immediate',
  ref_identity = 'ref_identity'
}

export const RELATIONSHIPS_MAP = {
  none: {value: RELATIONSHIP_DATA_ATTRIBUTES.none, displayValue: ''},
  id: {value: RELATIONSHIP_DATA_ATTRIBUTES.id, displayValue: 'Primary ID or key'},
  identity: {value: RELATIONSHIP_DATA_ATTRIBUTES.identity, displayValue: 'Subject identity found across tables'},
  ref_id: {value: RELATIONSHIP_DATA_ATTRIBUTES.ref_id, displayValue: 'Foreign key or ID'},
  ref_immediate: {value: RELATIONSHIP_DATA_ATTRIBUTES.ref_immediate, displayValue: 'Foreign key to shared resource'},
  ref_identity: {value: RELATIONSHIP_DATA_ATTRIBUTES.ref_identity, displayValue: 'Subject identity not found in other tables'}
}

const relationships = [
  RELATIONSHIPS_MAP.none,
  RELATIONSHIPS_MAP.id,
  RELATIONSHIPS_MAP.identity,
  RELATIONSHIPS_MAP.ref_id,
  RELATIONSHIPS_MAP.ref_immediate,
  RELATIONSHIPS_MAP.ref_identity
]

export default relationships
