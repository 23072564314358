import { createAsyncThunk } from '@reduxjs/toolkit'

/* local ducks */
import { types as dbTypes } from 'state/databases'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'

/* utils */
import { MESSAGES } from 'utils/constants'

export const fetchGetConfigurations = createAsyncThunk(
  'import/databases/get-configurations',
  async ({ databaseTypeId }: dbTypes.DatabaseConfigurationParams, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI

    try {
      const module = await import(`utils/data/${databaseTypeId}`)
      return module.default
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchGetDatabases = createAsyncThunk(
  'import/databases/get-all-database-types',
  async (_, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI

    try {
      const module = await import('utils/data/_databases')
      return module.default
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

const actions = {
  fetchGetConfigurations,
  fetchGetDatabases
}

export default actions
