import { TableCell, TableCellProps } from '@material-ui/core'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type CellInputContainerProps = TableCellProps & StyledComponentProps

/**
 * TableCell styled-component
 */
export const CellInputContainer = styled(TableCell)<CellInputContainerProps>`
  position: relative;

  background-color: ${({ theme }) => theme.colors.white};

  border: solid 1px #e4e4e4;

  width: 350px;
  height: initial;

  && {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.grayDarker1};
    font-size: 1rem;
    padding: 0.5em;
  }
`

export default CellInputContainer
