import { createMuiTheme } from '@material-ui/core/styles'
import fonts from './fonts'

export const muiTheme = createMuiTheme({
  typography: {
    fontFamily: fonts.primary
  }
})

export default muiTheme
