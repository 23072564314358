import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledWideLayoutProps = BoxProps

/**
 * StyledWideLayout styled-component
 */
export const StyledWideLayout = styled(Box)<StyledWideLayoutProps>`
  position: relative;
  display: grid;
  grid-template-rows: auto 2.6em auto 2.5em;
  grid-template-columns: 1fr 1090px 1fr;
  grid-template-areas:
    'nav nav nav'
    '. . .'
    '. content .'
    '. . .';

  width: 100%;
`

export default StyledWideLayout
