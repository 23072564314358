import { createAsyncThunk } from '@reduxjs/toolkit'

/* local ducks */
import requests from './_requests'
import { selectMfaRefId } from './selectors'
import { types as authTypes } from 'state/auth'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'
import { actions as appActions } from 'state/app'

/* utils */
import { MESSAGES } from 'utils/constants'
import { ApiError, HttpResponse } from 'utils/types'

export const fetchLogout = createAsyncThunk('fetch/logout/clear-credentials', async (_, thunkAPI) => {
  const { dispatch, rejectWithValue, signal } = thunkAPI

  try {
    const res: HttpResponse<void> = await requests.logoutUser(null, { signal })

    switch (res.status) {
      case 200: {
        dispatch(appActions.setAuth(false))
        return {}
      }

      case 401: {
        const { title, message } = MESSAGES.auth

        dispatch(appActions.setAuth(false))
        dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

        return rejectWithValue({ message })
      }

      default: {
        const { title } = MESSAGES.general
        const { message }: ApiError = await res.json() as ApiError

        dispatch(
          alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
        )

        return rejectWithValue({ message })
      }
    }
  } catch (err) {
    const message = err?.message || ''
    const { title } = MESSAGES.general

    dispatch(
      alertActions.createAlert({
        title,
        message,
        severity: 'error'
      })
    )
    return rejectWithValue({ message })
  }
})

export const fetchPostCredentials = createAsyncThunk(
  'fetch/login/post-credentials',
  async (params: authTypes.LoginParams, thunkAPI) => {
    const { dispatch, rejectWithValue, signal } = thunkAPI

    try {
      const res: HttpResponse<authTypes.LoginCredentialsResponse> = await requests.postCredentials(params, { signal })

      switch (res.status) {
        case 200: {
          return await res.json() as authTypes.LoginCredentialsResponse
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = await res.json() as ApiError

          dispatch(
            alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

export const fetchPostVerification = createAsyncThunk(
  'fetch/login/post-verification',
  async (param: authTypes.MfaParams, thunkAPI) => {
    const { dispatch, getState, rejectWithValue, signal } = thunkAPI

    const params: authTypes.MfaFetchParams = {
      mfaRefId: selectMfaRefId(getState()),
      ...param
    }

    try {
      const res: HttpResponse<void> = await requests.postVerification(params, { signal })

      switch (res.status) {
        case 200: {
          dispatch(appActions.setAuth(true))
          return {}
        }

        case 401: {
          const { title, message } = MESSAGES.auth

          dispatch(appActions.setAuth(false))
          dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

          return rejectWithValue({ message })
        }

        default: {
          const { title } = MESSAGES.general
          const { message }: ApiError = await res.json() as ApiError

          dispatch(
            alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
          )

          return rejectWithValue({ message })
        }
      }
    } catch (err) {
      const message = err?.message || ''
      const { title } = MESSAGES.general

      dispatch(
        alertActions.createAlert({
          title,
          message,
          severity: 'error'
        })
      )
      return rejectWithValue({ message })
    }
  }
)

const actions = {
  fetchLogout,
  fetchPostCredentials,
  fetchPostVerification
}

export default actions
