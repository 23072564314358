import { ComponentPropsWithoutRef, ReactNode, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

/* state */
import { useDispatch } from 'state/store'
import { actions as alertActions } from 'state/alerts'
import { types as currTypes } from 'state/currentConnection'
import { actions as piiActions } from 'state/piiMapping'

/* type declarations */
export type UploadFileProps = {
  connectionId: currTypes.ConnectionProxyId
  containerProps?: ComponentPropsWithoutRef<'label'>
  onSuccess?: () => void
  render: () => ReactNode
  setLoading?: Function
}

/* render props component */
export default function UploadFile({
  connectionId,
  containerProps,
  onSuccess,
  render,
  setLoading
}: UploadFileProps) {
  /* library hooks */
  const dispatch = useDispatch()

  // Note: This is a "key hack" to resolve a specific React bug.  React doesn't like to re-trigger the input's onChange event in certain situations
  //  (such as with attempting to upload files with the same name as before), but as long as the key is made to be unique every time, the bug is avoided.
  //  Example reference: https://stackoverflow.com/questions/50300392/react-input-type-file-onchange-not-firing
  const [count, setCount] = useState<number>(0)

  /* render */
  // Note: Keys here are required here as a way to avoid a certain nasty bug. Atlas's design requires that there be file upload inputs
  //  for different parts of the app that each have different behavior. Without these keys, React has the potential to think that these inputs are actually
  // the same html--thereby triggering the wrong behavior for the mistaken elements without there being an obvious cause for the effect.
  return (
    <>
      <input
        accept=".yaml"
        id="upload-file__input"
        key={`${count}__upload-file__input`}
        style={{ display: 'none' }}
        onChange={handleUpload}
        type="file"
      />
      <label htmlFor="upload-file__input" key="upload-file__label" {...containerProps}>
        {render()}
      </label>
    </>
  )

  async function handleUpload(event: any) {
    setLoading && void setLoading(true)

    const file: File | undefined = event.target?.files[0]

    setCount(count + 1)

    if (!file) {
      dispatch(
        alertActions.createAlert({
          title: `File not recognized`,
          message: `Please try again.`,
          severity: 'warning'
        })
      )

      setLoading && void setLoading(false)
      return undefined
    } else if (!connectionId) {
      dispatch(
        alertActions.createAlert({
          title: `Unable to upload YAML file`,
          message: `No connection has been selected. Please return the connections page and try again.`,
          severity: 'warning'
        })
      )

      setLoading && void setLoading(false)
      return undefined
    }

    dispatch(piiActions.updateYamlFileName(file.name))

    const formData = new FormData()
    formData.append('structure-file', file)

    try {
      const action = await dispatch(piiActions.fetchUploadStructure({ connectionId, formData }))
      await unwrapResult(action)

      dispatch(
        alertActions.createAlert({
          title: `Success`,
          message: `"${file.name}" has been successfully uploaded.`,
          severity: 'success'
        })
      )

      onSuccess && void onSuccess()
    } catch (_) {}

    setLoading && void setLoading(false)
  }
}
