import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { UnauthLayout, UnauthLayoutProps } from 'components/templates'
import { LoginForm } from 'components/organisms'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'
import { actions as authActions, types as authTypes } from 'state/auth'

/* utils */
import { routes } from 'utils/constants'

/* types */
export type LoginProps = RouteComponentProps<{}> & UnauthLayoutProps

/**
 * Login redux- & router-aware component
 */
export default function Login({ location }: LoginProps) {
  const { pathname } = location

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* state hooks */
  const [state, setState] = useState<authTypes.LoginParams>({ email: '', password: '' })
  const [rememberMe, setRememberMe] = useState(false)

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ isAuthPath: false })), [dispatch, pathname])

  /* constants */
  const headerProps = {
    subtitle: `Please sign in with your Ethyca account details`,
    title: `Welcome to Atlas`
  }

  const formProps = {
    primaryActions,
    rememberMe,
    saveUsername,
    setRememberMe,
    setState,
    ...state
  }

  /* render */
  return (
    <UnauthLayout {...headerProps}>
      <LoginForm {...formProps} />
    </UnauthLayout>
  )

  /* callbacks */
  async function primaryActions() {
    try {
      const action = await dispatch(authActions.fetchPostCredentials(state))
      await unwrapResult(action)
      history.push(routes.verify)
    } catch (_) {}
  }

  function saveUsername() {
    // TODO
  }
}
