import styled from 'styled-components'

/* components */
import { Link, LinkProps } from 'components/atoms'

/* type declarations */
export type StyledAtlasLinkProps = LinkProps

/**
 * StyledAtlasLink styled-component
 */
export const StyledAtlasLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  letter-spacing: 7px;
  text-decoration: none;
  text-transform: uppercase;
`

export default StyledAtlasLink
