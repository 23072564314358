import React from 'react'

/* components */
import { LeftAlignCell } from '../StyledElements'

/* type declarations */
export type ConnectionNameProps = {
  value?: string
}

export default function ConnectionName({ value }: ConnectionNameProps) {
  return <LeftAlignCell>{value}</LeftAlignCell>
}
