import styled from 'styled-components'

/* components */
import { Row, RowProps } from 'components/atoms'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledRowProps = RowProps & StyledComponentProps

/**
 * StyledRow styled-component
 */
export const StyledRow = styled(Row)<StyledRowProps>`
  display: flex;
  margin: 0 1em;
  padding-top: 1em;
  width: 100%;

  && {
    & > * {
      margin: 0 0.5em;
    }
  }
`

export default StyledRow
