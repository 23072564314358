import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type CircularButtonProps = ButtonProps & {
  isHidden: boolean
}

export const CircularButton = styled(({ isHidden, ...rest }) => <Button {...rest} />)<CircularButtonProps>`
  && {
    position: absolute;
    top: 50%;
    ${({ isHidden }) => (isHidden ? `margin-left: -15px;` : `right: -3%;`)}
    z-index: 2;

    min-width: initial;
    min-height: initial;
    height: 28px;
    width: 28px;
    border-radius: 50%;

    font-size: 1.1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryHover};
    }
  }
`

export default CircularButton
