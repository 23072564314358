import React from 'react'

/* components */
import { StyledText, StyledTextProps } from './StyledText'

/* type declarations */
export type TextProps = StyledTextProps & {
  message?: string
}

/**
 * Text presentational component
 */
export default function Text({ children, message, ...rest }: TextProps) {
  return (
    <StyledText data-testid="text" {...rest}>
      {message || children}
    </StyledText>
  )
}
