import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledPiiLayoutProps = BoxProps

export const StyledPiiLayout = styled(Box)<StyledPiiLayoutProps>`
  position: relative;
  display: grid;
  grid-row-gap: 1em;
  grid-template-rows: 4em 1em 2.6em auto 2.5em;
  grid-template-columns: 2% 1fr 2%;
  grid-template-areas:
    'nav nav nav'
    '. extra-nav .'
    '. header .'
    '. content .'
    '. . .';

  height: 100%;
  width: 100%;
`

export default StyledPiiLayout
