import styled from 'styled-components'

/* components */
import { TextField, TextFieldProps } from 'components/atoms'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTextFieldProps = TextFieldProps & StyledComponentProps

/**
 * StyledTextField styled-component
 */
export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  && {
    & input {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight2};
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
    }
  }
`

export default StyledTextField
