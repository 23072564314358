import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { WideContentLayout, WideContentLayoutProps } from 'components/templates'
import { DatabasesForm, LoadingScreen } from 'components/organisms'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'
import {
  actions as currActions,
  selectors as currSelectors,
  types as currTypes
} from 'state/currentConnection'
import { actions as dbActions, selectors as dbSelectors, types as dbTypes } from 'state/databases'

/* utils */
import { pageFlows, routes } from 'utils/constants'
import { EventClick } from 'utils/types'

/* type declarations */
export type ChooseDatabaseProps = RouteComponentProps<{}> & WideContentLayoutProps

/**
 * ChooseDatabase redux- & router-aware component
 */
export default function ChooseDatabase({ location }: ChooseDatabaseProps) {
  const { pathname } = location

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* state hooks */
  const [isLoaded, setIsLoaded] = useState(false)

  /* selector hooks */
  const connectionInState: currTypes.ConnectionProxy =
    useSelector(currSelectors.selectCurrentConnection) || {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const databases: dbTypes.ChooseDatabase = useSelector(dbSelectors.selectDatabases) || []

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ isAuthPath: true })), [dispatch, pathname])

  useEffect(() => {
    if (!isLoaded) {
      if (!databases.length) {
        setIsLoaded(true)
        dispatch(dbActions.fetchGetDatabases())
      } else {
        setIsLoaded(true)
      }
    }
  }, [databases, dispatch, isLoaded])

  /* constants */
  const { id: loadedId, connectionType } = connectionInState
  const pageVersion = loadedId ? pageFlows.edit : pageFlows.new

  const pageConfig = {
    [pageFlows.edit]: {
      subtitle: `The database type cannot be edited. To configure for a different database type, a new connection must be created.`,
      title: `Current Database Type`
    },
    [pageFlows.new]: {
      subtitle: `Select from one of the database types below. You will add connection information in the next step.`,
      title: `Add a New Database`
    }
  }

  const headerProps = {
    title: pageConfig[pageVersion].title,
    subtitle: pageConfig[pageVersion].subtitle
  }

  const formProps = {
    currentType: connectionType,
    databases,
    pageVersion,
    primaryActions,
    secondaryActions
  }

  /* render */
  return (
    <WideContentLayout {...headerProps}>
      {isLoaded ? <DatabasesForm {...formProps} /> : <LoadingScreen />}
    </WideContentLayout>
  )

  /* callbacks */
  function primaryActions(event: EventClick) {
    const { name: databaseTypeId } = event.currentTarget

    const route = routes.generateConfigRoute({
      connectionId: pageVersion === pageFlows.edit ? loadedId : undefined,
      databaseTypeId
    })

    dispatch(currActions.updateConnectionProxy(createUpdatedConnection(connectionInState, databaseTypeId)))

    history.push(route)
  }

  function secondaryActions() {
    dispatch(currActions.updateConnectionProxy({}))
    history.push(routes.home)
  }

  function createUpdatedConnection(
    currentConnection: currTypes.ConnectionProxy,
    databaseTypeId: dbTypes.DatabaseTypeId
  ): currTypes.ConnectionProxy {
    return {
      ...currentConnection,
      connectionType: databaseTypeId,
      configurations:
        currentConnection.connectionType === databaseTypeId ? currentConnection.configurations : []
    }
  }
}
