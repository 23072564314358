import React from 'react'
import { useHistory } from 'react-router-dom'

/* components */
import { FlatLayout } from 'components/templates'
import { Progress, StackProps } from 'components/atoms'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type LoadingScreenProps = StackProps

/**
 * LoadingScreen presentational component
 */
export default function LoadingScreen() {
  const history = useHistory()

  const buttonProps = {
    handleSecondarySubmit,
    secondaryButtonText: `Cancel`,
    secondaryProps: {
      variant: 'outlined'
    }
  }

  const layoutProps = {
    buttonProps,
    subtitle: `Please wait one moment`,
    title: `Loading...`
  }

  return (
    <FlatLayout {...layoutProps}>
      <Progress style={{ width: '100%' }} />
    </FlatLayout>
  )

  function handleSecondarySubmit() {
    history.push(routes.home)
  }
}
