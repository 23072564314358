/* local ducks */
import { types as authTypes } from 'state/auth'

/* utils */
import { configureInit } from 'utils/app'
import { endpoints } from 'utils/constants'
import { Empty, HttpResponse, RequestOptions } from 'utils/types'

export function logoutUser(_: Empty, { signal }: RequestOptions): Promise<HttpResponse<void>> {
  const input: RequestInfo = endpoints.logout
  const init: RequestInit = configureInit({
    method: 'DELETE',
    requiresAuth: true,
    signal
  })

  return fetch(input, init)
}

export function postCredentials(
  params: authTypes.LoginFetchParams,
  { signal }: RequestOptions
): Promise<HttpResponse<authTypes.LoginCredentialsResponse>> {
  const input: RequestInfo = endpoints.loginInit
  const init: RequestInit = configureInit({
    body: JSON.stringify(params),
    method: 'POST',
    signal
  })

  return fetch(input, init)
}

export function postVerification(params: authTypes.MfaParams, { signal }: RequestOptions): Promise<HttpResponse<void>> {
  const input: RequestInfo = endpoints.verifyMfa
  const init: RequestInit = configureInit({
    body: JSON.stringify(params),
    method: 'POST',
    signal
  })

  return fetch(input, init)
}

const requests = {
  logoutUser,
  postCredentials,
  postVerification
}

export default requests
