const stateNames = {
  alerts: 'alerts',
  app: 'app',
  auth: 'auth',
  connections: 'connections',
  currentConnection: 'currentConnection',
  databases: 'databases',
  errors: 'errors',
  example: 'example',
  oldSchemas: 'oldSchemas',
  piiMapping: 'piiMapping',
  schemas: 'schemas',
  user: 'user'
}

export default stateNames
