import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledFooterProps = StyledComponentProps

/**
 * StyledFooter styled-component
 */
export const StyledFooter = styled.footer<StyledFooterProps>`
  grid-area: footer;
  align-self: end;

  display: flex;
  justify-content: space-evenly;

  color: ${({ theme }) => theme.colors.white};
  font-size: 0.9rem;
  padding: 0 2em;
  z-index: 1;
`

export default StyledFooter
