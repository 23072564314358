import React from 'react'
import styled from 'styled-components'
import { LinearProgress, LinearProgressProps } from '@material-ui/core'

export type ProgressProps = LinearProgressProps

export default function Progress({ ...rest }: ProgressProps) {
  return (
    <>
      <StyledProgress {...rest} />
    </>
  )
}

const StyledProgress = styled(LinearProgress)<LinearProgressProps>`
  width: 100%;
`
