import styled from 'styled-components'
import { DialogContent, DialogContentProps } from '@material-ui/core'

/* utils */
import { StyledComponentProps } from 'utils/types'

export type StyledDialogContentProps = StyledComponentProps & DialogContentProps

export const StyledDialogContent = styled(DialogContent)<StyledDialogContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  width: 350px;
  padding: 0 !important;
  padding-bottom: 1.25em !important;
`

export default StyledDialogContent
