import { Button as OriginalButton, ButtonProps as OriginalButtonProps } from '@material-ui/core'
import styled from 'styled-components'
import {
  color,
  layout,
  space,
  ColorProps,
  LayoutProps,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system'

/* heleprs */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledButtonProps = StyledComponentProps &
  OriginalButtonProps &
  ColorProps &
  LayoutProps &
  SpaceProps &
  TypographyProps

/* StyledButton styled-component */
export const StyledButton = styled(OriginalButton)<StyledButtonProps>`
  font-size: 0.9375rem;
  min-height: 50px;
  width: 100%;
  ${color};
  ${layout};
  ${space};
  ${typography};
`

export default StyledButton
