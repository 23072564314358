import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledMultiRaisedLayoutProps = BoxProps

/**
 * StyledMultiRaisedLayout styled-component
 */
export const StyledMultiRaisedLayout = styled(Box)<StyledMultiRaisedLayoutProps>`
  position: relative;
  display: grid;
  grid-template-rows: auto 2.6em auto 2.5em;
  grid-template-columns: 1fr 540px 1fr;
  grid-template-areas:
    'nav nav nav'
    '. . .'
    'content content content'
    '. . .';

  width: 100%;

  & > * {
    justify-self: center;
  }
`

export default StyledMultiRaisedLayout
