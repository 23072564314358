import styled from 'styled-components'
import {
  compose,
  color,
  layout,
  space,
  typography,
  ColorProps,
  LayoutProps,
  SpaceProps,
  TypographyProps
} from 'styled-system'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTextProps = StyledComponentProps & ColorProps & TypographyProps & LayoutProps & SpaceProps

/**
 * StyledText styled-system component
 */
export const StyledText = styled.p`
  white-space: pre-wrap;

  ${compose(color, layout, space, typography)}
`

export default StyledText
