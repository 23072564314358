/**
 * Due to a problem with circular dependencies, these specific are declared separately from the rest of the schema actions
 *      and have to be imported separately from the others as well
 */

import { createAsyncThunk } from '@reduxjs/toolkit'

/* local ducks */
import requests from './_requests'
import { types as schemaTypes } from 'state/schemas'

/* foreign ducks */
import { actions as alertActions } from 'state/alerts'
import { actions as appActions } from 'state/app'
import { selectors as currSelectors, types as currTypes } from 'state/currentConnection'

import { actions as piiActions, types as piiTypes } from 'state/piiMapping'
import { selectors as schemaSelectors } from 'state/schemas'

/* utils */
import schemaUtils from './utils'
import { MESSAGES } from 'utils/constants'
import { composer } from 'utils/functions'
import { ApiError, HttpResponse } from 'utils/types'

export const fetchFinishSchema = createAsyncThunk('fetch/schemas/finish-schema', async (_, thunkAPI) => {
  const { dispatch, getState, rejectWithValue, signal } = thunkAPI

  dispatch(piiActions.clearSchemaValidationErrors())

  const currentState = getState()

  const schema = composer(currentState)
    .map(schemaSelectors.selectSchema)
    .map(schemaUtils.handleUnmappings)
    .resolve(schemaUtils.whitelistValuesForSchema)

  const connectionId = composer(currentState)
    .map(currSelectors.selectCurrentConnection)
    .resolve((connection: currTypes.ConnectionProxy) => connection?.id || '')

  try {
    const res: HttpResponse<schemaTypes.SchemaForUI> = await requests.finishSchema(
      { connectionId, schema },
      { signal }
    )

    switch (res.status) {
      case 200: {
        const json: schemaTypes.SchemaForUI = (await res.json()) as schemaTypes.SchemaForUI

        if (json?.entities) {
          dispatch(
            alertActions.createAlert({
              title: MESSAGES.finishSchema.title,
              message: MESSAGES.finishSchema.message,
              severity: 'success'
            })
          )
        } else {
          // A "200" response with no entities is most likely a situation where the entire
          //  schema is (intentionally) being deleted.
          dispatch(
            alertActions.createAlert({
              title: MESSAGES.finishSchemaUnmapped.title,
              message: MESSAGES.finishSchemaUnmapped.message,
              severity: 'success'
            })
          )
        }

        return {
          ...json,
          connectionId
        }
      }

      case 401: {
        const { title, message } = MESSAGES.auth

        dispatch(appActions.setAuth(false))
        dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

        return rejectWithValue({ connectionId, message })
      }

      case 422: {
        const { errors } = (await res.json()) as piiTypes.SchemaApiErrorsResponse

        dispatch(alertActions.createAlert(schemaUtils.convertSchemaErrorsToAlerts(errors)))
        dispatch(piiActions.addSchemaValidationErrors(errors))

        return rejectWithValue({
          connectionId,
          errors
        })
      }

      default: {
        const { title } = MESSAGES.general
        const { message }: ApiError = (await res.json()) as ApiError

        dispatch(
          alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
        )

        return rejectWithValue({ connectionId, message })
      }
    }
  } catch (err) {
    const message = err?.message || ''
    const { title } = MESSAGES.general

    dispatch(
      alertActions.createAlert({
        title,
        message,
        severity: 'error'
      })
    )
    return rejectWithValue({ connectionId, message })
  }
})

export const fetchSaveSchema = createAsyncThunk('fetch/schemas/save-schema', async (_, thunkAPI) => {
  const { dispatch, getState, rejectWithValue, signal } = thunkAPI

  const currentState = getState()

  const schema = composer(currentState).resolve(schemaSelectors.selectSchema)

  const connectionId = composer(currentState)
    .map(currSelectors.selectCurrentConnection)
    .resolve((connection: currTypes.ConnectionProxy) => connection?.id || '')

  try {
    const res: HttpResponse<schemaTypes.SchemaForUI> = await requests.saveSchema(
      { connectionId, schema },
      { signal }
    )

    switch (res.status) {
      case 200: {
        const json: schemaTypes.SchemaForUI = (await res.json()) as schemaTypes.SchemaForUI

        dispatch(
          alertActions.createAlert({
            title: MESSAGES.saveSchema.title,
            message: MESSAGES.saveSchema.message,
            severity: 'success'
          })
        )

        return {
          ...json,
          connectionId
        }
      }

      case 401: {
        const { title, message } = MESSAGES.auth

        dispatch(appActions.setAuth(false))
        dispatch(alertActions.createAlert({ title, message, severity: 'error' }))

        return rejectWithValue({ connectionId, message })
      }

      case 422: {
        const { errors } = (await res.json()) as piiTypes.SchemaApiErrorsResponse

        dispatch(alertActions.createAlert(schemaUtils.convertSchemaErrorsToAlerts(errors)))
        dispatch(piiActions.addSchemaValidationErrors(errors))

        return rejectWithValue({
          connectionId,
          errors
        })
      }

      default: {
        const { title } = MESSAGES.general
        const { message }: ApiError = (await res.json()) as ApiError

        dispatch(
          alertActions.createAlert({ title: `${title} (Status ${res.status})`, message, severity: 'error' })
        )

        return rejectWithValue({ connectionId, message })
      }
    }
  } catch (err) {
    const message = err?.message || ''
    const { title } = MESSAGES.general

    dispatch(
      alertActions.createAlert({
        title,
        message,
        severity: 'error'
      })
    )
    return rejectWithValue({ connectionId, message })
  }
})

const asyncActions = {
  fetchFinishSchema,
  fetchSaveSchema
}

export default asyncActions
