/* local ducks */
import { types as alertTypes } from 'state/alerts'

const initialState: alertTypes.State = {
  message: '',
  open: false,
  severity: undefined,
  title: '',
  titleProps: undefined
}

export default initialState
