import styled from 'styled-components'
import {
  // The compose utility: https://styled-system.com/api#compose
  compose,
  // regular imports
  color,
  flexbox,
  layout,
  grid,
  position,
  space,
  // type imports
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps
} from 'styled-system'
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type BoxProps = StyledComponentProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps

/* Box styled-system component */
export const Box = styled('div')<BoxProps>`
  ${compose(color, flexbox, grid, layout, position, space)}
`

export default Box
