import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

export type StyledBodyProps = BoxProps

export const StyledBody = styled(Box)<StyledBodyProps>`
  height: 100%;
  padding: 1em 2em;
`

export default StyledBody
