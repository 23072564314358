import React from 'react'

/* components */
import { ExternalLink, ExternalLinkProps, Link, LinkProps } from 'components/atoms'
import { StyledImage, StyledImageProps } from './StyledImage'

/* assets */
import img from 'assets/img/ethyca.svg'

/* type declarations */
export type EthycaLogoProps = StyledImageProps &
  UrlProps & {
    href?: string
    to?: string
  }

export type UrlProps = ExternalLinkProps | LinkProps

/**
 * EthycaLogo presentational component
 */
export default function EthycaLogo({ href, to, ...rest }: EthycaLogoProps) {
  const image = <StyledImage alt="Ethyca" data-testid="ethyca_logo" src={img} {...rest} />
  if (href) {
    return (
      <ExternalLink href={href} target="_blank" rel="noopener noreferrer">
        {image}
      </ExternalLink>
    )
  }

  if (to) {
    return <Link to={to}>{image}</Link>
  }

  return image
}
