import styled from 'styled-components'

/* components */
import { Button, ButtonProps } from 'components/atoms'

/* type declarations */
export type RenameTableButtonProps = ButtonProps

export const RenameTableButton = styled(Button)<RenameTableButtonProps>`
  width: initial;

  && {
    background-color: #757575;
    color: ${({ theme }) => theme.colors.white};
    font-size: 13px;
    min-height: 2.5em;
    padding: 6px 1.6em;

    &:hover {
      background-color: #666666;
    }
  }
`

export default RenameTableButton
