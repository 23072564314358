import React, { ChangeEvent, useState } from 'react'

/* components */
import { Box } from 'components/atoms'
import { StyledText } from '../StyledText'
import { RenameTableButton } from './RenameTableButton'
import { TableNameButton } from './TableNameButton'
import { TableNameInput } from './TableNameInput'

/* state */
import { types as currTypes } from 'state/currentConnection'
import { types as schemaTypes } from 'state/schemas'

/* type declarations */
export type CategoryNameHandlerProps = {
  connectionId: currTypes.ConnectionProxyId
  changeValue: (props: schemaTypes.SchemaTableChange) => void
  entityCategory: schemaTypes.EntityCategory
  entityName: string
}

export function CategoryNameHandler({
  connectionId,
  changeValue,
  entityCategory,
  entityName
}: CategoryNameHandlerProps) {
  /* state hooks */
  const [isChangingName, setIsChangingName] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(entityCategory || '')

  /* constants */
  const displayValue = `${entityCategory || 'None'}`
  const tableNameButtonProps = {
    fontSize: '1.5rem',
    textTransform: 'initial'
  }

  /* render */
  return (
    <Box display="flex" alignItems="center">
      <StyledText>Category : </StyledText>
      {isChangingName ? (
        <TableNameInput onChange={handleChange} value={inputValue} />
      ) : (
        <TableNameButton message={displayValue} style={tableNameButtonProps} onClick={activateChangeName} />
      )}
      {isChangingName && <RenameTableButton onClick={renameTable}>Rename Category</RenameTableButton>}
    </Box>
  )

  /* callbacks */
  function activateChangeName() {
    setIsChangingName(true)
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget
    setInputValue(value)
  }

  function renameTable() {
    changeValue({ connectionId, entityName, newCategory: inputValue })
    setIsChangingName(false)
  }
}

export default CategoryNameHandler
