/* foreign ducks */
import { RootState } from 'state/store'

/* utils */
import { stateNames } from 'utils/constants'

const { user } = stateNames

export const selectUserDetails = (state: RootState) => state[user]
export const selectFetchState = (state: RootState) => state[user]?.fetchState
export const selectName = (state: RootState) => state[user]?.name
export const selectOrganizationName = (state: RootState) => state[user]?.organizationName

const selectors = {
  selectFetchState,
  selectName,
  selectOrganizationName,
  selectUserDetails
}

export default selectors
