import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type StyledSubmitButtonsProps = BoxProps & {
  buttonDirection?: string
}

/**
 * StyledSubmitButtons styled-component
 */
export const StyledSubmitButtons = styled(Box)<StyledSubmitButtonsProps>`
  display: flex;
  flex-direction: ${({ buttonDirection }) => buttonDirection || 'row'};
  justify-content: space-between;

  position: relative;
  width: 100%;

  & button {
    width: ${({ buttonDirection }) => (buttonDirection === 'row' ? '225px' : 'initial')};
  }
`

export default StyledSubmitButtons
