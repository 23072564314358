import styled from 'styled-components'

/* components */
import { GeneralDialog, GeneralDialogProps } from 'components/molecules'

/* type declarations */
export type DialogContainerProps = GeneralDialogProps

export const DialogContainer = styled(GeneralDialog)<DialogContainerProps>``

export default DialogContainer
