import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

/* components */
import { LeftAlignCell } from '../StyledElements'
import { ConnectionStatusIcon } from 'components/atoms/icons/index';
import { ConnectionStatusIconProps } from 'components/atoms/icons/ConnectionStatusIcon';

/* state */
import { useDispatch } from 'state/store'
import { actions as connActions, types as connTypes } from 'state/connections'

/* utils */
import { CONNECTION_STATUSES } from 'utils/constants'

/* type declarations */
export type ConnectionStatusProps = {
    id: connTypes.ConnectionId
}

export default function ConnectionStatus({ id }: ConnectionStatusProps) {
    /* library hooks */
    const dispatch = useDispatch()

    /* state hooks */
    const [status, setStatus] = useState(CONNECTION_STATUSES.LOADING)

    useEffect(() => {
        getConnectionStatus({ id });

        async function getConnectionStatus({ id }: ConnectionStatusProps) {
            const dispatchedStatus = await dispatch(connActions.fetchStatusByConnectionId({ connectionId: id }))
            const status = unwrapResult(dispatchedStatus) as unknown as connTypes.ConnectionStatus;
            setStatus(status && status.passed
                ? CONNECTION_STATUSES.CONNECTED
                : CONNECTION_STATUSES.NOT_CONNECTED);
        }
    }, [dispatch, id, setStatus])


    const connectionStatusIconProps: ConnectionStatusIconProps = {
        style: {
            verticalAlign: 'middle',
            height: '12px',
            width: '12px',
            margin: '4px 11px 6px 0',
            color: status === CONNECTION_STATUSES.CONNECTED ? '#0edd4d' : status === CONNECTION_STATUSES.NOT_CONNECTED ? '#dd0e0e' : 'yellow'
        }
    }
    return <LeftAlignCell><ConnectionStatusIcon {...connectionStatusIconProps} />{status}</LeftAlignCell>
}
