import React from 'react'

/* components */
import * as Styled from '../StyledElements'
import { StyledTableCellProps } from '../StyledElements/TableCell'

/* utils */
import { EventClick } from 'utils/types'

/* type declarations */
export type EntityTableHeadingProps = StyledTableCellProps & {
  handleClick?: (event: any) => void
  label: string
}

export default function EntityTableHeading({ handleClick, label, ...rest }: EntityTableHeadingProps) {
  return (
    <Styled.TableHeading {...rest}>
      <Styled.CellButton onClick={onClick}>{label}</Styled.CellButton>
    </Styled.TableHeading>
  )

  function onClick(event: EventClick) {
    if (handleClick) {
      handleClick(event)
    }
  }
}
