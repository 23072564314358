import styled from 'styled-components'
import { StyledComponentProps } from 'utils/types'

export type SectionProps = StyledComponentProps

export const Section = styled.section<SectionProps>`
  & > *:not(:first-child) {
    margin-top: 0.5em;
  }

  & input {
    padding: 0;
  }
`

export default Section
