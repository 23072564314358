import React from 'react'

/* components */
import { SubmitButtons } from 'components/molecules'
import { CenterAlignCell } from '../StyledElements'

/* state */
import { types as connTypes } from 'state/connections'

/* utils */
import { DATA_STATUSES_BUTTON_MAP } from 'utils/constants'
import { EventClick } from 'utils/types'

/* type declarations */
export type ManageStructureProps = {
  connectionId: string
  handleClick: (event: EventClick) => void
  status: connTypes.DataMappingStatus
}

export default function ManageStructure({ connectionId, handleClick, status }: ManageStructureProps) {
  const label = DATA_STATUSES_BUTTON_MAP[status || '']
  const hasBeenMapped = !label

  const activeButtonStyle = {
    minHeight: 16,
    width: 189
  }

  const nonactiveButtonStyle = {
    border: 'solid 1px rgba(0, 0, 0, 0.12',
    color: 'rgba(0, 0, 0, 0.38)',
    minHeight: 16,
    width: 189
  }

  const buttonProps = {
    handleSecondarySubmit: handleClick,
    secondaryButtonText: hasBeenMapped ? 'Map PII' : label,
    secondaryProps: {
      name: connectionId,
      style: hasBeenMapped ? nonactiveButtonStyle : activeButtonStyle
    },
    style: {
      marginTop: 10
    }
  }

  return (
    <CenterAlignCell>
      <SubmitButtons {...buttonProps} />
    </CenterAlignCell>
  )
}
