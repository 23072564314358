import React from 'react'

/* components */
import { StyledTextField, StyledTextFieldProps } from './StyledTextField'

/* utils */
import { Field } from 'utils/types'

/* type declarations */
export type TextFieldProps = StyledTextFieldProps & Field

/* TextField presentational component */
export default function TextField({ label, name, type = 'text', ...rest }: TextFieldProps) {
  return <StyledTextField data-testid="text_field" label={label || name} name={name} type={type} {...rest} />
}
