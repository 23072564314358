import { createReducer, PayloadAction } from '@reduxjs/toolkit'

/* local ducks */
import actions from './actions'
import initialState from './initialState'
import { types as appTypes } from 'state/app'

/* foreign ducks */
import { actions as authActions } from 'state/auth'

const reducer = createReducer(initialState, {
  [actions.setAuth.type]: (state: appTypes.State, action: PayloadAction<appTypes.IsAuth>) => {
    state.isAuth = action.payload
  },

  [actions.updateRoute.type]: (state: appTypes.State, action: PayloadAction<appTypes.PathData>) => ({
    ...state,
    previousPath: state.currentPath,
    ...action.payload
  }),

  [authActions.fetchPostVerification.fulfilled.type]: (state: appTypes.State) => {
    state.isAuth = true
  }
})

export default reducer
