import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { RouteComponentProps, useHistory } from 'react-router-dom'

/* components */
import { MultiRaisedLayout, MultiRaisedLayoutProps } from 'components/templates'
import { ValidationModal } from 'components/organisms'
import { SubmitButtons, UploadFile } from 'components/molecules'
import { StyledPrimaryButton } from 'components/molecules/SubmitButtons/StyledPrimaryButton'
import { Link, Progress, Row, Text } from 'components/atoms'
import { CloudUploadIcon } from 'components/atoms/icons/index'
import { StyledCard } from './StyledCard'
import { WritingLinksImage } from './WritingLinksImage'

/* state */
import { useDispatch } from 'state/store'
import { actions as appActions } from 'state/app'
import { actions as dbActions, selectors as dbSelectors } from 'state/databases'
import {
  actions as currActions,
  selectors as currSelectors,
  types as currTypes
} from 'state/currentConnection'
import { selectors as piiSelectors, types as piiTypes } from 'state/piiMapping'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type PiiMappingActionProps = MultiRaisedLayoutProps & RouteComponentProps<any>

export default function PiiMappingAction({ location, match }: PiiMappingActionProps) {
  const { pathname } = location
  const { connectionId: currentId }: { connectionId: currTypes.ConnectionProxyId } = match.params

  /* library hooks */
  const dispatch = useDispatch()
  const history = useHistory()

  /* state hooks */
  const [isUploadingYaml, setIsUploadingYaml] = useState(false)

  /* selector hooks */
  const connectionInState: currTypes.ConnectionProxy =
    useSelector(currSelectors.selectCurrentConnection) || {}
  const currentDatabaseType = useSelector(dbSelectors.selectCurrentDatabaseType)
  const openYamlValidationModal: piiTypes.OpenYamlValidationModal = useSelector(
    piiSelectors.selectOpenYamlValidationModal
  )
  const validationMessages: piiTypes.YamlValidationErrors = useSelector(
    piiSelectors.selectYamlValidationErrors
  )

  const { id: loadedId } = connectionInState

  /* effect hooks */
  useEffect(() => void dispatch(appActions.updateRoute({ currentPath: pathname, isAuthPath: true })), [
    dispatch,
    pathname
  ])

  // If the route has a connection id but not redux state (i.e., the user navigated here directly),
  //  or if these values are different for some reason, then update the redux state with this new id
  useEffect(() => {
    if (currentId !== loadedId) {
      dispatch(currActions.updateConnectionProxy({ id: currentId }))
    }
  }, [currentId, dispatch, loadedId])

  useEffect(() => {
    if (!currentDatabaseType) {
      dispatch(dbActions.fetchGetDatabases())
    }
  }, [currentDatabaseType, dispatch])

  /* constants */
  const connectionId = currentId || loadedId
  const disabled = !!currentDatabaseType?.disableMapping

  const sections = [renderYamlComponent(), renderPiiComponent()]

  const layoutProps: MultiRaisedLayoutProps = {
    sections,
    subtitle: `The next step is to create your PII mapping. This involves identifying where Personally Identifiable Information (PII) resides within the database schema.`,
    title: `Create Your PII Mapping`
  }

  return (
    <>
      <MultiRaisedLayout {...layoutProps} />
      <Row justifyContent="center">
        <Link to={routes.connections}>Return to Database Connections</Link>
      </Row>
      <ValidationModal unformattedMessages={validationMessages} open={openYamlValidationModal} />
    </>
  )

  function renderYamlComponent() {
    const textProps = {
      style: { textAlign: 'center' } as React.CSSProperties
    }

    return (
      <StyledCard key="yaml-section">
        <Row>
          <CloudUploadIcon />
        </Row>
        <Text {...textProps}>If you have a .yaml file provided by Ethyca, upload it here</Text>
        <Row>
          <UploadFile
            connectionId={connectionId || ''}
            key="upload-file__component"
            onSuccess={onUploadSuccess}
            setLoading={setIsUploadingYaml}
            render={() => (
              <StyledPrimaryButton
                component="span"
                disabled={isUploadingYaml}
                key="upload-file__button"
                style={{ width: 225 }}>
                {isUploadingYaml ? <Progress /> : <>Upload YAML</>}
              </StyledPrimaryButton>
            )}
          />
        </Row>
      </StyledCard>
    )
  }

  function renderPiiComponent() {
    const disabledMessage = `Feature currently unavailable for ${currentDatabaseType?.type}.`

    const buttonProps = {
      handlePrimarySubmit: routeToPiiMapping,
      primaryButtonText: `Update PII Mapping`,
      primaryProps: {
        disabled,
        style: disabled
          ? {
              color: 'lightgray'
            }
          : undefined
      }
    }
    const textProps = {
      style: { textAlign: 'center' } as React.CSSProperties
    }

    return (
      <StyledCard disabled={disabled} data-tip={disabled ? disabledMessage : undefined} key="pii-section">
        {disabled && <ReactTooltip />}
        <Row>
          <WritingLinksImage />
        </Row>
        <Text {...textProps}>If you do not have a .yaml file, add your PII mapping here</Text>
        <Row>
          <SubmitButtons {...buttonProps} />
        </Row>
      </StyledCard>
    )
  }

  /* callbacks */
  function routeToPiiMapping() {
    const connectionId = currentId || loadedId

    if (connectionId) {
      history.push(`${routes.piiMapping}/${connectionId}`)
    } else {
      history.push(routes.connections)
    }
  }

  // Handle the actions involved in uploading a schema file
  async function onUploadSuccess() {
    setIsUploadingYaml(false)
    const destination = disabled ? routes.connections : `${routes.piiMapping}/${connectionId}`
    history.push(destination)
  }
}
