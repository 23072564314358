import React, { ReactNode } from 'react'
import styled from 'styled-components'

/* type declarations */
export type BackgroundProps = {
  children: ReactNode
}

/**
 * Layer1 styled-component
 */
export const Layer1 = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`

/**
 * Layer2 styled-component
 */
export const Layer2 = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary};

  &::before {
    top: -340px;
    left: 15vw;
    right: 50vw;
    height: 500px;
    content: '';
    position: absolute;
    transform: skewY(22deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transform-origin: 0 0;
  }

  &::after {
    top: -120px;
    left: 50vw;
    right: 0px;
    height: 500px;
    content: '';
    position: absolute;
    transform: skewY(22deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transform-origin: 0 0;
  }
`

/**
 * Layer3 styled-component
 */
export const Layer3 = styled.div`
  z-index: 1;

  &::before {
    top: -500px;
    left: -100px;
    right: -100px;
    height: 500px;
    content: '';
    z-index: 2;
    position: absolute;
    transform: skewY(22deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #0a61ce;
    transform-origin: 0 0;
  }

  &::after {
    left: -100px;
    right: -100px;
    bottom: 100px;
    height: 400px;
    content: '';
    position: absolute;
    transform: skewY(22deg);
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);
    transform-origin: 0 100%;
  }
`

/**
 * Background presentational component
 */
export function Background({ children }: BackgroundProps) {
  return (
    <Layer1>
      <Layer2>
        <Layer3>{children}</Layer3>
      </Layer2>
    </Layer1>
  )
}

export default Background
