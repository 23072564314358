import React from 'react'
import { useSelector } from 'react-redux'
import { ClickAwayListener } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

/* components */
import { Text } from 'components/atoms'
import { CloseIcon } from 'components/atoms/icons/index'
import { AlertContainer, AlertContainerProps } from './AlertContainer'

/* state */
import { useDispatch } from 'state/store'
import { actions as alertActions, selectors as alertSelectors, types as alertTypes } from 'state/alerts'

/* type declarations */
export type AlertBannerProps = AlertContainerProps

export default function AlertBanner({ className, style, ...remainingProps }: AlertBannerProps) {
  /* library hooks */
  const dispatch = useDispatch()

  /* selector hooks */
  const { message, open, title, titleProps, ...rest }: alertTypes.Alert =
    useSelector(alertSelectors.selectAlert) || {}

  /* render */
  if (open) {
    return (
      <ClickAwayListener onClickAway={closeModal}>
        <AlertContainer className={className} style={style}>
          <Alert action={renderAction()} {...rest} {...remainingProps}>
            <AlertTitle {...titleProps}>{title}</AlertTitle>
            <Text message={message} />
          </Alert>
        </AlertContainer>
      </ClickAwayListener>
    )
  }

  return <></>

  function closeModal() {
    dispatch(alertActions.dismissAlert())
  }

  function renderAction() {
    return <CloseIcon onClick={closeModal} />
  }
}
