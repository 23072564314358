import { createSelector } from '@reduxjs/toolkit'

/* local ducks */
import { types as connTypes } from 'state/connections'

/* foreign ducks */
import { RootState } from 'state/store'

/* utils */
import { stateNames } from 'utils/constants'
import { comparisonToSortValue } from 'utils/primitives'
import { SortDetails } from 'utils/types'

const { connections } = stateNames

export const selectBaseConnectionsList = (state: RootState) => state[connections].list
export const selectConnectionsSort = (state: RootState) => ({
  reverse: state[connections].tableSortReverse,
  sortByProperty: state[connections].tableSortByColumn
})

export const selectConnections = createSelector(
  selectBaseConnectionsList,
  selectConnectionsSort,
  (connections, sortDetails: SortDetails) => {
    const { sortByProperty: prop } = sortDetails

    if (connections && Array.isArray(connections) && prop) {
      return connections.slice().sort((a: connTypes.Connection, b: connTypes.Connection) =>
        // @ts-ignore
        handleReversals(sortDetails)
          ? comparisonToSortValue(a[prop], b[prop])
          : comparisonToSortValue(b[prop], a[prop])
      )
    }

    return connections
  }
)

function handleReversals({ reverse, sortByProperty }: SortDetails) {
  const flipReversalList = ['dataMappingStatus']

  return flipReversalList.includes(sortByProperty) ? !reverse : reverse
}

const selectors = {
  selectBaseConnectionsList,
  selectConnections,
  selectConnectionsSort
}

export default selectors
