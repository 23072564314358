import { TableRow, TableRowProps } from '@material-ui/core'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTableRowProps = StyledComponentProps & TableRowProps & {}

/**
 * TableRow styled-component
 */
export const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  && {
    border-bottom: 1px solid #ededed;
    color: ${({ theme }) => theme.colors.grayDarker1};
    height: 52px;
  }
`

export default StyledTableRow
