import styled from 'styled-components'

/* components */
import { Box, BoxProps } from 'components/atoms'

/* type declarations */
export type ProgressContainerProps = BoxProps

export const ProgressContainer = styled(Box)<ProgressContainerProps>``

export default ProgressContainer
