import React from 'react'

/* components */
import H1 from './H1'
import { Subtitle, StyledTextProps } from './Subtitle'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type TitleProps = StyledComponentProps & {
  subtitle?: string
  subtitleProps?: StyledTextProps
  title: string
}

/**
 * Title presentational component
 */
export default function Title({ subtitle, subtitleProps, title, ...rest }: TitleProps) {
  return (
    <>
      <H1 data-testid="title" message={title} {...rest} />
      {subtitle && <Subtitle data-testid="subtitle" message={subtitle} {...subtitleProps} />}
    </>
  )
}
