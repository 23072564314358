import styled from 'styled-components'
import { SubmitButtons, SubmitButtonsProps } from 'components/molecules'

export type AddNewConnectionProps = SubmitButtonsProps

export const AddNewConnection = styled(SubmitButtons)<AddNewConnectionProps>`
  justify-content: flex-end;

  && {
    button.MuiButtonBase-root.MuiButton-root {
      width: 302px;
      padding: 0;
    }
  }

  && .MuiButton-label {
    height: 16px;
    margin: 0;
    font-size: 15.1px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    text-align: center;
  }
`

export default AddNewConnection
