import React from 'react'
import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type StyledTitleProps = StyledComponentProps & {
  noColor?: boolean
}

export type H1Props = StyledTitleProps & {
  message?: string
}

/**
 * StyledTitle styled-component
 */
export const StyledTitle = styled.h1<StyledTitleProps>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.717rem;
  line-height: 1;
  padding-top: 0;
`

/**
 * H1 presentational-component
 */
export function H1({ children, message, ...rest }: H1Props) {
  return (
    <StyledTitle data-testid="h1" {...rest}>
      {message || children}
    </StyledTitle>
  )
}

export default H1
