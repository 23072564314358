import React from 'react'

/* components */
import { Text, TextProps } from 'components/atoms'
import RowItem from './RowItem'

/* type declarations */
export type ConnectionLabelProps = TextProps

export default function ConnectionLabel({ message, ...rest }: ConnectionLabelProps) {
  return (
    <RowItem {...rest}>
      <Text message="Select Database" />
    </RowItem>
  )
}
