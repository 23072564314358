import React, { PropsWithoutRef } from 'react'

/* components */
import { Header } from 'components/organisms'
import { Title } from 'components/molecules'
import { Paper, PaperProps } from 'components/atoms'
import { Background } from './Background'
import { StyledContent } from './StyledContent'
import { PositionContents, PositionContentsProps } from './PositionContents'
import { UnauthNavbar } from './UnauthNavbar'
import Footer from './Footer'

/* utils */
import colors from 'App/theme/colors'

/* type declarations */
export type UnauthLayoutProps = PositionContentsProps & {
  headerProps?: PropsWithoutRef<JSX.IntrinsicElements['header']>
  subcontainerProps?: PaperProps
  subtitle: string
  title: string
  values?: object
}

/**
 * UnauthLayout type declarations
 */
export default function UnauthLayout({
  children,
  headerProps,
  subcontainerProps,
  subtitle,
  title,
  values,
  ...rest
}: UnauthLayoutProps) {
  const titleProps = {
    subtitle,
    subtitleProps: {
      style: {
        fontSize: '0.9rem',
        color: colors.grayDarker1
      }
    },
    title
  }

  return (
    <Background>
      <PositionContents data-testid="unauth_layout" {...rest}>
        <UnauthNavbar />
        <StyledContent>
          <Paper style={{ boxShadow: colors.boxShadowHeavy, paddingBottom: 64 }} {...subcontainerProps}>
            <Header {...headerProps}>
              <Title style={{ color: 'inherit', fontSize: '2.43em' }} {...titleProps} />
            </Header>
            <main>{children}</main>
          </Paper>
        </StyledContent>
        <Footer />
      </PositionContents>
    </Background>
  )
}
