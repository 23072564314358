import React from 'react'
import { formatDistance } from 'date-fns'
import styled from 'styled-components'

/* components */
import { LeftAlignCell } from '../StyledElements'

/* utils */
import { formatDate } from 'intl/main'
import { capitalize } from 'utils/primitives'
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type ConnectionDatesProps = {
  lastModified?: number | Date
}

export default function ConnectionDates({ lastModified }: ConnectionDatesProps) {
  return (
    <LeftAlignCell>
      <DatesContainer>
        <LastModifiedDate>{handleDate({ lastModified })}</LastModifiedDate>
        <DateDistance>{handleDateDistance({ lastModified })}</DateDistance>
      </DatesContainer>
    </LeftAlignCell>
  )

  function handleDate({ lastModified }: ConnectionDatesProps) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }

    return formatDate({ date: lastModified, options })
  }

  function handleDateDistance({ lastModified }: ConnectionDatesProps) {
    let formattedDateDistance: string | null

    try {
      formattedDateDistance = lastModified
        ? `${capitalize(formatDistance(lastModified, new Date()))} ago`
        : null
    } catch (err) {
      formattedDateDistance = null
    }

    return formattedDateDistance
  }
}

const DatesContainer = styled.div<StyledComponentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const DateDistance = styled.span<StyledComponentProps>`
  color: rgba(0, 0, 0, 0.38);
  font-size: 0.855rem;
  margin-top: -4px;
`
const LastModifiedDate = styled.span<StyledComponentProps>``
