import React from 'react'
import { useSelector } from 'react-redux'

/* components */
import { SubmitButtons } from 'components/molecules'
import { Box } from 'components/atoms'
import { ButtonsRow } from './ButtonsRow'
import { EntitiesTable } from './EntitiesTable'
import { MappingTable } from './MappingTable'

/* state */
import { useDispatch } from 'state/store'
import { types as currTypes } from 'state/currentConnection'
import { selectors as piiSelectors, types as piiTypes } from 'state/piiMapping'
import {
  actions as schemaActions,
  fetchFinishSchema,
  fetchSaveSchema,
  types as schemaTypes
} from 'state/schemas'

/* utils */
import theme from 'App/theme'

/* type declarations */
export type PiiTablesProps = {
  connection: currTypes.ConnectionProxy
  entities: schemaTypes.EntitiesForUI
  selectedEntity?: schemaTypes.EntityForUI
  userSelectedEntityName?: schemaTypes.EntityName
}

export default function PiiTables({
  connection,
  entities,
  selectedEntity,
  userSelectedEntityName
}: PiiTablesProps) {
  /* library hooks */
  const dispatch = useDispatch()

  /* selector hooks */
  const schemaValidations: piiTypes.SchemaValidations = useSelector(piiSelectors.selectSchemaValidations)

  /* constants */
  const hasEntities: boolean = !!entities

  const entitiesProps = {
    changeEntitySelection,
    className: 'entities-table',
    columnName: `${connection?.name || ''} Table Names`.trim(),
    entities,
    schemaValidations,
    userSelectedEntityName
  }

  const mappingProps = {
    className: 'mapping-table',
    entities,
    schemaValidations,
    updateProperty,
    userSelectedEntityName
  }

  const buttonProps = {
    handlePrimarySubmit: finishSchema,
    handleSecondarySubmit: saveSchema,
    primaryButtonText: 'Finish',
    secondaryButtonText: 'Save Progress',
    secondaryProps: {
      style: {
        backgroundColor: theme.colors.white
      }
    }
  }

  const userSelectedIdentity =
    selectedEntity || entities?.find((entity) => entity.name === userSelectedEntityName)

  return (
    <>
      {hasEntities && <EntitiesTable selectedEntity={selectedEntity} {...entitiesProps} />}
      <MappingTable selectedEntity={userSelectedIdentity} {...mappingProps} />
      {hasEntities && (
        <ButtonsRow>
          <Box width={485}>
            <SubmitButtons {...buttonProps} />
          </Box>
        </ButtonsRow>
      )}
    </>
  )

  function changeEntitySelection(newEntity: schemaTypes.EntityForUI) {
    dispatch(schemaActions.updateSelectedEntity(newEntity))
  }

  function finishSchema() {
    dispatch(fetchFinishSchema())
  }

  function saveSchema() {
    dispatch(fetchSaveSchema())
  }

  function updateProperty(mergedProperty: schemaTypes.EntityPropertyForUI) {
    const metaData = {
      connectionId: connection.id || '',
      entityName: userSelectedEntityName || ''
    }

    dispatch(schemaActions.updateEntityProperty({ ...mergedProperty, ...metaData }))
  }
}
