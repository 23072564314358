import React from 'react'

/* components */
import { DeleteIcon } from 'components/atoms/icons/index'
import { IconButton } from '../StyledElements'

/* utils */
import { EventClick } from 'utils/types'

export type DeleteProps = {
  connectionId: string
  handleClick: (event: EventClick) => void
}

export default function Delete({ connectionId, handleClick }: DeleteProps) {
  return (
    <IconButton name={connectionId} onClick={handleClick}>
      <DeleteIcon name={connectionId} />
    </IconButton>
  )
}
