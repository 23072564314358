import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

/* components */
import { EthycaLogo } from 'components/molecules'
import { Box } from 'components/atoms'
import { Anonymous } from 'components/atoms/icons/index'
import { StyledLink } from './StyledLink'
import { StyledAtlasLink } from './StyledAtlasLink'
import { StyledNavbar, StyledNavbarProps } from './StyledNavbar'

/* state */
import { useDispatch } from 'state/store'
import { selectors as appSelectors } from 'state/app'
import { actions as authActions } from 'state/auth'
import { actions as userActions, selectors as userSelectors } from 'state/user'

/* utils */
import { routes } from 'utils/constants'

/* type declarations */
export type NavbarProps = StyledNavbarProps

/**
 * Navbar redux-aware component
 */
export default function Navbar({ ...rest }: NavbarProps) {
  /* library hooks */
  const dispatch = useDispatch()

  /* selector hooks */
  const fetchState = useSelector(userSelectors.selectFetchState)
  const name = useSelector(userSelectors.selectName)
  const isAuth = useSelector(appSelectors.selectIsAuth)

  /* effect hooks */
  // Retrieve the user's details if the user is logged in
  useEffect(() => {
    if (isAuth && !fetchState) {
      getUser()
    }

    async function getUser() {
      dispatch(userActions.fetchGetUser())
    }
  }, [dispatch, fetchState, isAuth])

  /* render */
  return (
    <StyledNavbar data-testid="navbar" as="nav" {...rest}>
      <Box gridArea="logo" style={{ padding: '19px 39px' }}>
        <EthycaLogo to={routes.home} style={{ width: 90 }} />
      </Box>
      <Box gridArea="title" style={{ paddingLeft: '1em' }}>
        <StyledAtlasLink to={routes.home}>atlas</StyledAtlasLink>
      </Box>
      <Box gridArea="user" style={{ cursor: 'default' }}>
        <StyledLink to={routes.account}>
          {name || (
            <Box style={{ fontSize: '1.4em', marginRight: '1em' }}>
              <Anonymous />
            </Box>
          )}
        </StyledLink>
      </Box>
      <Box gridArea="log">
        <StyledLink onClick={handleLogout} to={routes.login}>
          Log out
        </StyledLink>
      </Box>
    </StyledNavbar>
  )

  /* callbacks */
  async function handleLogout() {
    await dispatch(authActions.fetchLogout())
  }
}
