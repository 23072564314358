import { Link, LinkProps as OriginalLinkProps } from 'react-router-dom'
import styled, { DefaultTheme } from 'styled-components'
import { color, ColorProps } from 'styled-system'

export type LinkProps = OriginalLinkProps &
  ColorProps & {
    theme?: DefaultTheme
  }

export const StyledLink = styled(Link)<LinkProps>`
  ${color}
`

export default StyledLink
