import styled from 'styled-components'
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type ExternalLinkProps = StyledComponentProps

/**
 * ExternalLink styled-component
 */
export const ExternalLink = styled.a<ExternalLinkProps>`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export default ExternalLink
