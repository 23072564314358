import styled from 'styled-components'

/* utils */
import { StyledComponentProps } from 'utils/types'

/* type declarations */
export type CellButtonProps = StyledComponentProps & {
    fontWeight?: string
}

/**
 * CellButton styled-component
 */
export const CellButton = styled.button<CellButtonProps>`
  background-color: inherit;
  color: inherit;
  font-weight: ${({ fontWeight='normal' }) => `${fontWeight}`};

  border: none;

  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  height: 100%;
  width: 100%;

  text-align: left;

  && {
    display: flex;
    min-height: 56px;
    padding: 16px 16px 16px 22px;
  }
`

export default CellButton
