import styled from 'styled-components'
import { LinearProgress, LinearProgressProps } from '@material-ui/core'

import { StyledComponentProps } from 'utils/types'

export type StyledProgressBarProps = LinearProgressProps & StyledComponentProps

export const StyledProgressBar = styled(LinearProgress)<StyledProgressBarProps>`
  border-radius: 4px;
  width: 804px;

  && {
    height: 34px;
  }
`

export default StyledProgressBar
