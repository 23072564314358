import React from 'react'

/* components */
import { StyledExternalLink } from './StyledExternalLink'
import { StyledFooter } from './StyledFooter'

/* utils */
import { externalUrls } from 'utils/constants'

/**
 * Footer presentational component
 */
export default function Footer() {
  return (
    <StyledFooter data-testid="footer">
      <StyledExternalLink href={externalUrls.privacyPolicy}>Privacy Policy</StyledExternalLink>
      <StyledExternalLink href={externalUrls.termsConditions}>Terms & Conditions</StyledExternalLink>
    </StyledFooter>
  )
}
